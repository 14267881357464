import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    district: [],
    selectedActiveTab: null,
    selectedSection: 1,
    bEngageData: {},
    insightData: {},
    bEngageList: [],
    speakerList: [],
    sponsorList: [],
    awardList: [],
    partnershipList: [],
    deleglatesList: {},
    nominationLists: {},
    floorPlan: null,
    totalBEngageCount: 0,
    filterData: [],
    category: null,
    gid: null,
    bEngageInitialValues: {},
    isLoad: false,
    bEngageDashboard: [],
    otpDelivered: false,
    regGid: "",
    getNewRegId: "",
    agendaData: [],
    agendaSpeakerData: [],
    emailList: [],
    awardGid: null,
    partnerGid: null,
    registrationDetails: {},
    conferenceParticiapnts: [],
    conferenceParticiapntsCount: 0,
    conferencesDetail: [],
}
const bEngageSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        resetbEngageSlice: () => initialState,
        addBEngageDistrict(state, action) {
            state.district = action.payload;
        },
        addBEngageData(state, action) {
            state.bEngageData = action.payload;
        },
        setGid(state, action) {
            state.gid = action.payload;
        },
        setInsightData(state, action) {
            state.insightData = action.payload;
        },
        setBEngageListData(state, action) {
            state.bEngageList = action.payload;
        },
        setTotalCount(state, action) {
            state.totalBEngageCount = action.payload;
        },
        setCategory(state, action) {
            state.category = action.payload;
        },
        setFilterData(state, action) {
            state.filterData = action.payload;
        },
        setGid(state, action) {
            state.gid = action.payload;
        },
        setBEngageInitalValues(state, action) {
            state.bEngageInitialValues = action.payload;
        },
        setEventIsLoad(state, action) {
            state.isLoad = action.payload;
        },
        setSpeakerList(state, action) {
            state.speakerList = action.payload;
        },
        setSponsorList(state, action) {
            state.sponsorList = action.payload;
        },
        setPartnershipList(state, action) {
            state.partnershipList = action.payload;
        },
        setAwardList(state, action) {
            state.awardList = action.payload;
        },
        setFloorPlan(state, action) {
            state.floorPlan = action.payload;
        },
        setDelegatesList(state, action) {
            state.deleglatesList = action.payload;
        },
        setSelectedActiveTab(state, action) {
            state.selectedActiveTab = action.payload;
        },
        setBEngageDashboard(state, action) {
            state.bEngageDashboard = action.payload;
        },
        setOtpDelivered(state, action) {
            state.otpDelivered = action.payload;
        },
        setRegGid(state, action) {
            state.regGid = action.payload;
        },
        setGetNewRegId(state, action) {
            state.getNewRegId = action.payload;
        },
        updateAgenda(state, action) {
            const index = state.agendaData.findIndex(item => item.schedule_date === action.payload.schedule_date);
            if (index >= 0) {
                state.agendaData[index] = action.payload;
            } else {
                state.agendaData.push(action.payload);
            }
        },
        setAgendaSpeaker(state, action) {
            state.agendaSpeakerData = action.payload;
        },
        setAgendaList(state, action) {
            state.agendaData = action.payload;
        },
        setSelectedSection(state, action) {
            state.selectedSection = action.payload;
        },
        setNominationLists(state, action) {
            state.nominationLists = action.payload;
        },
        setAwardGid(state, action) {
            state.awardGid = action.payload;
        },
        setPartnerGid(state, action) {
            state.partnerGid = action.payload;
        },
        setEmailList(state, action) {
            state.emailList = action.payload;
        },
        setRegistrationDetails(state, action) {
            state.registrationDetails = action.payload;
        },
        setConferenceParticiapnts(state, action) {
            state.conferenceParticiapnts = action.payload;
        },
        setConferenceParticiapntsCount(state, action) {
            state.conferenceParticiapntsCount = action.payload;
        },
        setConferencesDetail(state, action) {
            state.conferencesDetail = action.payload;
        },

    }
})

export default bEngageSlice.reducer;
export const {
    setBEngageInitalValues,
    setInsightData,
    setBEngageListData,
    resetbEngageSlice,
    addBEngageDistrict,
    addBEngageData,
    setGid,
    setAwardGid,
    setPartnerGid,
    setBrandListData,
    setTotalCount,
    setFilterData,
    setCategory,
    setEventIsLoad,
    setSpeakerList,
    setSponsorList,
    setPartnershipList,
    setAwardList,
    setFloorPlan,
    setDelegatesList,
    setSelectedActiveTab,
    setBEngageDashboard,
    setOtpDelivered,
    setRegGid,
    setGetNewRegId,
    updateAgenda,
    setAgendaList,
    setAgendaSpeaker,
    setSelectedSection,
    setNominationLists,
    setEmailList,
    setRegistrationDetails,
    setConferenceParticiapnts,
    setConferenceParticiapntsCount,
    setConferencesDetail,
} = bEngageSlice.actions;