import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Divider, Fade, Grid, Typography } from "@mui/material";
import SinglePostLayout from './SinglePostLayout';
import EventShare from "./EventShare";
import RfpPost from "./RfpPost";
import { useSelector } from "react-redux";
import PostCreateProcess from "./PostSubComponents/PostCreateProcess";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { useLocation, useParams } from "react-router";
import { getBookMarkPost, getDashboardPost, getHashtagData } from "../../actions";
import { useDispatch } from "react-redux";
import EmptyPostCard from "./PostSubComponents/EmptyPostCard";
import _ from 'lodash-es';
import PostSkeleton from "./PostSubComponents/PostSkeleton";
import EmptyBookMarkCard from "../BookMarks/EmptyBookMarkCard";
import { setBookmarkType, setMyFeed, setNotificationPostGid, setNotificationPostType } from "../../../../store/slices/SocialMediaSlice";
import EventBookMark from "./EventBookMark";
import { Helmet } from "react-helmet";
import PostReshareHeader from "./PostSubComponents/PostReshareHeader";
import PostActions from "./PostActions";

const Post = () => {
    const { socialMedia, form, master, users } = useSelector((state) => { return state; });
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    const [loading, setLoading] = useState(false)
    let offset = useRef(10);
    let totalCount = useRef(0);
    let totalPosts = useRef(0);
    let isFecthing = false;
    // const isFecthing = useRef(false);

    useEffect(() => {
        // Add event listener when component mounts to detect scroll
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove event listener when component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        if (socialMedia?.postDashboard) {
            totalCount.current = socialMedia?.postDashboard?.length;
            totalPosts.current = socialMedia?.totalPostCount;
        }
    }, [socialMedia?.postDashboard, socialMedia?.totalPostCount])

    const handleScroll = () => {
        if (isSocialImpact || isSocialHastags) {
            return;
        }
        // Detect if user has scrolled to the bottom of the page
        if (isFecthing) return;
        if (totalCount.current < totalPosts.current) {
            if (Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight) {
                // Fetch more data when user scrolls to the bottom
                try {
                    isFecthing = true;
                    offset.current = offset.current + 10;
                    if (totalCount.current) {
                        dispatch(getDashboardPost(offset.current, true, setLoading));
                    }

                } catch (error) {
                    isFecthing = false;
                }
                setTimeout(() => {
                    isFecthing = false;
                }, 2000);
            }
        } else {
            setLoading(false);
        }
    };
    const isSocialImpact = location.pathname.startsWith(`/social-media/social-impacts`);
    const isSocialHastags = location.pathname.startsWith(`/social-media/hashtags`);
    const isBookMarkEvents = location.pathname.startsWith(`/social-media/bookmark/event`);
    const isBookMarkPost = location.pathname.startsWith(`/social-media/bookmark/post`);
    const isNotificationPost = location.pathname.startsWith(`/social-media/posts`);
    const isMyFeed = location.pathname.startsWith(`/social-media/my-posts`);
    const isHashtag = location.pathname.startsWith(`/social-media/hashtags/`);
    useEffect(() => {
        if (!isSocialHastags && !isSocialImpact && !isBookMarkPost && !isBookMarkEvents && !isNotificationPost) {
            dispatch(getDashboardPost(offset.current))
        }
    }, []);
    useEffect(() => {
        if (isBookMarkPost) {
            dispatch(setBookmarkType('Post'));
        }
        if (isBookMarkEvents) {
            dispatch(setBookmarkType('SocialEvent'));
        }
        if (isBookMarkPost || isBookMarkEvents) {
            dispatch(getDashboardPost(offset.current));
        }
    }, [socialMedia.bookmarkType]);
    useEffect(() => {
        if (isNotificationPost) {
            dispatch(setNotificationPostType('posts'));
            if (params.gid) {
                dispatch(setNotificationPostGid(params.gid))
            }
        }
        if (isNotificationPost) {
            dispatch(getDashboardPost(offset.current));
        }
    }, [socialMedia.notificationPostType]);
    useEffect(() => {
        if (isMyFeed) {
            dispatch(setMyFeed('myPosts'));
        }
        if (isMyFeed) {
            dispatch(getDashboardPost(offset.current));
        }
    }, [socialMedia.myFeed]);
    useEffect(() => {
        if (isHashtag) {
            dispatch(getHashtagData(params.hashtag));
        }
    }, [isHashtag,params.hashtag]);

    // console.log("sell", socialMedia?.postDashboard)


    const postDashboardArray = Array.isArray(socialMedia?.postDashboard) ? socialMedia?.postDashboard : [socialMedia?.postDashboard];

    const convertToHttps = (url) => {
        if (url.startsWith('http://')) {
            return url.replace('http://', 'https://');
        }
        return url;
    };
    const shareUrl = convertToHttps(window.location.href);

    // useEffect(() => {
    //     if (isNotificationPost && !_.isEmpty(postDashboardArray)) {
    //         const firstPost = postDashboardArray[0];
    //         //console.log("fff", firstPost)
    //         const metaTags = [
    //             { property: "og:title", content: firstPost?.title || "Check out this post!" },
    //             { property: "og:type", content:"website" },
    //             { property: "og:description", content: firstPost?.body || "" },
    //             { property: "og:image", content: firstPost?.primary_post?.post_images?.[0]?.images_url || "" },
    //             { property: "og:video", content: firstPost?.primary_post?.post_video?.video_url || "" },
    //             { property: "og:url", content: `${shareUrl}` || "" },
    //             { name: "twitter:card", content: "summary_large_image" },
    //             { name: "twitter:title", content: firstPost?.title || "Check out this post!" },
    //             { name: "twitter:description", content: firstPost?.body || "" },
    //             { name: "twitter:image", content: firstPost?.primary_post?.post_images?.[0]?.images_url || "" },
    //             { name: "twitter:video", content: firstPost?.primary_post?.post_video?.video_url || "" }
    //         ];
    //         console.log("reL", firstPost)

    //         console.log("Updating meta tags for first post:", metaTags);
    //         //console.log("anoterh checl",  master.isLoad);
    //         const updateMetaTags = (metaTags) => {
    //             metaTags.forEach(({ property, name, content }) => {
    //                 let meta = document.querySelector(`meta[${property ? `property="${property}"` : `name="${name}"`}]`);
    //                 if (meta) {
    //                     meta.setAttribute("content", content);
    //                     console.log(`Updating meta tag: ${property || name} = ${content}`);
    //                 } else {
    //                     meta = document.createElement("meta");
    //                     if (property) {
    //                         meta.setAttribute("property", property);
    //                     } else {
    //                         meta.setAttribute("name", name);
    //                     }
    //                     meta.setAttribute("content", content);
    //                     document.head.appendChild(meta);
    //                     console.log(`Adding meta tag: ${property || name} = ${content}`);
    //                 }
    //             });
    //         };
    //         updateMetaTags(metaTags);
    //     }
    // }, [postDashboardArray]);
    const [metaTags, setMetaTags] = useState([]);

    useEffect(() => {
        if (isNotificationPost && !_.isEmpty(postDashboardArray)) {
            const firstPost = postDashboardArray[0];
            const newMetaTags = [
                { property: "og:title", content: firstPost?.title || "Check out this post!" },
                { property: "og:type", content: "website" },
                { property: "og:description", content: firstPost?.body || "" },
                { property: "og:image", content: firstPost?.primary_post?.post_images?.[0]?.images_url || "" },
                { property: "og:video", content: firstPost?.primary_post?.post_video?.video_url || "" },
                { property: "og:url", content: `${shareUrl}` || "" },
                { name: "twitter:card", content: "summary_large_image" },
                { name: "twitter:title", content: firstPost?.title || "Check out this post!" },
                { name: "twitter:description", content: firstPost?.body || "" },
                { name: "twitter:image", content: firstPost?.primary_post?.post_images?.[0]?.images_url || "" },
                { name: "twitter:video", content: firstPost?.primary_post?.post_video?.video_url || "" }
            ];
            setMetaTags(newMetaTags);
        }
    }, [postDashboardArray]);
    return (
        <>
            <Helmet>
                {metaTags.map((tag, index) => {
                    const key = tag.property ? `property:${tag.property}` : `name:${tag.name}`;
                    return tag.property ? (
                        <meta key={key} property={tag.property} content={tag.content} />
                    ) : (
                        <meta key={key} name={tag.name} content={tag.content} />
                    );
                })}

            </Helmet>
            <Grid container rowSpacing={2}>
                {!isSocialImpact && !isMyFeed &&
                    <PostCreateProcess />
                }
                {/* {!_.isEmpty(socialMedia?.postDashboard) && socialMedia.postDashboard.length > 0 ? ( */}
                {!_.isEmpty(socialMedia?.indianCsrPreview) && socialMedia?.indianCsrPreview?.posts?.map((post) => (
                    <>
                        <Grid item xs={12}>
                            <SinglePostLayout socialMedia={socialMedia} key={post.gid} post={post} form={form} master={master} users={users} offset={offset} />
                        </Grid>
                    </>
                ))}


                {/* {!_.isEmpty(socialMedia?.postDashboard) && socialMedia?.postDashboard?.map((post) => ( */}
                {!_.isEmpty(postDashboardArray) && postDashboardArray.map((post) => (
                    <>
                        {post?.multiple_shared_post &&
                            <Grid item xs={12} >
                                <Box className={SocialMediaCSS.postCard}>
                                    <Box className={SocialMediaCSS.postHeader} sx={{ padding: post?.multiple_shared_post ? '16px 0px 16px 0px' : "" }}>
                                        <PostReshareHeader post={post} />
                                    </Box>
                                    <Divider sx={{ marginTop: '0px' }} />
                                    <SinglePostLayout socialMedia={socialMedia} key={post.gid} post={post} form={form} master={master} users={users} offset={offset} />
                                    <Divider />
                                    <PostActions post={post} master={master} users={users} form={form} />
                                </Box>
                            </Grid>
                        }

                        {post?.post_type === 'Post' && !post?.multiple_shared_post &&
                            <Grid item xs={12}>
                                <SinglePostLayout socialMedia={socialMedia} key={post.gid} post={post} form={form} master={master} users={users} offset={offset} />
                            </Grid>}
                        {post?.post_type === 'ProposalRequest' &&
                            <Grid item xs={12}>
                                <RfpPost post={post} />
                            </Grid>}
                        {post?.post_type === 'SocialEvent' &&
                            <Grid item xs={12}>
                                <EventShare post={post} />
                            </Grid>}
                        {post?.post_type === 'Conference' &&
                            <Grid item xs={12}>
                                <EventShare post={post} />
                            </Grid>}
                        {post?.post_type === 'bookmark_event' &&
                            <Grid item xs={12}>
                                <EventBookMark post={post} />
                            </Grid>}
                    </>
                ))}
                {/* ) : ( */}

                <Grid item xs={12}>
                    {_.isEmpty(socialMedia?.postDashboard) && !isBookMarkEvents && !isBookMarkPost && !isMyFeed && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                        <Fade in={_.isEmpty(socialMedia?.postDashboard)} style={{ transitionDelay: _.isEmpty(socialMedia?.postDashboard) ? '100ms' : '0ms', }} unmountOnExit>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                                <PostSkeleton />
                            </Box>
                        </Fade>
                    </Box>
                    }
                </Grid>
                {/* {
                    !master.isLoad && <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                            <PostSkeleton />
                        </Box>
                    </>
                } */}
                {_.isEmpty(socialMedia.postDashboard) && master.isLoad && !loading && <Grid item xs={12}>
                    <EmptyPostCard />
                </Grid>}
                {_.isEmpty(socialMedia.postDashboard) && isMyFeed && <Grid item xs={12}>
                    <EmptyPostCard />
                </Grid>}
                {_.isEmpty(socialMedia.postDashboard) && (isBookMarkPost || isBookMarkEvents) && <Grid item xs={12}>
                    <EmptyBookMarkCard />
                </Grid>}

                {/* <Grid item xs={12}>
                    <SharedPostLayout socialMedia={socialMedia} dispatch={dispatch} />
                </Grid> */}

            </Grid >
        </>
    );
}
export default Post;
