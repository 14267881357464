import { Box, IconButton, InputBase } from '@mui/material';
import React, { useState } from 'react';
import UserProfileImg from '../../../../assets/images/projectTempImage.jpg';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import Chat from '../../../../assets/icons/Chat.svg';
import { postCommentSubmit, postRepliesSubmit } from '../../actions';
import { useDispatch } from 'react-redux';
import CommonInitials from '../../../../common/components/CommonInitials';
import { useNavigate } from 'react-router';
import { handleUserData } from '../../../../common/actions';

const CommentInputContainer = ({ gid, postId = 0, comment, show = true, value = '', editFlag = false, setIsEditing = null, users }) => {
    const {userData} = users;
    const navigate = useNavigate();
    const [newMessage, setNewMessage] = useState(value);
    const dispatch = useDispatch();
    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
            // console.log(newMessage);
            // setNewMessage('');
        }
    };

    const handleSendMessage = () => {
        console.log(newMessage);
        setNewMessage('');
        if (comment) {
            dispatch(postCommentSubmit(newMessage, gid, postId, editFlag, setIsEditing));
        } else {
            dispatch(postRepliesSubmit(newMessage, gid, postId, editFlag, setIsEditing));
        }
    };
    const handleUserProfile = () => {
        navigate(`/profile/${userData?.gid}`);
    }
    return (
        <>
            <Box className={SocialMediaCSS.commentHeader}>
                {show &&
                <Box className={SocialMediaCSS.cursor} onClick={handleUserProfile}>
                    <CommonInitials name={userData?.initials} bgColor={userData?.color_code} divStyle={{ padding: '3px', maxHeight: '42px', maxWidth: '42px', minHeight: '42px', minWidth: '42px', borderRadius: '50%', flex: '0 0 42px', borderColor: '#e9ecef', border: '1px solid #dee2e6' }} typoStyle={{ fontSize: '16px', lineHeight: '35px', }} avatarStyle={{ width: '42px', height: '42px',}} src={userData?.profile_pic} companyLogo={userData?.profile_pic} avatar={true} />
               </Box>
                }
                <Box className={SocialMediaCSS.commentInputBox}>
                    <InputBase
                        placeholder="Write your comment..."
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={1}
                        classes={{
                            root: SocialMediaCSS.commentTextInput,
                            input: SocialMediaCSS.commentTextInputInput,
                            focused: SocialMediaCSS.commentTextInputFocused,
                        }}
                        value={newMessage}
                        // onChange={(e) => setNewMessage(e.target.value)}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        endAdornment={(
                            <IconButton
                                color="primary"
                                aria-label="Send"
                                className={SocialMediaCSS.sendCommentIcon}
                                onClick={handleSendMessage}
                                disabled={!newMessage.trim()}
                            >
                                <img src={Chat} alt='Send' className={SocialMediaCSS.h18} />
                            </IconButton>
                        )}
                    />
                </Box>
            </Box>
        </>
    )
}

export default CommentInputContainer
