import { reset, initialize } from 'redux-form'
import { getHeaderForAPICall, convertJsonToQS, deleteLocalStorage, deleteCookie2 } from './utils';
import _ from 'lodash-es';
import { INDIVIDUAL_FLOW_KEYS, COMPLIANCE_DOCUMENTS as compliance, LOCAL_STORAGE_KEYS, TOKEN_TYPE } from './constants';
import { COMMON_MSG, httpMethod } from './constants';
import { setSnackbar } from "../store/slices/MessageSlice";
import {
  STATE_URL,
  DISTRICT_URL,
  MASTER_URL,
  ORG_EXPERIENCE,
  MY_NETWORKS,
  REDEEM_CODE,
  SCHEDULE7,
  NOTIFICATION,
  MESSAGE,
  REMARK,
  COUNTRY_URL,
  LANGUAGE,
  ADDRESS_TYPE,
  POST_ACTIVITY,
  MODULE_TYPE_URL,
  BUSINESS_SUIT_LIST,
  UPGRADE_ACCOUNT,
  MENTIONS,
  HASHTAGS,
  TAG_MENTIONS,
  POST_TYPES,
  POST_REACTION,
  GRIEVANCE,
  CONNECTION_TYPES,
  THEMATIC_AREAS,
  RFP_QUESTIONS,
  SOCIAL_USER_DETAIL,
  REQUEST_TYPES,
  SUPPORT_REQ,
  NOMINATION_TYPE,
  COMMUNITY_COUNT,
  SDGGUIDELINES_URL,
} from "../http/apiUrls";
import { resetUserSlice, setApplied, setBusineesSuite, setConnectCount, setLoginTokens, setMessageCount, setMyNetWorks, setNotificationCount, setRedeepApiFlag, setUserDetail } from '../store/slices/UserSlice';
import { addProfileDistrict, resetProfileSlice, setModalFlag, setModalMessage } from '../store/slices/ProfileSlice';
import { resetRegistrationForms, getCompanyType, getDefaultToken, logoutSingleAccount, loginDataUpdate } from '../modules/Registration/actions';
import { addBeneficiaryTypes, addColorCodes, addEventBeneficiaryTypes, addEventMode, addEventType, addLanguage, addManagementType, addModuleType, addSchedule7, addSdgGuideLines, addTransportaionMode, addUserCountry, resetMasterSlice, setIsLoad, setPendingCall, setPostActivity, setHastags, setMentions, setTagMentions, setPostTypes, setPostReaction, setGrievanceTypes, setConnectionTypes, setRfpQuestions, setRequestTypes, setNominationTypes, } from '../store/slices/MasterSlice';
import {
  addAnnexureList,
  addAddressType,
  addUserDistrict,
  addUserState,
  addThematicAreas,
} from "../store/slices/MasterSlice";
import { addOrgExperience, addScreeningQuestions } from '../store/slices/RfpSlice';
import { getCompleteProfile } from '../modules/Profile/actions';
import { addEventDistrict } from '../store/slices/EventSlice';


export const fetchAPI = (_APIUrl, HTTPMethod, data, _header, isBlob, mockResponse) => async (dispatch, getState) => {
  const { users } = getState();
  let header = _header === false? "noheader" : { ..._header };
  let APIUrl = _APIUrl;
  let headers = getHeaderForAPICall(header, isBlob);
  if (users && users.isLoggedIn && users?.tokens?.accessToken && header!=="noheader") { // every time it will get new token
    headers = {
      ...headers,
      "access-token": users?.tokens?.accessToken,
      "refresh-token": users?.tokens?.refreshToken
    };
  }
  const restArguments = {
    headers,
    method: HTTPMethod,
  };

  if (HTTPMethod === "POST" || HTTPMethod === "DELETE" || HTTPMethod === "PUT") {
    restArguments.body = isBlob ? data : JSON.stringify(data);
  } else if (HTTPMethod === "GET") {
    if (data != null) {
      const queryString = convertJsonToQS(data);
      APIUrl = `${APIUrl}?${queryString}`;
    }
  }
  let response;
  let accessToken;
  let refreshToken;
  try {
    dispatch(setPendingCall(true));
    if (mockResponse) {
      // only for mockResponse tesing
      response = await new Promise((resolve) =>
        setTimeout(() => resolve(mockResponse), 1000)
      );
    } else {
      response = await fetch(APIUrl, restArguments);
      if (response) {
        dispatch(setPendingCall(false));
        // update the login tokens 
        if (response.headers.get('access-token') && response.headers.get('refresh-token')) {
          accessToken = response.headers.get('access-token');
          refreshToken = response.headers.get('refresh-token');
          dispatch(setLoginTokens({
            accessToken,
            refreshToken
          }));
        }
        // login token code end
      }
      if (response.status === 401) {
        if (users.isLoggedIn && users.userData) {
          dispatch(logoutSingleAccount(users.userData.gid));
        } else {
          dispatch(doLogout());
        }
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
      if (response.status !== 200 && response.status !== 201 && response.status !== 422 && response.status !== 401) throw response;
      response = await response.json();
      if (response.statusCode === "402") { // for permission check
        dispatch(setModalDetails(true, response.error.errorMsg));
        dispatch(
          setSnackbar({
            flag: false,
            message: "",
            type: "error",
          })
        );
      }
    }
    return {
      ...response,
      refreshToken
    };
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(setPendingCall(false));
    if (response && response.status === 401) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.TOKEN_EXPIRED,
          type: "error",
        })
      );
    } else {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.GENERIC_ERROR_MSG,
          type: "error",
        })
      );
    }
    return {
      ...response,
    };
  }
};

export const setModalDetails = (flag, message) => (dispatch) => {
  dispatch(setModalMessage(message));
  dispatch(setModalFlag(flag));
}

export const doLogout = (resetUser = true) => (dispatch, getState) => {
  dispatch(resetUserSlice());
  // dispatch(reset("AboutUsform"));
  // dispatch(reset("catchmentAreaForm"));
  // dispatch(reset("compliancesForm"));
  // dispatch(reset("addressForm"));
  // dispatch(reset("pastProjectForm"));
  dispatch(resetProfileSlice());
  // dispatch(resetMasterSlice());
  // dispatch(resetRegistrationForms());
  deleteCookie2('accessToken');
  deleteCookie2('refreshToken');
  deleteCookie2('userList');
  // if (resetUser) dispatch(resetUserSlice());
  // deleteLocalStorage(LOCAL_STORAGE_KEYS.USER_DATA);
  // navigate('/login');
};

export const getAllState = () => (dispatch) => {
  dispatch(fetchAPI(STATE_URL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(addUserState(response.data.states));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getAddressTypes = () => (dispatch) => {
  dispatch(fetchAPI(ADDRESS_TYPE, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(addAddressType(response?.data?.address_type));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const getThematicAreas = () => (dispatch) => {
  dispatch(fetchAPI(THEMATIC_AREAS, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(addThematicAreas(response.data.thematic_areas))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}
export const getAllDistrict = (state_id, action) => (dispatch) => {
  const APIUrl = state_id ? `${DISTRICT_URL}?state_id=${state_id}` : DISTRICT_URL;
  dispatch(fetchAPI(APIUrl, httpMethod.GET)).then((response) => {
    if (response.success) {
      if (action === "event") {
        dispatch(addEventDistrict(response?.data?.districts)); // set district for events
      } else {
        if (state_id) {
          dispatch(addProfileDistrict(response?.data?.districts));
        } else {
          dispatch(addUserDistrict(response?.data?.districts));
        }
      }
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getAllCountry = () => (dispatch) => {
  const APIUrl = COUNTRY_URL;
  dispatch(fetchAPI(APIUrl, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(addUserCountry(response.data.countries));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getLanguage = () => (dispatch) => {
  dispatch(fetchAPI(LANGUAGE, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(addLanguage(response?.data?.language_type))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}
export const getPostActivity = () => (dispatch) => {
  dispatch(fetchAPI(POST_ACTIVITY, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setPostActivity(response.data.activity_type));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const getMentionsList = () => (dispatch) => {
  dispatch(fetchAPI(MENTIONS, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setMentions(response?.data.mention_data))
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const getConnectionTypes = () => (dispatch) => {

  dispatch(fetchAPI(CONNECTION_TYPES, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setConnectionTypes(response?.data?.connection_types))
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getRequestTypes = () => (dispatch) => {

  dispatch(fetchAPI(REQUEST_TYPES, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setRequestTypes(response?.data?.request_type_list))
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const getPostTypes = () => (dispatch, getState) => {
  const { master: { postTypes } } = getState();
  if (!_.isEmpty(postTypes)) return;
  dispatch(fetchAPI(POST_TYPES, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setPostTypes(response?.data?.post_types))
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const getTagMentions = () => (dispatch) => {
  dispatch(fetchAPI(TAG_MENTIONS, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setTagMentions(response?.data?.connected_users))
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const getPostReactions = () => (dispatch) => {
  dispatch(fetchAPI(POST_REACTION, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setPostReaction(response?.data?.reaction_type))
    } else if (response.error) {
      dispatch(
        setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", })
      );
    }
  });
};
export const getGrievanceTypes = () => (dispatch) => {
  dispatch(fetchAPI(GRIEVANCE, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setGrievanceTypes(response?.data?.dispute_type))
    } else if (response.error) {
      dispatch(
        setSnackbar({ flag: true, message: response.message, type: "error", })
      );
    }
  });
};

export const getHastagList = () => (dispatch) => {

  dispatch(fetchAPI(HASHTAGS, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(setHastags(response?.data))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
      }
    },
  );
}
export const getCompanyId = () => (dispatch, getState) => {
  const { users } = getState();
  return users?.userData?.data?.company_id;
}

export const getMasterData = () => (dispatch, getState) => {
  const { master } = getState();
  if (
    master.state.length > 0 && // check for null
    master.district.length > 0 &&
    master.annexureList.length > 0 &&
    master.thematicAreas.length > 0 &&
    master.addressType.length > 0
  ) {
    return;
  }
  dispatch(fetchAPI(MASTER_URL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(getCompleteProfile());
      dispatch(getCompanyType());
      dispatch(getModuleType());
      // dispatch(getSchedule7());
      dispatch(addAnnexureList(response.data.annexureList));
      dispatch(addAddressType(response.data.addressType));
      dispatch(addUserDistrict(response.data.district));
      dispatch(addUserState(response.data.state));
      dispatch(addThematicAreas(response.data.thematicAreas));
      response.data.BeneficiaryType.sort((a, b) => a.name.localeCompare(b.name));
      dispatch(addBeneficiaryTypes(response.data.BeneficiaryType));
      dispatch(addSdgGuideLines(response.data.SdgGuidelines));
      dispatch(addSchedule7(response.data.ScheduleSevens));
      dispatch(addScreeningQuestions(response.data.ScreeningQuestions));
      dispatch(addOrgExperience(response.data.OrgExperience));
      dispatch(addEventMode(response.data.EventMode));
      dispatch(addEventType(response.data.EventType));
      dispatch(addEventBeneficiaryTypes(response.data.BeneficiaryType));
      dispatch(addTransportaionMode(response.data.TransportationModes));
      dispatch(addLanguage(response.data.VolunteerLangs));
      dispatch(addManagementType(response.data.managementTypes))
      dispatch(addColorCodes(response.data.colorCodes));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getNotificationCount = () => (dispatch) => {
  //return;
  dispatch(fetchAPI(NOTIFICATION, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(setNotificationCount(response.data));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}
export const getConnectsCount = () => (dispatch) => {
  dispatch(fetchAPI(COMMUNITY_COUNT, httpMethod.GET)).then(
    response => {
      if (response.success) {
      dispatch(setConnectCount(response.data));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const getModuleType = () => (dispatch) => {
  dispatch(fetchAPI(MODULE_TYPE_URL, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(addModuleType(response.data?.module_type));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const submitSupport = (values, file) => (dispatch) => {
  const formData = new FormData();
  formData.append('support_request[user_query]', values?.user_query);
  formData.append('support_request[description]', values?.description);
  if(file){
    formData.append('support_request[file]', file);
  }
  dispatch(fetchAPI(SUPPORT_REQ, httpMethod.POST, formData, '', true)).then(
    response => {
      if (response.success) {
        dispatch(reset('supportForm'));
        dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const getBusinessSuite = (gid) => (dispatch) => {
  const dataToPost = {
    gid
  };
  dispatch(fetchAPI(BUSINESS_SUIT_LIST, httpMethod.GET, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(setBusineesSuite(response?.data));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const submitTrialActivated = (id, gid, setTrialActivated) => (dispatch) => {
  const dataToPost = {
    gid,
    module_id: id,
  };
  dispatch(fetchAPI(BUSINESS_SUIT_LIST, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        // dispatch(setBusineesSuite(response.data?.list));
        dispatch(getBusinessSuite(gid));
        // dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
        setTrialActivated(true);
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const submitUpgradeAccount = (company_type, gid, navigate, onClose) => (dispatch) => {
  const dataToPost = {
    gid,
    company_type,
  };
  dispatch(fetchAPI(UPGRADE_ACCOUNT, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(loginDataUpdate(response.data, navigate, onClose));
        onClose();
        dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const getMessageCount = () => (dispatch) => {
  dispatch(fetchAPI(MESSAGE, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(setMessageCount(response?.count));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const getSchedule7 = () => (dispatch) => {
  dispatch(fetchAPI(SCHEDULE7, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(addSchedule7(response?.data?.schedule_seven));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const getComlianceDocIDs = (complianceDoc) => (dispatch, getState) => {
  let newArr = [];
  complianceDoc && compliance.map((data) => {
    if (complianceDoc[data.fileName] && complianceDoc[data.fileName] === true) {
      newArr.push(data.fileName);
    }
  });
  return newArr;
}




export const getAnnexureIds = (annexure) => (dispatch, getState) => {
  const {
    master: { annexureList },
  } = getState();
  let newArr = [];
  annexure && annexureList &&
    annexureList.map((data) => {
      if (annexure[data.name] === true) {
        newArr.push(data.id);
      }
    });
  return newArr;
};

export const getOutBoxData = () => (dispatch, getState) => {
  const {
    form: { OutboxForm },
  } = getState();
  let complianceDoc = dispatch(
    getComlianceDocIDs(OutboxForm?.values?.complianceDoc)
  );
  let annexureDocIds = dispatch(getAnnexureIds(OutboxForm?.values?.annexure));
  let outBoxData = {
    aboutUs: OutboxForm?.values?.aboutUs ? true : false,
    pastProjects: OutboxForm?.values?.pastProjects || [],
    complianceDoc: complianceDoc,
    annexureDocIds: annexureDocIds,
  };
  return outBoxData;
};

export const getProfileFromOutBox = () => (dispatch, getState) => {
  let outBoxData = dispatch(getOutBoxData());
  const { profile } = getState();
  let previewFromOutBox = {
    complianceDoc: outBoxData.complianceDoc,
    annexureDocIds: dispatch(getAnnexureTitleList(outBoxData.annexureDocIds)), // _.keys(OutboxForm?.values?.annexure),
  };
  if (outBoxData.aboutUs) {
    previewFromOutBox = {
      ...previewFromOutBox,
      aboutUs: profile.aboutUs,
    };
  }
  if (outBoxData.pastProjects.length > 0) {
    previewFromOutBox = {
      ...previewFromOutBox,
      pastProjectData: dispatch(
        getPastProjectsFromOutBox(outBoxData.pastProjects)
      ),
    };
  }
  return previewFromOutBox;
};

export const getPastProjectsFromOutBox =
  (pastProjects) => (dispatch, getState) => {
    const {
      profile: { pastProjectData },
    } = getState();
    let newProjectArr = [];
    pastProjects.map((data) => {
      pastProjectData.find((project) => {
        if (project.id == data) {
          newProjectArr.push(project);
        }
      });
    });
    return newProjectArr;
  };


export const getAnnexureTitleList = (ids) => (dispatch, getState) => {
  const {
    master: { annexureList },
  } = getState();
  let data = annexureList.filter((data) => ids.indexOf(data.id) > -1);
  return data;
}
export const getRfpQuestions = () => (dispatch, getState) => {
  dispatch(fetchAPI(RFP_QUESTIONS, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setRfpQuestions(response?.data?.rfp_questionnaire_list));
    } else if (response.error) {
      dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" })
      );
    }
  });
}

export const getOrgExperience = () => (dispatch, getState) => {
  dispatch(fetchAPI(ORG_EXPERIENCE, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(addOrgExperience(response.data));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
}

export const getMyNetworks = (user_type, uniqueId) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId,
    user_type
  }
  let APIUrl = `${MY_NETWORKS}/${user_type}`;
  dispatch(fetchAPI(MY_NETWORKS, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(setMyNetWorks(response.data))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const setRemarks = (remark, id) => (dispatch, getState) => {
  let dataToPost = {
    remark: remark,
    connected_user_id: id,
  }
  // let APIUrl = `${MY_NETWORKS}/${user_type}`;
  dispatch(fetchAPI(REMARK, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        return;
        // dispatch(setMyNetWorks(response.data))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const getProfileDataForPreview = (data) => (dispatch, getState) => {
  const { aboutUs, companyInfo, userUploadedData, user_type_id } = data;
  let profileData = {
    aboutUs: aboutUs || undefined,
    pastProjectData: companyInfo?.pastProjects,
    complianceDoc: companyInfo?.complianceDoc || [],
    userUploadedData,
    user_type_id,
  };
  if (companyInfo?.annexureDocs) {
    let annexureIds = companyInfo?.annexureDocs.map((data) => data.annexureId);
    let annexureDocIds = dispatch(getAnnexureTitleList(annexureIds));
    if (annexureDocIds && annexureDocIds.length > 0) {
      profileData = {
        ...profileData,
        annexureDocIds,
      }
    }
  }
  return profileData;
}

export const resetAllForms = () => (dispatch, getState) => {
  Object.keys(getState().form).forEach(formToReset =>
    dispatch(
      initialize(formToReset, {}, false, { updateUnregisteredFields: false }),
    ),
  );
};

export const resetSingleForm = (formToReset) => (dispatch, getState) => {
  dispatch(initialize(formToReset, {}, false, { updateUnregisteredFields: false }));
};

export const handleCouponCode = (module_type, redeem_code) => (dispatch, getState) => {
  let dataToPost = {
    module_type,
    redeem_code
  }
  dispatch(fetchAPI(REDEEM_CODE, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(setRedeepApiFlag(true));
        dispatch(handleUserData(module_type));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const handleUserData = (module_type) => (dispatch, getState) => {
  const { users: { userData: { data } } } = getState();
  const moduleType = `is_${module_type}_applied`;
  const updatedData = {
    ...data,
    [moduleType]: true,
  };
  dispatch(setApplied(updatedData));
}

export const isIndividualProfile = (userData) => {
  return userData?.data?.role_key === INDIVIDUAL_FLOW_KEYS.INDIVIDUAL;
};

export const getColorCodeById = (id) => (dispatch, getState) => {
  const { master: { colorCodes } } = getState();
  const colorList = colorCodes?.find((state) => state?.id == id);
  return colorList?.code;
};
export const getSocialUserDetail = () => (dispatch) => {

  dispatch(fetchAPI(SOCIAL_USER_DETAIL, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(setUserDetail(response.data));
      } else if (response.error) {
        // dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}
export const getNominationList = () => (dispatch) => {

  dispatch(fetchAPI(NOMINATION_TYPE, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(setNominationTypes(response?.data?.nomination_types))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
      }
    },
  );
}
export const getSdgGuideLines = () => (dispatch) => {
  dispatch(fetchAPI(SDGGUIDELINES_URL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(addSdgGuideLines(response?.data?.sdg_guidelines));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
