import React from 'react';
import { Field } from 'redux-form';
import { RenderMultiSelect } from './RenderMultiSelect';

const CommonMultiSelect = (props) => {
  return (
    <span className="common-select">
      <Field
        name={props.name}
        // {...props}
        component={RenderMultiSelect}
        options={props.options}
        disabled={props.disabled}
        validate={props.validate}
        onChange={props.onChange}
        placeholder={props.placeholder}
        customHeight={props.customHeight}
        fullWidth={props.fullWidth}
        value={props.defaultValue }
        square={props.square}
        showcount={props.showcount}
        marginSize={props.marginSize}
        placeholderFontSize= {props.placeholderFontSize}
        maxSelections= {props.maxSelections}
        selectTitle={props.selectTitle}
        isProfile={props.isProfile}
        customClasses={props.classes}
      />
    </span>
  );
}

export default CommonMultiSelect;