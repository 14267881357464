import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider, Grid, IconButton, Paper, Popover, useMediaQuery } from '@mui/material';
import theme from '../../../../assets/material-ui/theme';
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { Typography } from '@material-ui/core';
import CloseIcon from "../../../../assets/icons/closeDialogue.svg"
import CommonInitials from '../../../../common/components/CommonInitials';
import ReduxForm from '../../../../common/components/ReduxForm';
import CommonInput from '../../../../common/components/CommonInput';
import CommonButton2 from '../../../../common/components/CommonButton2';
import share from "../../../../assets/icons/shareWhite.svg";
import { getDate, getThematicNameById, isJSON } from '../../../../common/utils';
import DownArrow from "../../../../assets/icons/DownArrow.svg";
import PostMedia from '../Posts/PostMedia';
import { useDispatch } from 'react-redux';
import { shareRfpPost } from '../../actions';
import { useNavigate } from 'react-router';
import { reset } from 'redux-form';

export const ShareRfp = ({ open, onClose, Title, RFPId, master, user, form, shareData }) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [textExceeded, setTextExceeded] = useState(false);
    const [selectedPostType, setSelectedPostType] = useState(master?.postTypes?.[0] || {});
    const opens = Boolean(anchorEl);
    const postMorePopover = opens ? 'simple-popover' : undefined;
    const tags = master?.hashtags?.hashtags;
    const users = master?.mentions;
    //console.log("ff", shareData)
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePostTypeSelect = (postType) => {
        setSelectedPostType(postType);
        handlePopoverClose();
    };
    const handleResharePost = () => {
        const selectedPostTypeId = selectedPostType.id
        let gid = shareData?.gid
        dispatch(shareRfpPost(gid, selectedPostTypeId, onClose,navigate))
    };
    const handlViewRFP = () => {
        let gid = shareData?.gid
        navigate(`/social-media/rfp/${gid}`)
    };

    useEffect(() => {
        if (open) {
            dispatch(reset('postReshareform'));
        }
    }, [open]);
    const handleExceedLimit = (exceeded) => {
        if (exceeded) {
            setTextExceeded(true)
        } else {
            setTextExceeded(false)
        }
    };
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleDlgClose}
            aria-labelledby="Share Post"
            PaperProps={{ classes: { root: SocialMediaCSS.sharePopup } }}
        >
            <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                <Box className={SocialMediaCSS.postHeader}>
                    <Box className={SocialMediaCSS.postHeaderLogo}>
                        <CommonInitials
                            name={user?.userData?.initials}
                            bgColor={user?.userData?.color_code}
                            divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                            typoStyle={{ fontSize: '17px', lineHeight: '45px', }}
                            avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                            src={user?.userData?.profile_pic}
                            companyLogo={user?.userData?.profile_pic}
                            avatar={true}
                        />
                    </Box>
                    <Box className={SocialMediaCSS.postHeaderInfo}>
                        <Typography className={SocialMediaCSS.postReshareTitle} >
                            {user?.userData?.name}
                        </Typography>
                        <Box className={`${SocialMediaCSS.cursor} ${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} gap={1} aria-describedby={postMorePopover} onClick={handlePopoverOpen}>
                            <img src={selectedPostType.image} className={SocialMediaCSS.headAudi} />
                            <Typography className={SocialMediaCSS.postTypeReshare} >
                                {selectedPostType.value}
                            </Typography>
                            <img src={DownArrow} />
                        </Box>
                    </Box>
                    <IconButton onClick={() => onClose()} sx={{ alignSelf: 'start' }}>
                        <img src={CloseIcon} alt='Close Dialog' />
                    </IconButton>
                </Box>
                <Box pt={2.4}>
                    <ReduxForm
                        form="postReshareform"
                        label="postReshare"
                        submitButton="Next">
                        <Grid container>
                            <Grid item xs={12} mt={-1.2}>
                                <CommonInput
                                    name="description"
                                    placeholder="Write about this post"
                                    type="socialInput"
                                    users={users}
                                    tags={tags}
                                    maxLength='5000'
                                    showCount={false}
                                    countFontSize="12px"
                                    rows="6"
                                    onExceedLimit={handleExceedLimit}
                                />
                            </Grid>
                        </Grid>
                    </ReduxForm>
                </Box>
                <Box className={`${SocialMediaCSS.postReshareCard} ${SocialMediaCSS.scrollable}`}>
                    <Box className={SocialMediaCSS.postHeader}>
                        <Box className={SocialMediaCSS.postHeaderLogo}>
                            <CommonInitials
                                name={shareData?.initials}
                                bgColor={shareData?.colour_code}
                                divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                typoStyle={{ fontSize: '16px', lineHeight: '45px', }}
                                avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                src={shareData?.company_logo}
                                companyLogo={shareData?.company_logo}
                                avatar={true}
                            />
                        </Box>
                        <Box className={SocialMediaCSS.postHeaderInfo} gap={0}>
                            <Typography className={SocialMediaCSS.postReshareTitle} >{shareData?.brand_name}</Typography>
                            {/* <Box className={SocialMediaCSS.f} gap={2}> */}
                            <Typography className={SocialMediaCSS.postTypeReshare}> {shareData?.company_type?.charAt(0)?.toUpperCase() + shareData?.company_type?.slice(1)}</Typography>
                                <Typography className={SocialMediaCSS.postTypeReshare}>{shareData?.connection_count} Connections</Typography>
                            {/* </Box> */}
                        </Box>
                    </Box>

                    <Box className={SocialMediaCSS.f} gap={2} mb={1}>
                        {shareData?.thematic_area_ids?.map((theme, index) => (
                            <Box className={SocialMediaCSS.rfpThematic} mt={1.6} key={index}>
                                <Typography className={SocialMediaCSS.themeRfp}>{dispatch(getThematicNameById(theme))}</Typography>
                            </Box>
                        ))}
                    </Box>

                    <Typography variant='body2' className={SocialMediaCSS.textContent}>
                        {shareData?.title}
                    </Typography>
                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                        < CommonButton2
                            label="View RFP"
                            name="reshare"
                            variant="outlined"
                            type="button"
                            onClick={handlViewRFP}
                            style={{ padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600' }}

                        />
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                            <Typography className={SocialMediaCSS.rfpLast}>Last submission Date:</Typography> &nbsp;
                            <Typography className={SocialMediaCSS.LaRfpDate}>{getDate(shareData?.submission_date)}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_end}`} pt={2}>
                    < CommonButton2
                        label="| Share as Post"
                        name="reshare"
                        variant="contained"
                        startIcon={<img src={share} />}
                        type="button"
                        onClick={() => handleResharePost()}
                        disabled={!form?.postReshareform?.values || !form?.postReshareform?.values?.description}
                        style={{
                            padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none',
                            background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%', color: '#FFF',
                            opacity: !form?.postReshareform?.values || !form?.postReshareform?.values?.description ? 0.5 : 1
                        }}
                    />
                </Box>
            </Box>
            <Popover
                id={postMorePopover}
                open={opens}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={SocialMediaCSS.popoverPostMore}
            >
                <Box className={SocialMediaCSS.postMoreOptionBox} onClick={handlePopoverClose}>
                    {master?.postTypes?.map((data, index) => (
                        <Box className={SocialMediaCSS.postMoreOptionItem} onClick={() => handlePostTypeSelect(data)}>
                            <img src={data.image} className={SocialMediaCSS.h14} />
                            <Box className={SocialMediaCSS.postMoreOptionItemDetail}>
                                <Typography variant='body2' className={SocialMediaCSS.postMoreOptionItemName}>
                                    {data.value}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Popover>
        </Dialog>
    );
};