// import { Box, IconButton, Tooltip, tooltipClasses } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { styled } from '@mui/styles';
// import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
// import { deleteReaction, submitPostReaction } from '../../actions';
// import { useSelector } from 'react-redux';

// const LightTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.mainPalette.grey.white,
//         color: theme.mainPalette.grey.lightText,
//         boxShadow: theme.shadow.softShadow3,
//         padding: '8px',
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//         color: theme.mainPalette.grey.white,
//     },
// }));

// const AllReactions = ({ master, postId, dispatch, type = "Post", post, commentId, poss, parentComment, commentDeleteId, commentReplyId, replyId,commentData }) => {
//     const [selectedReactionId, setSelectedReactionId] = useState("");
//     const { socialMedia } = useSelector((state) => { return state; });
//     console.log("dd", postId)
//     useEffect(() => {
//         if (post?.primary_post?.personal_reactions?.length > 0) {
//             const reactionIds = post.primary_post.personal_reactions?.find(reaction => reaction.name)?.name;
//             setSelectedReactionId(reactionIds);
//         } else {
//             setSelectedReactionId(null);
//         }
//     }, [post]);
//     useEffect(() => {
//         if (commentData?.personal_reactions?.length > 0) {
//             const reactionIds = commentData?.personal_reactions?.find(reaction => reaction.name)?.name;
//             setSelectedReactionId(reactionIds);

//         } else {
//             setSelectedReactionId(null);
//         }
//     }, [commentData]);
//     useEffect(() => {
//         if (socialMedia?.comments) {
//             const replies = socialMedia?.replies && socialMedia.replies[parentComment];
//             if (replies) {
//                 replies.forEach(replies => {
//                     const reactComment = replies?.personal_reactions
//                     // const reactionNames = reactComment?.map(reaction => reaction.name);
//                     const commreact = reactComment?.find(reaction => reaction?.name)?.name;
//                     console.log("Reply_Body:", commreact);
//                     console.log("Personal_Reply_Reactions:", commreact);
//                     setSelectedReactionId(commreact);
//                 })
//             }
//         }
//     }, [socialMedia.comments, postId, commentId]);

//     // const handleReactionClick = (reactionId,reactionValue) => {
//     //     setSelectedReactionId(reactionValue);
//     //     dispatch(submitPostReaction(postId, reactionId, type));
//     //     console.log("Selected Reaction ID:", reactionId);
//     // };
//     console.log(selectedReactionId, "fghh")
//     const handleReactionClick = (reactionId, reactionValue) => {
//         if (selectedReactionId === reactionValue) {
//             const reaction = post?.primary_post?.personal_reactions?.find(react => react.name === reactionValue);
//             const Id = reaction ? reaction.id : null;
//             // console.log("logs",Id)
//             dispatch(deleteReaction(Id, commentDeleteId, type, commentReplyId, poss, parentComment));
//             setSelectedReactionId(null);
//         } else {
//             setSelectedReactionId(reactionValue);
//             dispatch(submitPostReaction(postId, reactionId, type, commentId, poss, parentComment, replyId));
//         }
//         console.log("Selected Reaction ID:", reactionId);
//     };
//     // console.log("few",selectedReactionId)

//     return (
//         <Box className={SocialMediaCSS.postReactionsContainer}>
//             {master?.postReaction.map((reaction) => {
//                 const isSelected = selectedReactionId === reaction.value;
//                 // const isSelected = selectedReactionId.trim() === reaction.name.trim();
//                 //console.log(`SelectedReaction: ${selectedReactionId}, Reaction name: ${reaction.value}, isSelected: ${isSelected}`);

//                 return (
//                     <LightTooltip key={reaction.id} title={reaction.value} placement='top' arrow>
//                         <IconButton size='small' onClick={() => handleReactionClick(reaction.id, reaction.value, reaction.gid)}>
//                             <img
//                                 src={reaction.image}
//                                 alt={reaction.value}
//                                 className={`${SocialMediaCSS.h16} ${isSelected ? SocialMediaCSS.selectedIcon : ''}`}
//                             />
//                         </IconButton>
//                     </LightTooltip>
//                 );
//             })}
//         </Box>
//     );
// };

// export default AllReactions;
import { Box, IconButton, Tooltip, tooltipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { deleteReaction, deleteReactionComment, deleteReactionReply, submitPostReaction } from '../../actions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.mainPalette.grey.white,
        color: theme.mainPalette.grey.lightText,
        boxShadow: theme.shadow.softShadow3,
        padding: '8px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.mainPalette.grey.white,
    },
}));

const AllReactions = ({ master, postId, dispatch, type = "Post", post, commentId, poss, parentComment, commentDeleteId, commentReplyId, replyId, commentData, replyData, isLoggedIn}) => {
    const [selectedPostReaction, setSelectedPostReaction] = useState(null);
    const [selectedCommentReaction, setSelectedCommentReaction] = useState(null);
    const [selectedReplyReaction, setSelectedReplyReaction] = useState(null);
    const { socialMedia } = useSelector((state) => state);
    const navigate = useNavigate()

    useEffect(() => {
        if (post?.primary_post?.personal_reactions?.length > 0) {
            const reaction = post.primary_post.personal_reactions.find(reaction => reaction.name)?.name;
            setSelectedPostReaction(reaction);
        } else {
            setSelectedPostReaction(null);
        }
    }, [post]);

    useEffect(() => {
        if (commentData?.personal_reactions?.length > 0) {
            const reaction = commentData.personal_reactions.find(reaction => reaction.name)?.name;
            setSelectedCommentReaction(reaction);
        } else {
            setSelectedCommentReaction(null);
        }
    }, [commentData]);

    useEffect(() => {
        if (replyData?.personal_reactions?.length > 0) {
            const reaction = replyData?.personal_reactions?.find(reaction => reaction.name)?.name;
            setSelectedReplyReaction(reaction);
        } else {
            setSelectedReplyReaction(null);
        }
    }, [replyData]);

    const handleReactionClick = (reactionId, reactionValue) => {
        if(isLoggedIn){
            navigate('/login');
        }
        if (master.pending) {
            return;
        }
        if (type === "Post") {
            if (selectedPostReaction === reactionValue) {
                const reaction = post?.primary_post?.personal_reactions?.find(react => react.name === reactionValue);
                const Id = reaction ? reaction.id : null;
                dispatch(deleteReaction(Id, commentDeleteId, type, commentReplyId, poss, parentComment));
                setSelectedPostReaction(null);
            } else {
                setSelectedPostReaction(reactionValue);
                dispatch(submitPostReaction(postId, reactionId, type, commentId, poss, parentComment, replyId));
            }
        } else if (type === "PostComment") {
            if (selectedCommentReaction === reactionValue) {
                const reaction = commentData?.personal_reactions?.find(react => react.name === reactionValue);
                const comsId = reaction ? reaction.id : null;
                const PostCommentGId = commentData?.post_gid
                const Variant = 'PostComment';
              
                dispatch(deleteReactionComment( type,comsId,PostCommentGId,Variant));
                setSelectedCommentReaction(null);
            } else {
                setSelectedCommentReaction(reactionValue);
                dispatch(submitPostReaction(postId, reactionId, type, commentId, poss, parentComment, replyId));
            }
        } else if (type === "CommentReply") {
            if (selectedReplyReaction === reactionValue) {
                const reaction = replyData?.personal_reactions?.find(react => react.name === reactionValue);
                const replyId = reaction ? reaction.id : null;
                const PostReplyGId = replyData?.post_comment_gid
                const Variant = 'CommentReply';
                console.log("four",replyId)
                dispatch(deleteReactionReply(type,replyId,PostReplyGId,Variant));
                setSelectedReplyReaction(null);
            } else {
                setSelectedReplyReaction(reactionValue);
                dispatch(submitPostReaction(postId, reactionId, type, commentId, poss, parentComment, replyId));
            }
        }
    };

    return (
        <Box className={SocialMediaCSS.postReactionsContainer}>
            {master?.postReaction.map((reaction) => {
                const isSelected =
                    (type === "Post" && selectedPostReaction === reaction.value) ||
                    (type === "PostComment" && selectedCommentReaction === reaction.value) ||
                    (type === "CommentReply" && selectedReplyReaction === reaction.value);

                return (
                    <LightTooltip key={reaction.id} title={reaction.value} placement='top' arrow>
                        <IconButton size='small' onClick={() => handleReactionClick(reaction.id, reaction.value)} disabled={master.pending}>
                            <img
                                src={reaction.image}
                                alt={reaction.value}
                                className={`${SocialMediaCSS.h16} ${isSelected ? SocialMediaCSS.selectedIcon : ''}`}
                            />
                        </IconButton>
                    </LightTooltip>
                );
            })}
        </Box>
    );
};

export default AllReactions;
