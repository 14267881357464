import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import CircleIcon from '@mui/icons-material/Circle';
import { getDashboardPost, getSocialRfpPreview, } from "../../actions";
import RFPCSS from "../../../RFP/RFPcss/RFP.module.css"
import { useDispatch } from "react-redux";
import request from "../../../../assets/icons/request.svg";

import { getDate } from "date-fns/esm";
import { getResponseDate, getThematicNameById } from "../../../../common/utils";
import { getDistrictNameByIds, getStateNameById } from "../../../RFP/actions";

const RfpQuesPreview = ({ SocialMediaCSS }) => {
    const { socialMedia: { glimpseFormData, selectedSampleQuestion }, form } = useSelector((state) => { return state; });
    const location = useLocation();
    const dispatch = useDispatch();
    const { gid } = useParams();
    const isSocialHastags = location.pathname.startsWith(`/social-media/hashtags`);

    useEffect(() => {
        dispatch(getSocialRfpPreview(gid))
    }, []);

    return (
        <>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} >
                    <Box className={SocialMediaCSS.rfpPrev}>
                        <Typography className={SocialMediaCSS.prevText}>Request For Proposal</Typography>
                        <img src={request} />
                    </Box>
                    {/* <Box className={SocialMediaCSS.whitecard}>
                        <Typography className={SocialMediaCSS.tileRFP}>Title</Typography>
                        <Typography className={SocialMediaCSS.rfptitle1}>Building girls school in rural areas under pradanmantri beti bachao beti padhao program. Building girls school in rural areas under pradanmantri beti bachao beti padhao program.</Typography>
                    </Box> */}
                    <Box className={RFPCSS.whitecard} >
                        <Typography className={RFPCSS.tileRFP}>Title</Typography>
                        <Typography className={RFPCSS.rfptail}>{glimpseFormData.title}</Typography>
                        <Box className={RFPCSS.BdugetContainer} >
                            <Box className={RFPCSS.f}>
                                <Typography className={RFPCSS.RFPBduget}>Indicative Budget (INR): </Typography>&nbsp; 
                                <Typography className={RFPCSS.RfpBdugetValue}>{glimpseFormData?.budget_private === false ?'TBD' :glimpseFormData?.budget}</Typography>
                            </Box>
                            <Box className={RFPCSS.f}>
                                <Typography className={RFPCSS.RFPBduget}>Tenure: </Typography>&nbsp;
                                <Typography className={RFPCSS.RfpBdugetValue}>{glimpseFormData?.project_Tenure}   {glimpseFormData?.project_Tenure_dropdown}</Typography>
                            </Box>
                            <Box className={RFPCSS.f}>
                                <Typography className={RFPCSS.RFPBduget}>Last Date: </Typography>&nbsp;
                                <Typography className={RFPCSS.RfpBdugetValue}>{getResponseDate(glimpseFormData?.submissionDate)}</Typography>
                            </Box>
                        </Box>
                        <Box pt={2}>
                            <Typography className={RFPCSS.tileRFP}>Thematic Area</Typography>
                            {/* {glimpseFormData?.thematicArea?.map((item, index) => (
                                <Typography className={RFPCSS.rfptitle1} pt={1}>{dispatch(getThematicNameById(item ))}</Typography>
                            ))} */}
                            <Box className={RFPCSS.themes} >
                                {glimpseFormData?.thematicArea?.map((item, index) => (
                                    <Typography className={RFPCSS.rfptitle1} pt={1}>
                                        {index > 0 ? ', ' : ''}
                                        {dispatch(getThematicNameById(item))}
                                    </Typography>
                                ))}</Box>

                        </Box>
                        <Box pt={4}>
                            <Typography className={RFPCSS.tileRFP}>Implementation Area</Typography>
                            {glimpseFormData?.geoLocation && glimpseFormData?.geoLocation?.map((type, index) => (
                                <>
                                    <Typography className={RFPCSS.RFPBduget} pt={1}>{dispatch(getStateNameById(type.state_id))}</Typography>

                                    <Box className={RFPCSS.catchmentDistList} pt={1}>
                                        {type.district_ids.map((item, index) => (
                                            <Box className={RFPCSS.catchmentDistItem} >
                                                <CircleIcon className={RFPCSS.bullet} />
                                                <Typography variant='body4' className={RFPCSS.rfptitle1}>
                                                    {dispatch(getDistrictNameByIds(item))}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </>
                            ))}
                        </Box >


                        <Box pt={4}>
                            <Typography className={RFPCSS.tileRFP}>Screening Queries</Typography>
                            {/* {!respond && !responsePreview && */}
                            <Box >
                                {[...(glimpseFormData?.customQuestion || []), ...(selectedSampleQuestion || [])]?.map((item, index) => (
                                    <Box className={RFPCSS.f} pb={1}>
                                        <Typography className={RFPCSS.rfptitle1} pr={1}>{index + 1}.</Typography>
                                        <Typography className={RFPCSS.rfptail}>{item.question}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            {/* {respond &&
                                <AnswerQueries RFPCSS={RFPCSS} glimpseFormData={glimpseFormData} />
                            }
                            {responsePreview && !respond &&
                                <PreviewResponses RFPCSS={RFPCSS} glimpseFormData={glimpseFormData} />
                            } */}
                        </Box>
                        <Box className={RFPCSS.divider}>
                            <Divider />
                        </Box>
                        <Box className={RFPCSS.footorRfp}>
                            <Typography className={RFPCSS.prepared}>Prepared by:</Typography>
                            <Typography className={RFPCSS.footorAddress}>{glimpseFormData.prepared_by}</Typography>
                            <Typography className={RFPCSS.footorAddress}>{glimpseFormData.prepared_by_address}</Typography>

                        </Box>
                        <Box className={RFPCSS.disclaimBox}>
                            <Typography className={RFPCSS.disclamer}>*This information has been captured from profile.</Typography>
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </>
    );
}
export default RfpQuesPreview;
