import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider, Grid, useMediaQuery } from '@mui/material';
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { Typography } from '@material-ui/core';
import cancel from "../../../../assets/icons/Cancel.svg"
import ConnectionTabs from './SubComponents/ConnectionTabs';
import CommonInitials from '../../../../common/components/CommonInitials';
import { useDispatch } from 'react-redux';
import { getFollowersList, getFollowingList, getReactionList, getShareData, } from '../../actions';
import { REACTION_LIST } from '../../../../common/constants';
import { useNavigate } from 'react-router';

export const ConnnectionList = ({ open, onClose, follower, Title, reaction, socialMedia, gid, master, share, type, commentId, commentsGid, replyId }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const [tabId, setTabId] = useState(REACTION_LIST.ALL)
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    //console.log("type", type)
    const handleCardClick = (gid) => {
        navigate(`/profile/${gid}`);
    }
    //console.log("fish", replyId)

    const getResourceData = () => {
        if (reaction === 'true') {
            return Array.isArray(socialMedia?.reactionList) ? socialMedia?.reactionList : [];
        } else if (follower === true) {
            return socialMedia.followersList;
        } else if (share === 'true') {
            return Array.isArray(socialMedia?.shareData) ? socialMedia?.shareData : [];
        } else {
            return socialMedia.followingList;
        }
    };
    useEffect(() => {
        if (reaction === 'true') {
            // console.log('Dispatching getReactionList with:', replyId);
            dispatch(getReactionList(gid, type, commentId, commentsGid, replyId));
        } else if (follower === true) {
            dispatch(getFollowersList());
        } else if (follower === false) {
            dispatch(getFollowingList());
        } else if (share === 'true') {
            dispatch(getShareData(gid));
        }
    }, []);
    // console.log('red',commentId)
    const resourceData = getResourceData();
    // const selectedTabReactid = resourceData?.find(reactId => reactId?.reaction_type_key)?.reaction_type_key;
    // const reactionList = master?.postReaction?.find(react => react?.key === selectedTabReactid);
    // const selectedReaction = reactionList ? reactionList.image : null;
    // console.log("ready", selectedReaction)
    return (
        <Dialog open={open} onClose={handleDlgClose} PaperProps={{ sx: { minWidth: isXsScreen ? '900px' : '300px', borderRadius: '16px', padding: '8px' } }}>
            <Grid cointainer className={SocialMediaCSS.connection}>
                <Grid item xs={12} className={SocialMediaCSS.connectionList}>
                    <Typography className={SocialMediaCSS.followerTypo}>{follower === true ? Title : reaction === 'true' || share === 'true' ? Title : 'Following'}</Typography>
                    <Box className={SocialMediaCSS.cancel}>
                        <img src={cancel} className={SocialMediaCSS.cursor} onClick={() => onClose()} />
                    </Box>
                </Grid>
            </Grid>
            <Divider className={SocialMediaCSS.followers} />

            {reaction &&
                <ConnectionTabs master={master} commentsGid={commentsGid} replyId={replyId}  setTabId={setTabId} gid={gid} tabId={tabId} socialMedia={socialMedia} commentId={commentId} />
            }
            {resourceData.length === 0 ? (
                <Typography variant="body1" align="center" className={SocialMediaCSS.MarginEmpty}>
                    No records found !!
                </Typography>
            ) : (
                <Grid item xs={12} className={`${SocialMediaCSS.followersList} ${SocialMediaCSS.scrollable}`}>
                    {getResourceData().map((data, index) => {
                        // const selectedTabReactid = data?.find(reactId => reactId?.reaction_type_key)?.reaction_type_key;
                        const selectedTabReactid = data?.reaction_type_key;
                        const reactionList = master?.postReaction?.find(react => react?.key === selectedTabReactid);
                        const selectedReaction = reactionList ? reactionList.image : null;
                        return (
                            <Box className={`${SocialMediaCSS.connectionGap} ${SocialMediaCSS.cursor}`} onClick={() => handleCardClick(reaction ? data.user_gid : data.gid)}>
                                <Box className={`${SocialMediaCSS.connectionCard}`}>
                                    <CommonInitials
                                        name={data?.initials}
                                        bgColor={data?.colour_code}
                                        divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '44px', minWidth: '44px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                        typoStyle={{ fontSize: '18px', lineHeight: '42px', }}
                                        avatarStyle={{ width: '44px', height: '44px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                        src={reaction || share ? data?.image_url?.url : data?.profile_pic?.url}
                                        companyLogo={reaction || share ? data?.image_url?.url : data?.profile_pic?.url}
                                        avatar={true}
                                    />
                                    <Box>
                                        <Typography className={SocialMediaCSS.resourcetypo}>{data.name}</Typography>
                                        <Typography className={SocialMediaCSS.eventThematic}>{reaction || share ? data.tagline : data.profile_tagline}</Typography>
                                    </Box>
                                    {reaction &&
                                        <Box className={SocialMediaCSS.reactionIcon}>
                                            <img src={selectedReaction} style={{ width: "24px", height: '24px' }} />
                                        </Box>}
                                </Box>
                            </Box>);
                    })}
                </Grid>
            )}
        </Dialog >
    );
};