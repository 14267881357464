import React, { useState } from 'react';
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";
import CommonInitials from '../../../../../common/components/CommonInitials';
import { Box, Typography } from '@mui/material';
import share from "../../../../../assets/icons/share_lightGrey.svg"
import { ConnnectionList } from '../../Popup/ConnectionList';
import { useSelector } from 'react-redux';
import { SharedList } from '../../Popup/SubComponents/SharedList';

const PostReshareHeader = ({ post }) => {
    const [sharedDialogOpen, setSharedDialogOpen] = useState(false);
    const { socialMedia } = useSelector((state) => { return state; });
    const getDynamicPadding = () => {
        if (post?.multiple_users[2]?.initials) return "105px";
        if (post?.multiple_users[1]?.initials) return "75px";
        if (post?.multiple_users[0]?.initials) return "45px";
        return "0px";
    };
    const handleSharedDialogOpen = () => {
        setSharedDialogOpen(true);
    };

    const handleSharedDialogClose = () => {
        setSharedDialogOpen(false);
    };
    return (
        <>
            <Box className={`${SocialMediaCSS.postHeader} ${SocialMediaCSS.otherHeader}`} onClick={handleSharedDialogOpen}>
                {post?.multiple_users[0]?.initials &&
                    <Box className={`${SocialMediaCSS.postHeaderLogo}`} sx={{ position: 'absolute', left: '0px', zIndex: 3 }}>
                        <CommonInitials
                            name={post?.multiple_users[0]?.initials}
                            bgColor={post?.multiple_users[0]?.colour_code}
                            divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                            typoStyle={{ fontSize: '18px', lineHeight: '44px', }}
                            avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                            src={post?.multiple_users[0]?.image}
                            companyLogo={post?.multiple_users[0]?.image}
                            avatar={true}
                        />
                    </Box>}
                {post?.multiple_users[1]?.initials &&
                    <Box className={SocialMediaCSS.postHeaderLogo} sx={{ position: 'absolute', left: '30px', zIndex: 2 }}>
                        <CommonInitials
                            name={post?.multiple_users[1]?.initials}
                            bgColor={post?.multiple_users[1]?.colour_code}
                            divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                            typoStyle={{ fontSize: '18px', lineHeight: '44px', }}
                            avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                            src={post?.multiple_users[1]?.image}
                            companyLogo={post?.multiple_users[1]?.image}
                            avatar={true}
                        />
                    </Box>}
                {post?.multiple_users[2]?.initials &&
                    <Box className={SocialMediaCSS.postHeaderLogo} sx={{ position: 'absolute', left: '60px', zIndex: 1 }}>
                        <CommonInitials
                            name={post?.multiple_users[2]?.initials}
                            bgColor={post?.multiple_users[2]?.colour_code}
                            divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                            typoStyle={{ fontSize: '18px', lineHeight: '44px', }}
                            avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                            src={post?.multiple_users[2]?.image}
                            companyLogo={post?.multiple_users[2]?.image}
                            avatar={true}
                        />
                    </Box>}

            </Box>
            {/* <Box className={SocialMediaCSS.postHeaderInfo} sx={{ paddingLeft: getDynamicPadding }}>
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                    {post?.multiple_users[0]?.name &&
                        <Typography variant='body2' className={SocialMediaCSS.postHeaderName} >
                            {post?.multiple_users[0]?.name}
                        </Typography>}
                    {post?.multiple_users[1]?.name &&
                        <Typography variant='body2' className={SocialMediaCSS.postHeaderName} >
                            ,{post?.multiple_users[1]?.name}
                        </Typography>}
                    {post?.multiple_users[2]?.name &&
                        <Typography variant='body2' className={SocialMediaCSS.postHeaderName} >
                            ,{post?.multiple_users[2]?.name}
                        </Typography>}
                    {post?.total_users_count < 3 &&
                        <Box pl={0.4}>
                            <Typography className={SocialMediaCSS.sharedOther}> has reshared this</Typography>
                        </Box>}
                </Box>
                {post?.total_users_count > 3 &&
                    <Box>
                        <Typography className={SocialMediaCSS.sharedOther}>and {post?.total_users_count} others reshared this</Typography>
                    </Box>}
            </Box > */}
            <Box className={SocialMediaCSS.postHeaderInfo} sx={{ paddingLeft: getDynamicPadding}}>
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center} ${SocialMediaCSS.postHeaderIn}`}>
                    {post?.multiple_users[0]?.name &&
                        <Typography variant='body2' className={`${SocialMediaCSS.postHeaderName} ${SocialMediaCSS.ReshareWord}`} >
                            {post?.multiple_users[0]?.name}
                        </Typography>}

                    {post?.total_users_count === 2 && post?.multiple_users[1]?.name &&
                        <Typography variant='body2' className={`${SocialMediaCSS.postHeaderName} ${SocialMediaCSS.ReshareWord}`}>
                           &nbsp; {' and '}{post?.multiple_users[1]?.name}
                        </Typography>}

                    {post?.total_users_count === 3 && post?.multiple_users[1]?.name &&
                        <Typography variant='body2'className={`${SocialMediaCSS.postHeaderName} ${SocialMediaCSS.ReshareWord}`}>
                            {", "}{post?.multiple_users[1]?.name}
                        </Typography>}

                    {post?.total_users_count === 3 && post?.multiple_users[2]?.name &&
                        <Typography variant='body2' className={`${SocialMediaCSS.postHeaderName} ${SocialMediaCSS.ReshareWord}`}>
                            &nbsp; {" and "}{post?.multiple_users[2]?.name}
                        </Typography>}

                    {post?.total_users_count > 3 && post?.multiple_users[1]?.name &&
                        <Typography variant='body2' className={`${SocialMediaCSS.postHeaderName} ${SocialMediaCSS.ReshareWord}`}>
                            {", "}{post?.multiple_users[1]?.name}
                        </Typography>}

                    {post?.total_users_count > 3 && post?.multiple_users[2]?.name &&
                        <Typography variant='body2' className={`${SocialMediaCSS.postHeaderName} ${SocialMediaCSS.ReshareWord}`}>
                            {", "}{post?.multiple_users[2]?.name}
                        </Typography>}

                    {post?.total_users_count <= 3 &&
                        <Box pl={0.4}>
                            <Typography className={SocialMediaCSS.sharedOther}>
                                {" has reshared this"}
                            </Typography>
                        </Box>}

                    {post?.total_users_count > 3 &&
                        <Box pl={0.4}>
                            <Typography className={SocialMediaCSS.sharedOther}>
                                {" and "}{post?.total_users_count - 3} others reshared this
                            </Typography>
                        </Box>}
                </Box>
            </Box>

            {post?.multiple_shared_post &&
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ReshareBorder}`} gap={0.5}>
                    <img src={share} />
                    <Typography variant='body4' className={SocialMediaCSS.otherText}>RESHARED</Typography>
                </Box>
            }
            {sharedDialogOpen &&
                <SharedList
                    open={sharedDialogOpen}
                    onClose={handleSharedDialogClose}
                    Title="Share"
                    socialMedia={socialMedia}
                    gid={post?.gid}
                />}
        </>
    );
};

export default PostReshareHeader;
