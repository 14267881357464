import React, { useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import logo from "../../../../assets/images/Health.svg";
import { ConnnectionList } from "../Popup/ConnectionList";
import { useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CommonInitials from "../../../../common/components/CommonInitials";
import theme from "../../../../assets/material-ui/theme";
import { useNavigate } from "react-router";
import { resetBookmarkType, setMyFeed } from "../../../../store/slices/SocialMediaSlice";
import { useDispatch } from "react-redux";
import CommonButton2 from "../../../../common/components/CommonButton2";


const ProfileSummary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [connectionDialogOpen, setConnectionDialogOpen] = useState(false);
    const [followClick, setFollowClick] = useState(false);
    const { users: { userData, companyTypes, isLoggedIn }, socialMedia } = useSelector((state) => state);

    const handleProfileDialogOpen = () => {
        setConnectionDialogOpen(true);
    };

    const handleProfileDialogClose = () => {
        setConnectionDialogOpen(false);
    };

    const postInitials = socialMedia?.postDashboard?.primary_post?.post_user_details?.initials;
    const postColorCode = socialMedia?.postDashboard?.primary_post?.post_user_details?.colour_code;
    const postName = socialMedia?.postDashboard?.primary_post?.post_user_details?.name;
    const postTagline = socialMedia?.postDashboard?.primary_post?.post_user_details?.tagline;
    const postProfilePic = socialMedia?.postDashboard?.primary_post?.post_user_details?.image?.url;

    const connectionTypeCount = socialMedia?.myDatabase?.Connection_type_count || [];
    const followersCountObj = connectionTypeCount.find(item => item.hasOwnProperty('followers'));
    const followingCountObj = connectionTypeCount.find(item => item.hasOwnProperty('following'));
    const myPostCountObj = connectionTypeCount.find(item => item.hasOwnProperty('my_posts'));
    const profileCompletionobj = connectionTypeCount.find(item => item.hasOwnProperty('profile_complete'));

    const followersCount = followersCountObj ? followersCountObj.followers : 0;
    const followingCount = followingCountObj ? followingCountObj.following : 0;
    const myPostCount = myPostCountObj ? myPostCountObj.my_posts : 0;
    const profileCompletion = profileCompletionobj ? profileCompletionobj.profile_complete : 0;
    const completionPercentage = Math.round(profileCompletion);
    //console.log("redl",profileCompletion)
    const handleMyPost = () => {
        dispatch(resetBookmarkType());
        navigate('/social-media/my-posts');
        dispatch(setMyFeed('myPosts'));
    }
    const handleUserProfile = (gid) => {
        if (isLoggedIn) {
            navigate(`/profile/${userData?.gid}`);
        } else {
            navigate('/login');
        }
    }
    return (
        <>
            <Box className={`${SocialMediaCSS.smCard} ${SocialMediaCSS.noTitleCard}`}>
                <Box className={SocialMediaCSS.profileCardLayout}>
                    {isLoggedIn ?
                        <>
                            <Box className={SocialMediaCSS.companyProfileInfoBox}>
                                <Box className={`${SocialMediaCSS.companyProfileImgBox} ${SocialMediaCSS.cursor}`} onClick={handleUserProfile}>
                                    <CommonInitials
                                        name={userData.initials}
                                        bgColor={userData.color_code}
                                        divStyle={{
                                            padding: '0px',
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '25px',
                                            boxShadow: theme.shadow.softShadow2,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexShrink: '0',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        typoStyle={{ fontSize: '14px', lineHeight: '32px' }}
                                        avatarStyle={{ width: '50px', height: '50px', borderRadius: '25px', boxShadow: theme.shadow.softShadow2, border: `2px solid ${theme.mainPalette.grey.grey100} `, backgroundColor: '#fff' }}
                                        src={userData.profile_pic}
                                        companyLogo={userData?.profile_pic}
                                        avatar={true}
                                    />
                                    <CircularProgressbar
                                        value={completionPercentage}
                                        circleRatio={0.9}
                                        strokeWidth={4}
                                        styles={{
                                            root: {},
                                            path: {
                                                stroke: theme.mainPalette.secondary.secondary110,
                                                strokeLinecap: 'round',
                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                transform: 'rotate(198deg)',
                                                transformOrigin: 'center center',
                                            },
                                            trail: {
                                                stroke: theme.mainPalette.grey.grey500,
                                                strokeLinecap: 'round',
                                                transform: 'rotate(198deg)',
                                                transformOrigin: 'center center',
                                            },
                                            text: {
                                                fill: theme.mainPalette.secondary.secondary110,
                                                fontSize: '16px'
                                            },
                                            background:
                                            {
                                                fill: 'transparent',
                                            },
                                        }}
                                        className={SocialMediaCSS.profileProgressBar}
                                    />
                                    {completionPercentage !== 100 && (
                                        <Box className={SocialMediaCSS.Percentage}>
                                            <Typography variant="body5" className={SocialMediaCSS.percTypo}>
                                                {`${completionPercentage}%`}
                                            </Typography>
                                        </Box>)
                                    }
                                </Box>
                                <Box className={SocialMediaCSS.CompnayTitle} >
                                    <Typography className={`${SocialMediaCSS.CompanyTypo} ${SocialMediaCSS.cursor}`} onClick={handleUserProfile}>{userData.name}</Typography>
                                    <Typography className={SocialMediaCSS.userType}>{userData?.tagline}
                                        {/* <Typography className={SocialMediaCSS.userType}>{userData?.user_personal_type || socialMedia?.myDatabase?.user_perosnal_type} */}
                                        {/* {companyTypes && companyTypes?.find((data) => data.key === userData?.company_type)?.value} */}
                                        {/* {companyTypes.find((data) => data.key === userData?.company_type)?.value} */}
                                        {/*   {capitalizeFirstLetter(userData.profile_type)}  */}

                                    </Typography>
                                </Box>
                            </Box>

                            <Divider className={SocialMediaCSS.leftBoxline} flexItem />

                            <Grid container spacing={'12px'}>
                                <Grid item xs={12} className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                                    <Typography className={SocialMediaCSS.Followers}>Followers</Typography>
                                    <Typography className={SocialMediaCSS.FollowersCount} onClick={() => { handleProfileDialogOpen(); setFollowClick(true); }} >{followersCount}</Typography>
                                </Grid>
                                <Grid item xs={12} className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                                    <Typography className={SocialMediaCSS.Followers}>Following</Typography>
                                    <Typography className={SocialMediaCSS.FollowersCount} onClick={() => { handleProfileDialogOpen(); setFollowClick(false); }}>{followingCount}</Typography>
                                </Grid>
                                <Grid item xs={12} className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                                    <Typography className={SocialMediaCSS.Followers}>My Posts</Typography>
                                    <Typography className={SocialMediaCSS.FollowersCount} onClick={handleMyPost}>{myPostCount}</Typography>
                                </Grid>
                            </Grid>
                        </> : <>
                            <Box className={SocialMediaCSS.companyProfileInfoBox}>
                                <Box className={`${SocialMediaCSS.companyProfileImgBox} ${SocialMediaCSS.cursor}`} onClick={handleUserProfile}>
                                    <CommonInitials
                                        name={postInitials}
                                        bgColor={postColorCode}
                                        divStyle={{
                                            padding: '0px',
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '25px',
                                            boxShadow: theme.shadow.softShadow2,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexShrink: '0',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        typoStyle={{ fontSize: '14px', lineHeight: '32px' }}
                                        avatarStyle={{ width: '50px', height: '50px', borderRadius: '25px', boxShadow: theme.shadow.softShadow2, border: `2px solid ${theme.mainPalette.grey.grey100} `, backgroundColor: '#fff' }}
                                        src={postProfilePic}
                                        companyLogo={postProfilePic}
                                        avatar={true}
                                    />
                                </Box>
                                <Box className={SocialMediaCSS.CompnayTitle} >
                                    <Typography className={`${SocialMediaCSS.CompanyTypo} ${SocialMediaCSS.cursor}`} onClick={handleUserProfile}>{postName}</Typography>
                                    <Typography className={SocialMediaCSS.userType}>{postTagline}
                                        {/* <Typography className={SocialMediaCSS.userType}>{userData?.user_personal_type || socialMedia?.myDatabase?.user_perosnal_type} */}
                                        {/* {companyTypes && companyTypes?.find((data) => data.key === userData?.company_type)?.value} */}
                                        {/* {companyTypes.find((data) => data.key === userData?.company_type)?.value} */}
                                        {/*   {capitalizeFirstLetter(userData.profile_type)}  */}

                                    </Typography>
                                </Box>
                            </Box>
                            <Grid container spacing={'12px'}>
                                <Grid item xs={12} className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                                    <CommonButton2
                                        label="Connect"
                                        name="Connect"
                                        type="Connect"
                                        variant="contained"
                                        // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                        style={{ padding: '6px 32px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', width: '150px', borderRadius: '20px' }}
                                        onClick={handleUserProfile}
                                    />
                                    <CommonButton2
                                        label='Follow'
                                        name='Follow'
                                        type='Follow'
                                        variant="outlined"
                                        // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                        style={{ padding: '6px 32px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, width: '150px', borderRadius: '20px' }}
                                        onClick={handleUserProfile}
                                    />
                                </Grid>
                            </Grid>
                        </>}

                </Box>
            </Box>
            {connectionDialogOpen &&
                <ConnnectionList
                    open={connectionDialogOpen}
                    onClose={handleProfileDialogClose}
                    Title="Followers"
                    socialMedia={socialMedia}
                    follower={followClick}
                />}
        </>
    );
}

export default ProfileSummary;