import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import SocialMediaCSS from "../../modules/SocialMedia/SocialMediaCSS/SocialMedia.module.css";
import { Box, Typography } from '@mui/material';
import hashtag from "../../assets/icons/Hashtag.svg";
import CommonInitials from './CommonInitials';
import { getHastagList } from '../actions';
import { useDispatch } from 'react-redux';

const RenderSocialInput = ({
    input,
    meta: { touched, error },
    placeholder,
    users,
    tags,
    maxLength,
    showCount,
    selectedTags,
    unSelectedTag,
    countFontSize,
    rows,
    onExceedLimit,
}) => {
    const dispatch = useDispatch();
    const getRegularContent = (value) => {
        // return value.replace(/@\[[^\]]+\]\([^)]+\)|#[^\s]+/g, '');
        return value.replace(/@\[[^\]]+\]\([^)]+\)|#[^\s]+(?:\s+[^\s]+)?/g, '');
    };
    const getMentionsAndTagsLength = (value) => {
        const mentions = value.match(/@\[[^\]]+\]\([^)]+\)/g) || [];
        const hashtags = value.match(/#[^\s]+(?:\s+[^\s]+)?/g) || [];
        return mentions.join('').length + hashtags.join('').length;
    };
    // const getMentionsAndTagsLength = (value) => {
    //     const mentionMatches = value.match(/@\[[^\]]+\]\([^)]+\)/g) || [];
    //     const hashtagMatches = value.match(/#[^\s]+(?:\s+[^\s]+)?/g) || [];

    //     const mentionDisplayLength = mentionMatches.reduce((acc, mention) => {
    //         const displayText = mention.match(/@\[[^\]]+\]/)?.[0]?.slice(2, -1) || '';
    //         return acc + displayText.length;
    //     }, 0);

    //     const hashtagDisplayLength = hashtagMatches.reduce((acc, hashtag) => {
    //         const displayText = hashtag.slice(1);
    //         return acc + displayText.length;
    //     }, 0);

    //     return mentionDisplayLength + hashtagDisplayLength;
    // };
    // const [charCount, setCharCount] = useState(input.value ? getRegularContent(input.value).length : 0);
    const [charCount, setCharCount] = useState(input.value ? getRegularContent(input.value).length + getMentionsAndTagsLength(input.value) : 0);
    const [exceeded, setExceeded] = useState(false);
    //console.log("he8re", unSelectedTag)
    const formatTagsAsMentions = (tags) => {
        return tags.map(tag => `${tag}`).join(' ');
    };
    useEffect(() => {
        const checkExceeded = () => {
            const totalLength = getRegularContent(input.value).length + getMentionsAndTagsLength(input.value);
            setCharCount(totalLength);
            setExceeded(totalLength > maxLength);
        };

        checkExceeded();
    }, [input.value, maxLength]);
    useEffect(() => {
        if (exceeded) {
            onExceedLimit(true);
        } else {
            onExceedLimit(false);
        }
    }, [exceeded, onExceedLimit]);
    //console.log("rod", charCount)

    // useEffect(() => {
    //     const uniqueTags = new Set(selectedTags);
    //     setSelectedTagValues(selectedTags);
    //     if (selectedTags.length > 0) {
    //         const existingValue = input.value || '';
    //         const mentionSet = new Set(existingValue.match(/@\[[^\]]+\]\([^)]+\)/g) || []);
    //         selectedTags.forEach(tag => mentionSet.add(tag));
    //         const newValue = `${existingValue} ${Array.from(mentionSet).join(' ')}`.trim();
    //         input.onChange(newValue);
    //     }
    // }, [selectedTags]);

    // useEffect(() => {
    //     if (!Array.isArray(selectedTags)) return;
    //     const existingValue = input.value || '';
    //     const mentionSet = new Set(existingValue.match(/@\[[^\]]+\]\([^)]+\)|#[^\s]+/g) || []);
    //     let newValue = existingValue;

    //     selectedTags?.forEach(tag => {
    //         if (!mentionSet.has(tag)) {
    //             mentionSet.add(tag);
    //             newValue += ` ${tag}`;
    //         }
    //     });

    //     newValue = newValue.trim();
    //     if (newValue !== existingValue) {
    //         input.onChange(newValue);
    //     }
    // }, [selectedTags]);
    useEffect(() => {
        if (!Array.isArray(selectedTags)) return;

        const existingValue = input.value || '';
        const mentionSet = new Set(existingValue.match(/@\[[^\]]+\]\([^)]+\)|#[^\s]+/g) || []);
        let newValue = existingValue;

        selectedTags.forEach(tag => {
            if (!mentionSet.has(tag)) {
                mentionSet.add(tag);
                newValue += ` ${tag}`;
            }
        });

        newValue = newValue.trim();
        if (newValue !== existingValue) {
            input.onChange(newValue);

            // Update charCount and exceeded state
            const regularContent = getRegularContent(newValue);
            const mentionsAndTagsLength = getMentionsAndTagsLength(newValue);
            const totalLength = regularContent.length + mentionsAndTagsLength;
            setCharCount(totalLength);
            setExceeded(totalLength > maxLength);
        }
    }, [selectedTags]);

    useEffect(() => {
        if (!Array.isArray(unSelectedTag)) return;
        const existingValue = input.value || '';
        let newValue = existingValue;

        unSelectedTag.forEach(tag => {
            // Remove the unselected tag from the input value
            const escapedTag = tag.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
            const tagPattern = new RegExp(`\\s*${escapedTag}`, 'g');
            newValue = newValue.replace(tagPattern, '');
        });

        newValue = newValue.trim();
        if (newValue !== existingValue) {
            console.log('Updating input value to remove unselected tags:', newValue);
            input.onChange(newValue);

            const regularContent = getRegularContent(newValue);
            const mentionsAndTagsLength = getMentionsAndTagsLength(newValue);
            const totalLength = regularContent.length + mentionsAndTagsLength;
            setCharCount(totalLength);
            setExceeded(totalLength > maxLength);
        } else {
            console.log('No change in value after removing unselected tags:', unSelectedTag);
        }
    }, [unSelectedTag]);
    //console.log("real", exceeded)


    const handleChange = (event, newValue) => {
        //debugger;
        let value = newValue || event.target.value;
        // if (value.length > maxLength) {
        //     value = value.slice(0, maxLength);
        // }
        const regularContent = getRegularContent(value);
        const mentionsAndTagsLength = getMentionsAndTagsLength(value);
        const totalLength = regularContent.length + mentionsAndTagsLength;
        setCharCount(totalLength);
        setExceeded(totalLength > maxLength);
        //setCharCount(regularContent.length);
        input.onChange(value);
        // dispatch(getHastagList());
        // Extract mentions, tags, and regular content
        const content = regularContent.trim();
        const mentionsRegex = /@\[[^\]]+\]\([^)]+\)/g; // Match mentions like @[User Two](user2)
        const hashtagsRegex = /#[^\]]+\]/g; // Match hashtags like #[Tag One]
        const mentions = value.match(mentionsRegex) || [];
        const hashtags = value.match(hashtagsRegex) || [];
        if (value.includes('#')) {
            dispatch(getHastagList());
        }

        console.log('Mentions:', mentions);
        console.log('Hashtags:', hashtags);
        console.log('Content:', content);
    };
    const renderSuggestion = (users, search, highlightedIndex) => {
        const { initials, color_code, image } = users;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CommonInitials
                    name={initials}
                    bgColor={color_code}
                    divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '36px', minWidth: '36px', borderRadius: '50%', cursor: 'pointer' }}
                    typoStyle={{ fontSize: '14px', lineHeight: '36px', }}
                    avatarStyle={{ width: '36px', height: '36px', boxShadow: " 5px 5px 10px 0px rgba(170, 170, 204, 0.50), -5px -5px 10px 0px #FFF" }}
                    src={image}
                    companyLogo={image}
                    avatar={true}
                />
                {/* <img src={users.image} alt="Tag Image" className={SocialMediaCSS.tagImg} style={{ marginRight: '12px', width: '36px', height: '36px' }} /> */}
                <Box ml={"12px"}>
                    <Typography className={SocialMediaCSS.tagHead}>{users.display}</Typography>
                    <Typography className={SocialMediaCSS.tagType}>{users.type}</Typography>
                </Box>
            </Box>
        );
    };
    const rendertagsSuggestion = (tags, search, highlightedIndex) => {

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={hashtag} alt="Tag Image" className={SocialMediaCSS.tagImg} style={{ marginRight: '12px', width: '36px', height: '36px' }} />
                <Box>
                    <Typography className={SocialMediaCSS.tagHead}>{tags.display}</Typography>
                    <Typography className={SocialMediaCSS.tagType}>{tags?.count} Public Post</Typography>
                </Box>
            </Box>
        );
    };
    const formattedUsers = users?.map(user => ({
        id: user.gid,
        display: user.value,
        image: user.image,
        type: user.type,
        initials: user.initials,
        color_code: user.colour_code
    }));
    const formattedTags = tags?.map(tag => ({
        id: tag.key,
        display: tag.value,
        count: tag.count
    }));
    return (
        <>
            <MentionsInput
                value={input.value}
                onChange={handleChange}
                placeholder={placeholder}
                style={{
                    control: {
                        fontSize: 14,
                        fontWeight: 'normal',
                        borderRadius: '16px',
                        border: '1px solid #cccccc',
                        // maxHeight: '200px',
                        // overflowY: 'auto',
                    },
                    '&multiLine': {
                        control: {
                            minHeight: 135,
                            maxHeight: 135,
                        },
                        highlighter: {
                            padding: 8,
                            border: '1px solid transparent',
                            height: 128,
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                        },
                        input: {
                            padding: 9,
                            minHeight: 63,
                            maxHeight: 135,
                            outline: 0,
                            border: 0,
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: 0,
                                background: 'transparent',
                            },
                            /* Hide scrollbar for Firefox */
                            '*::-webkit-scrollbar': {
                                display: 'none',
                            },
                            scrollbarWidth: 'none',
                        },
                    },
                    suggestions: {
                        '&::-webkit-scrollbar': {
                            width: 0,
                            background: 'transparent',
                        },
                        /* Hide scrollbar for Firefox */
                        '*::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                        maxHeight: 200,
                        overflowY: 'auto',
                        list: {
                            backgroundColor: 'white',
                            border: '1px solid rgba(0,0,0,0.15)',
                            fontSize: 14,
                            width: '400px',
                        },
                        item: {
                            padding: '5px 15px',
                            borderBottom: '1px solid rgba(0,0,0,0.15)',
                            '&focused': {
                                backgroundColor: '#cee4e5',
                            },
                        },
                    },
                }}
                inputProps={{ maxLength }}
                multiLine={true}
                rows={rows}
            >
                <Mention
                    trigger="@"
                    data={formattedUsers}
                    style={{
                        backgroundColor: '#daf4fa',
                        // backgroundColor: 'white',
                        // position: 'relative',
                        // zIndex: 1,
                        // color: 'blue',
                        // textShadow: '1px 2px 1px white, 1px 2px 1px white, 1px 2px 1px white,-1px 2px 2px white',
                        // // textDecoration: 'underline',
                        // pointerEvents: 'none',
                    }}
                    renderSuggestion={renderSuggestion}
                    className={SocialMediaCSS.scrollable}
                    displayTransform={(id, display) => `@${display}`}
                />
                <Mention
                    trigger="#"
                    // data={tags}
                    data={formattedTags}
                    style={{
                        fontStyle: 'italic',
                        backgroundColor: '#daf4fa',
                    }}
                    renderSuggestion={rendertagsSuggestion}
                    markup="#[__display__]"
                    displayTransform={(id, display) => `${display}`}
                // onAdd={() => {
                //     dispatch(getHastagList());
                // }}
                />
            </MentionsInput>
            <div style={{ display: 'flex', paddingTop: '6px' }}>
                {touched && error && (
                    <div className='error' style={{ marginRight: 'auto' }}>
                        {error}
                    </div>
                )}
                {/* {showCount && (
                    <span
                        style={{
                            fontSize: countFontSize ? countFontSize : '10px',
                            color: '#666',
                            fontWeight: '500',
                            marginLeft: 'auto',
                            fontStyle: 'italic',
                        }}
                    >
                        {`${charCount}/${maxLength}`}
                    </span>
                )} */}
                {showCount && (
                    <span
                        style={{
                            fontSize: countFontSize ? countFontSize : '10px',
                            color: '#D45454',
                            fontWeight: '500',
                            width: "100%",
                            // marginLeft: 'auto',
                            fontStyle: 'italic',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {exceeded && (
                            <Box sx={{ width: "100%", display: "flex", justifyContent: 'space-between' }} mb={2}>
                                <span>You have exceeded the maximum character limit by </span>
                                <span style={{ marginLeft: '5px', marginRight: '10px', fontWeight: 'bold' }}>{maxLength - charCount}</span>
                            </Box>
                        )}
                        {/* {!exceeded && (
                            <span>{charCount}/{maxLength}</span>
                        )} */}
                    </span>
                )}
            </div>
        </>
    );
};

export default RenderSocialInput;