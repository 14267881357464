import React, { useEffect, useState } from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Grid, useMediaQuery, Dialog, Tooltip } from "@mui/material";
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../../assets/icons/Cancel.svg"
import { useDispatch } from "react-redux";
import { setCreatePost, setPostAudience, setSelectedActivity, setSelectedThematic, setShowPostThematic, setUploadedFiles } from "../../../../../store/slices/SocialMediaSlice";
import arrow from "../../../../../assets/icons/arrow-back.svg"
import tick from '../../../../../assets/icons/charm_tick.svg';
import CommonButton2 from "../../../../../common/components/CommonButton2";
import { createPost, editPost } from "../../../actions";
import { reset } from "redux-form";
import share from "../../../../../assets/icons/shareWhite.svg"
import theme from "../../../../../assets/material-ui/theme";
import { setPostCategoryEdit } from "../../../../../store/slices/SocialMediaSlice";
import Search from "../PostSubComponents/Search";

const PostCategory = ({ form, onPostSelect, master, socialMedia, open, onClose, Title, post, }) => {
    const dispatch = useDispatch();
    const [selectedThematicAreas, setSelectedThematicAreas] = useState([]);
    const [thematicSelections, setThematicSelections] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const maxSelections = 5;

    const handleSearchChange = (search) => {
        setSearchQuery(search);
    };
    const handleCreateCancel = () => {
        dispatch(setPostCategoryEdit(false))
        onClose();
    };

    useEffect(() => {
        setThematicSelections(post?.primary_post?.post_thematic_area_ids || socialMedia.selectedThematic);
    }, []);
    // useEffect(() => {
    //     const initialSelections = post?.primary_post?.post_thematic_area_ids || socialMedia.selectedThematic;
    //     setThematicSelections(initialSelections);
    //     // dispatch(setSelectedThematic(initialSelections));
    // }, [post, socialMedia.selectedThematic, dispatch]);

    const handleBackClick = () => {
        dispatch(setPostCategoryEdit(false))
    }
    const handlePostClick = (gid) => {
        dispatch(setSelectedThematic(socialMedia.selectedThematic?.length !== 0 ? socialMedia.selectedThematic : post?.primary_post?.post_thematic_area_ids));
        dispatch(editPost(gid, onClose));
        dispatch(setShowPostThematic(false));
    }
    // const handleThematicSelection = (thematicId) => {
    //     const updatedSelections = thematicSelections.includes(thematicId)
    //         ? thematicSelections.filter((id) => id !== thematicId)
    //         : [...thematicSelections, thematicId];
    //     setThematicSelections(updatedSelections);
    //     dispatch(setSelectedThematic(updatedSelections));
    // };
    const handleThematicSelection = (thematicId) => {
        const updatedSelections = thematicSelections.includes(thematicId)
            ? thematicSelections.filter((id) => id !== thematicId)
            : thematicSelections.length < maxSelections
                ? [...thematicSelections, thematicId]
                : thematicSelections;

        setThematicSelections(updatedSelections);
        dispatch(setSelectedThematic(updatedSelections));
    };
    const nonPreselectedThematicAreas = master.thematicAreas.filter(thematic => !selectedThematicAreas.includes(thematic.id));
    const filteredNonPreselectedThematicAreas = nonPreselectedThematicAreas.filter(thematic => thematic.value?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
    const isUpdateDisabled = thematicSelections.length === 0 || post?.primary_post?.post_thematic_area_ids?.length === 0;
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleDlgClose}
                aria-labelledby="Edit Post"
                PaperProps={{ classes: { root: SocialMediaCSS.shareToPopup } }}
            >
                <Box className={SocialMediaCSS.postCard}>
                    <Box className={SocialMediaCSS.activityHeader}>
                        <Box className={`${SocialMediaCSS.postGap}`}>
                            <img src={arrow} onClick={handleBackClick} />
                            <Typography className={SocialMediaCSS.selectActivity} >
                                Select post category
                            </Typography>
                        </Box>
                        <Box className={SocialMediaCSS.cancelIcon} onClick={() => handleCreateCancel()} >
                            <img src={cancel} className={SocialMediaCSS.createPost} />
                        </Box>
                    </Box>
                    <Search onSearch={handleSearchChange} />
                    <Grid container spacing={2} className={`${SocialMediaCSS.PostThematic} ${SocialMediaCSS.scrollable}`} pb={4}>
                        <Grid item xs={12} className={SocialMediaCSS.PostThematic}>
                            {filteredNonPreselectedThematicAreas.map((thematic) => (
                                <Tooltip
                                    key={thematic.id}
                                    title={thematicSelections.length >= maxSelections && !thematicSelections.includes(thematic.id) ? 'Max selection reached' : ''}
                                    placement="top"
                                    arrow
                                >
                                    <Box
                                        key={thematic.id}
                                        className={SocialMediaCSS.PostThematicBox}
                                        sx={{
                                            color: thematicSelections.includes(thematic.id) ? '#fff' : '#333',
                                            fontWeight: thematicSelections.includes(thematic.id) ? 500 : 400,
                                            borderRadius: '30px',
                                            border: thematicSelections.includes(thematic.id) ? 'none' : '1px solid var(--BG-Secondary, #0DC366)',
                                            background: thematicSelections.includes(thematic.id)
                                                ? 'var(--BG-Secondary, linear-gradient(180deg, #0DC366 0%, #00A651 100%))'
                                                : 'var(--Primary-10, #EAF8FF)',
                                        }}
                                        onClick={() => handleThematicSelection(thematic.id)}
                                    >
                                        <Typography className={SocialMediaCSS.PostThematicTypo}>
                                            {thematic.value}
                                        </Typography>
                                        {thematicSelections.includes(thematic.id) && (
                                            <Box className={SocialMediaCSS.PostTickBox}>
                                                <img src={tick} alt="tick" />
                                            </Box>
                                        )}
                                    </Box>
                                </Tooltip>
                            ))}
                        </Grid>
                    </Grid>
                    <Box className={`${SocialMediaCSS.nextPost} ${SocialMediaCSS.margin}`} >
                        <CommonButton2
                            label="| Update"
                            name="update"
                            variant="contained"
                            startIcon={<img src={share} />}
                            type="button"
                            onClick={() => handlePostClick(post.gid)}
                            style={{
                                padding: '6px 24px',
                                fontSize: '14px',
                                height: '32px',
                                fontWeight: '600',
                                background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%',
                                color: "#FFF",
                                boxShadow: 'none',
                                opacity: isUpdateDisabled ? 0.5 : 1
                            }}
                            disabled={isUpdateDisabled ||master.pendingCall}
                            loading={master.pendingCall}
                        //disabled={socialMedia?.selectedThematic?.length === 0}
                        />
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default PostCategory;