import { createTheme } from '@mui/material/styles';
import { green, grey, lightBlue } from '@mui/material/colors';

export default createTheme({

    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The default props to change
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            // main: "#42BEE8",
            main: "#0A97D9", //primary
            light: "#06BB70", //secondary110
            dark: "#0775BA", //primary110
            field: "#FBB03B",
            contrastText: "#666666",
        },
        secondary: {
            main: "#09DA6F",
            main110: "#06BB70",
            light: "#F25F3D",
            dark: "#FFFFFF",
            contrastText: "#0A97D9",
        },
        success: {
            main: "#239F30",
            silk: "#96F6A0",
            light: "#666666",
            dark: '#333333',
            contrastText: '#FFFFFF',
            success10: '#DDFAD3'
        },
        error: {
            main: '#D45454',
            contrastText: '#FFFFFF'
            // dark: '#940000',
            // light: '#FC3844'
        },
        neutral: {
            main: '#FBB03B',
            dark: '#0A97D9',
            grey: '#CCCCCC',
        },
        tertiary: {
            main: '#09DA6F',
            light: '#CCFDD0',
        },
        winner: {
            main: '#4C9F38',
        },
        grey: {
            main: '#F5F5F5',
            secondary:'#F5F5F8', //Grey-100
            light: '#ADB5BD',
            lightGreyVoilet:  '#D5CCDF',
            background:"#EDEDED",
            neutral:"#E6E6E6",
            white:'#ffffff'
        },
        warning:{
            main: '#FD9D24',
            dark110: '#D97C1A',
            light80: '#FDBC5A',
            light70: '#FED07B',
            light20: '#FEE3A7',
            light10: '#FEF3D3',
        }

    },

    mainPalette:{
        primary: {
            primary110: "#0775BA",
            main: "#0A97D9",
            primary80: "#42BEE8",
            primary70: "#68D9F3",
            primary20: "#9BEFFB",
            primary10: "#CCFAFD",
            lightBlue: "#EAF8FF",
        },
        secondary: {
            secondary110: "#06BB70",
            main: "#09DA6F",
            secondary80: "#41E882",
            secondary70: "#67F390",
            secondary20: "#9AFBAD",
            secondary10: "#CCFDD0",
        },
        grey: {
            white: "#FFFFFF",
            grey100: "#F5F5F8",
            grey400: "#CCCCCC",
            grey500: "#ADB5BD",
            lightText : "#666666",
            darkText : "#333333",
            darkGrey: "#121212",
            black: "#000000",
        },
        success: {
            success110: "#19882F",
            success: "#239F30",
            success80: "#51C553",
            success70: "#80E278",
            success20: "#B5F5A8",
            success10: "#DDFAD3",
        },
        warning: {
            warning110: "#D97C1A",
            warning: "#FD9D24",
            warning80: "#FDBC5A",
            warning70: "#FED07B",
            warning20: "#FEE3A7",
            warning10: "#FEF3D3",
        },
        danger: {
            main: "#D45454",
            light: "rgba(212, 84, 84, 0.20)",
        }
    },

    status: {
        danger: 'orange',
    },

    button: {
        color: '#7a2ed6',
    },
    typography: {
        htmlFontSize: 16,
        // fontFamily: ["Roboto", "Helvetica", "sans-serif", "poppins"].join(","),
        fontFamily: ["poppins"].join(","),
        fontSize: 14,
        lineHeight: 1,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightSemiBold: 600,
        fontWeightBold: 700,

        h1: {
            fontSize: 40,
        },
        h2: {
            fontSize: 36,
        },
        h3: {
            fontSize: 32,
        },
        h4: {
            fontSize: 28,
        },
        h5: {
            fontSize: 24,
        },
        h6: {
            fontSize: 20,
        },

        subtitle1: {
            fontSize: 22,
        },
        // subtitle2: buildVariant(fontWeightLight, 14, 1.57, 0.1),
        subtitle2: {
            fontSize: 18,
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
        },
        body3: {
            fontSize: 15,
        },
        body4: {
            fontSize: '0.75rem',
        },
        body5: {
            fontSize: '0.625rem',
        },
        body6: {
            fontSize: '0.5rem',
        },
        button: {
            textTransform: 'none',
            // fontFamily: 'Roboto',
            fontWeight: 600,
            fontSize: 16,
        },

        // caption: h6 as per our design
        // overline: buildVariant(fontWeightRegular, 12, 2.66, 1),
    },

    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#F5F5F8',
                },
            },
        },
    },

    shadow:{
        softShadow: "0px 1px 8px 0px rgba(110, 110, 110, 0.10)",
        softShadow2: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)",
        softShadow3: "0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 6px -1px rgba(0, 0, 0, 0.12)",
        softShadow4: "0px 4px 6px -1px rgba(0, 0, 0, 0.12)",
        hardShadow: "3px 3px 3px 1px rgba(0, 0, 0, 0.20)",
        neoMorphismShadow : "5px 5px 10px 0px rgba(170, 170, 204, 0.5), -5px -5px 10px 0px rgba(255, 255, 255, 1)",
        neoMorphismShadow2: "-5px -5px 2px 0px rgba(255, 255, 255, 0.50), 5px 5px 10px 0px rgba(96, 161, 195, 0.25), 10px 10px 20px 0px rgba(96, 161, 195, 0.05), -10px -10px 20px 0px #FFF",
        neoMorphismShadowLight: "-7px -7px 7px  0px rgba(255, 255, 255, 1), 4px 4px 3px 0px rgba(209, 209, 209, 1)",
    },

    gradient:{
        primary: {
            btt:'linear-gradient(0deg, #0A97D9 0%, #0387C5 100%)',
            ltr:'linear-gradient(90deg, #0A97D9 0%, #0387C5 100%)',
            ttb:'linear-gradient(180deg, #0A97D9 0%, #0387C5 100%)',
            rtl:'linear-gradient(270deg, #0A97D9 0%, #0387C5 100%)',
        },
        secondary: {
            btt:'linear-gradient(0deg, #0DC466 0%, #00A651 100%)',
            ltr:'linear-gradient(90deg, #0DC466 0%, #00A651 100%)',
            ttb:'linear-gradient(180deg, #0DC466 0%, #00A651 100%)',
            rtl:'linear-gradient(270deg, #0DC466 0%, #00A651 100%)',
        },
        grey: 'linear-gradient(0deg, #999999 0%, #9F9F9F 100%)',
    }


});
