import { Box, CircularProgress, Fade, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import theme from '../../../../../assets/material-ui/theme'
import CommonButton2 from '../../../../../common/components/CommonButton2'
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import userImage2 from '../../../../../assets/images/user_profile_image.jpeg';
import { VOL_INVITE_TABS } from '../../../../../common/constants';
import CommonInitials from '../../../../../common/components/CommonInitials';
import { getAllVolInvitations, invitationAccept, invitationDeny, invitationWithdraw } from '../../../actions';
import { debounce, getCompanyTypeByKey } from '../../../../../common/utils';
import _ from 'lodash-es';
import SocialMediaEventCSS from '../../../SocialMediaCSS/SocialMediaEvent.module.css';

const VolinvitationList = ({ dispatch, navigate, selectedConnTab, volInvitationList, totalVolInvitationCount, offset, containerRef }) => {
    const [loading, setLoading] = useState(false);
    const isFetching = useRef(false);
    const lastScrollTop = useRef(0);
    let totalCount = useRef(0);
    let totalData = useRef(0);

    useEffect(() => {
        if (volInvitationList) {
            totalCount.current = volInvitationList.length;
            totalData.current = totalVolInvitationCount;
        }
    }, [volInvitationList, totalVolInvitationCount])

    const handleScroll = useCallback(() => {
        if (isFetching.current) return;
        const container = containerRef.current;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        if (scrollTop > lastScrollTop.current && scrollTop + clientHeight >= scrollHeight - 50 && totalCount.current < totalData.current) { // Adjusted threshold for triggering
            fetchMoreData();
        }

        lastScrollTop.current = scrollTop;
    }, []);

    const debouncedHandleScroll = debounce(handleScroll, 300);
    const fetchMoreData = () => {
        try {
            isFetching.current = true;
            offset.current += 10;
            dispatch(getAllVolInvitations(offset.current, true, setLoading, isFetching));
        } catch (error) {
            isFetching.current = false;
        }
    };
    useEffect(() => {
        dispatch(getAllVolInvitations());
    }, [])

    const handleAccept = (gid) => {
        dispatch(invitationAccept(gid));
    }

    const handleDeny = (gid) => {
        dispatch(invitationDeny(gid));
    }

    const handleWithdraw = (gid) => {
        dispatch(invitationWithdraw(gid));
    }

    const goToProfile = (gid) => {
        navigate(`/profile/${gid}`)
    }

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', debouncedHandleScroll);
        return () => {
            container.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, []);

    return (
        <Grid item xs={12}>
            <Box className={SocialMediaCSS.communityMainCardGrid}>
                <Grid container spacing={2}>
                    {!_.isEmpty(volInvitationList) && volInvitationList.map((data) => (
                        <Grid item xs={12} sm={6} md={4} xl={3} key={data?.gid}>
                            <Box className={SocialMediaCSS.communityMainCardBox}>
                                <Box className={SocialMediaCSS.communityMainCardBox2}>
                                    <CommonInitials
                                        name={data?.initials}
                                        bgColor={data?.colour_code}
                                        divStyle={{ padding: '3px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '10px', boxShadow: theme.shadow.softShadow2, flex: '0 0 70px', cursor: 'pointer', }}
                                        typoStyle={{ fontSize: '26px', lineHeight: '60px', }}
                                        avatarStyle={{ width: '70px', height: '70px', boxShadow: theme.shadow.softShadow2, cursor: 'pointer', borderRadius: '10px', }}
                                        src={data?.profile_pic}
                                        companyLogo={data?.profile_pic}
                                        avatar={true}
                                        onClick={() => goToProfile(data?.user_gid)}
                                    />
                                    <Box className={SocialMediaCSS.communityMainCardBox4}>
                                        <Typography className={SocialMediaCSS.communityMainCardTypo}>{data?.profile_name}</Typography>
                                        {/* <Typography className={SocialMediaCSS.communityMainCardTypo2}>{dispatch(getCompanyTypeByKey(data?.user_type))}</Typography> */}
                                        {data?.profile_tagline && <Typography className={SocialMediaCSS.communityMainCardTypo2}>{data?.profile_tagline}</Typography>}
                                    </Box>
                                </Box>
                                <Box className={SocialMediaCSS.communityMainCardBox3}>
                                    {selectedConnTab === VOL_INVITE_TABS.RECIEVED ?
                                        <>
                                            <CommonButton2
                                                label="Accept"
                                                name="Accept"
                                                type="Accept"
                                                variant="contained"
                                                // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                style={{ padding: '2px 16px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', width: '100px', borderRadius: '20px' }}
                                                onClick={() => { handleAccept(data?.gid) }}
                                            />
                                            <CommonButton2
                                                label='Decline'
                                                name='Decline'
                                                type='Decline'
                                                variant="outlined"
                                                // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                style={{ padding: '2px 16px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                                                onClick={() => handleDeny(data?.gid)}
                                            />
                                        </> :
                                        <CommonButton2
                                            label='Withdraw'
                                            name='Withdraw'
                                            type='Withdraw'
                                            variant="outlined"
                                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                            style={{ padding: '2px 16px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                                            onClick={() => handleWithdraw(data?.gid)}
                                        />
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            
            {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '100ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <Box className={SocialMediaEventCSS.loadingBox}>
                        <CircularProgress />
                    </Box>
                </Fade>
            </Box>}
            {_.isEmpty(volInvitationList) && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
                <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>No volunteering invites yet for {selectedConnTab === VOL_INVITE_TABS.RECIEVED ? 'Recieved' : 'Accepted'}</Typography>
            </Box>}
        </Grid>
    )
}

export default VolinvitationList