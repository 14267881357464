import { reset } from 'redux-form';
import _ from 'lodash-es';
import { setLogin, setCompanyType, setUserData, setTempUserData, setForgotPassData, updateUserListData, setLoginTokens, setProfileSkip } from "../../store/slices/UserSlice";
import {
    LOGIN_URL,
    GET_COMPANY_TYPES,
    SIGNUP_URL,
    FORGOT_PASSWORD,
    REGISTRATION_TEMP,
    RESET_PASSWORD,
    DEMO,
    RESEND_OTP,
    TOKEN_DATA,
    ACTIVATE_USER,
    GET_PROFILE_TYPES,
    SIGNOUT_SESSION,
} from '../../http/apiUrls';
import { httpMethod, LOGIN_DEVICE, PROFILE_FLOW_KEYS, EVENT_DASHBOARD, LOCAL_STORAGE_KEYS } from '../../common/constants';
import { doLogout, fetchAPI } from '../../common/actions';
import { setSnackbar } from '../../store/slices/MessageSlice';
import { getCookie, moveToSocialMedia, setCookie, setLocalStorage } from '../../common/utils';
import { setEventPrimaryTab } from '../../store/slices/EventSlice';
import { setIsLoad, setProfileTypes } from '../../store/slices/MasterSlice';
import { resetProfileSlice, setUserRoles } from '../../store/slices/ProfileSlice';

export const doLogin = (data, navigate, onClose, registerPage) => (dispatch, getState) => {
    const dataToPost = {
        email: data.email,
        password: data.password,
    };
    const header = false;
    dispatch(setIsLoad(true));
    dispatch(fetchAPI(LOGIN_URL, httpMethod.POST, dataToPost, header)).then(
        response => {
            if (response.success) {
                dispatch(updateUserList());  // setting default false for all login users 
                dispatch(loginDataUpdate(response.data, navigate, onClose, registerPage));
                dispatch(setUserListCookie(response));
                dispatch(setProfileSkip(false));
                // dispatch(setIsLoad(false));
                // moveToSocialMedia();
            } else if (response.error) {
                dispatch(setIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const loginDataUpdate = (data, navigate, onClose, registerPage) => (dispatch) => {
    dispatch(setLogin(true));
    dispatch(setUserData(data));
    dispatch(setIsLoad(false));
    if (onClose) {
        onClose();
        if (!registerPage) {
            moveToSocialMedia();
        }
    } else {
        navigate("/social-media");
    }
    // 
    // if(onClose){
    //     setTimeout(() => {
    //         window.location.reload();
    //     }, 400);
    // }else{
    //     navigate("/social-media");
    // }
}

export const getCompanyType = () => (dispatch, getState) => {
    dispatch(fetchAPI(GET_COMPANY_TYPES, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setCompanyType(response.data.company_types))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getProfileTypes = () => (dispatch, getState) => {
    dispatch(fetchAPI(GET_PROFILE_TYPES, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setProfileTypes(response.data.profile_types))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const doTempRegister = (data, profile_type, company_type, navigate, handleSignUpOpen) => (dispatch, getState) => {
    const { users: { tempUserData } } = getState();
    const dataToPost = {
        email: data.email,
        name: data.name,
        company_type: company_type || tempUserData?.company_type,
        profile_type: profile_type || tempUserData?.profile_type,
    };
    dispatch(fetchAPI(REGISTRATION_TEMP, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setTempUserData(dataToPost));
                if (navigate) {
                    navigate("/signup-mail-sent", { state: { name: data.name } });
                } else {
                    handleSignUpOpen();
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    )
}

export const completeRegistration = (data, navigate, onClose) => (dispatch, getState) => {
    dispatch(setIsLoad(true));
    const { users: { tempUserData: { email } } } = getState();
    const dataToPost = {
        password: data.password,
        email,
        verification_code: data.verification_code,
    };
    const header = false;
    dispatch(fetchAPI(SIGNUP_URL, httpMethod.POST, dataToPost, header)).then(
        response => {
            if (response.success) {
                dispatch(resetProfileSlice());
                dispatch(loginDataUpdate(response.data, navigate, onClose));
                dispatch(setTempUserData({}));
                if (onClose) {
                    onClose();
                }
                dispatch(updateUserList());  // setting default false for all login users 
                dispatch(setUserListCookie(response));
                dispatch(setProfileSkip(false));
                dispatch(setIsLoad(false));
                // navigate("/social-media");
                navigate("/social-media");
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const sendForgotPassLink = (data, navigate) => (dispatch, getState) => {
    const dataToPost = {
        email: data.email,
    };

    dispatch(fetchAPI(FORGOT_PASSWORD, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setForgotPassData(dataToPost));
                navigate('/reset-password');
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const resetPassword = (data, navigate) => (dispatch, getState) => {
    const { users: { forgotPassData: { email } } } = getState();
    const dataToPost = {
        password: data.password,
        email: email,
        verification_code: data.resetPasswordOtp
    };
    dispatch(fetchAPI(RESET_PASSWORD, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(loginDataUpdate(response.data, navigate));
                dispatch(reset('resetPassword'));
                dispatch(reset('forgotPassword'));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

const setUserDataToSlice = (data) => (dispatch, getState) => {
    dispatch(setUserData(data));
    dispatch(setUserRoles(data?.data?.userRoles));
}

export const removeOlderMessages = () => (dispatch, getState) => {
    const userListCookie = getCookie('userList') ? JSON.parse(getCookie('userList')) : "";
    const { users: { userList } } = getState();
    if (userList.length > 5) {
        let newList = [...userList];
        let newCookieList = [...userListCookie];
        newList.shift();
        newCookieList.shift();
        setCookie('userList', JSON.stringify(newCookieList), 30);
        dispatch(updateUserListData(newList));
    }
}

export const setUserListCookie = (response) => (dispatch, getState) => { // set user cookie at login time
    const { users } = getState();
    const filterUserList = filterUserListFromCookie(response?.data?.gid);
    const updatedData = filterUserList.map((data) => {
        let newData = {
            ...data,
            default: false,
        };
        return newData;
    });
    const dataToSet = {
        token: response?.refreshToken,
        default: true,
        gid: response?.data?.gid,
    }
    updatedData.push(dataToSet);
    setCookie('userList', JSON.stringify(updatedData), 30);
    // dispatch(updateUserListData(updatedData));
    dispatch(updateUserListInfo(response));
}

export const updateUserListInfo = (response) => (dispatch, getState) => { // update the userList in redux
    const { users: { userList } } = getState();
    const filteredData = dispatch(filterUserList(response.data.email));
    const updatedData = filteredData.map((data) => {
        let newData = {
            ...data,
            default: false,
        };
        return newData;
    });
    const dataToSet = { // set the login user as default
        ...response.data,
        default: true
    }
    updatedData.push(dataToSet);
    dispatch(updateUserListData(updatedData));
}

export const filterUserListFromCookie = (gid) => { // get filtered cookies
    const userList = getCookie('userList') ? JSON.parse(getCookie('userList')) : "";
    let filterUserListData = new Array();
    if (userList) {
        filterUserListData = userList && userList.filter((userData) => userData.gid !== gid);
    }
    return filterUserListData;
}

export const setLoginToken = (response) => (dispatch, getState) => {
    setCookie('accessToken', response.data.accessToken, 30);
}

export const filterUserList = (email) => (dispatch, getState) => {
    const { users: { userList } } = getState();
    const filterUserListData = userList && userList.filter((userData) => userData?.email !== email);
    return filterUserListData;
}

export const updateUserList = () => (dispatch, getState) => { // Nsetting default false for all login users 
    const { users: { userList } } = getState();
    const updatedData = userList.map((data) => {
        let newData = {
            ...data,
            default: false,
        };
        return newData;
    });
    dispatch(updateUserListData(updatedData));
    setLocalStorage(LOCAL_STORAGE_KEYS.USER_DATA, updatedData);
}

export const switchProfile = (gid, navigate) => (dispatch, getState) => {
    dispatch(setProfileSkip(false));
    dispatch(setIsLoad(true));
    const userList = getCookie('userList') ? JSON.parse(getCookie('userList')) : "";
    const updatedData = userList.map((data) => {
        let newData = {
            ...data,
            default: data.gid === gid ? true : false,
        };
        return newData;
    });
    setCookie('userList', JSON.stringify(updatedData), 30);
    dispatch(updateLoginDataFromCookies(true));
    // moveToSocialMedia();
}

export const checkUserPermission = (role, navigate) => (dispatch, getState) => {
    const { users: { userData: { data: { role_id } } }, profile: { userRoles } } = getState();
    if (!_.isEmpty(userRoles) && userRoles.indexOf(role) < 0 && role_id === 2) {
        navigate("/page-not-found");
    }
}

export const getTokenList = (userList) => {
    const tokenList = userList.map((data) => data.token);
    return tokenList;
}

export const getDefaultToken = () => {
    const userList = getCookie('userList') ? JSON.parse(getCookie('userList')) : "";
    if (userList === '') return null; // return for null entry 
    const filterUserList = userList && userList.filter((userData) => userData.default === true);
    const token = filterUserList[0]?.token || null;
    return token;
}

export const updateLoginDataFromCookies = (profileSwitch = false) => (dispatch) => { // fetch data as per the token from the cookie on refresh the page
    const userList = getCookie('userList') ? JSON.parse(getCookie('userList')) : "";
    if (userList === '') return; // return for null 
    const filterUserList = userList && userList.filter((userData) => userData.default === true);
    const defaultId = filterUserList[0]?.gid || null;
    let tokens = getTokenList(userList);
    if (tokens.length < 1 || tokens[0] === null) {
        return; // return for null 
    }
    const dataToPost = {
        refresh_tokens: [...tokens],
    }
    dispatch(fetchAPI(TOKEN_DATA, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                if (response.data.length > 1) {
                    const updatedUserList = response.data.map((data) => {
                        let newData = {
                            ...data,
                            default: false,
                        };
                        if (data.gid === defaultId) {
                            newData = {
                                ...data,
                                default: true,
                            }
                            dispatch(getUserDataFromCookie(data)); // set the default data in user redux
                            dispatch(setLoginTokens({ // set the default token for t
                                accessToken: data?.access_token,
                                refreshToken: data?.refresh_token
                            }));
                        }
                        return newData;
                    });
                    dispatch(updateUserListData(updatedUserList));
                } else {
                    const newData = {
                        ...response.data[0],
                        gid: response?.data[0]?.gid,
                        default: true,
                    }
                    dispatch(updateUserListData([newData]));
                    dispatch(setUserDataToSlice(newData));
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
    if (profileSwitch) { // it will call when you switch profile
        setTimeout(() => {
            moveToSocialMedia();
        }, 1000)
    }
}

export const getUserDataFromCookie = (data) => (dispatch) => { // set default single info in to the redux
    dispatch(setUserDataToSlice(data));
}

export const logoutSingleAccount = (gid, navigate) => (dispatch, getState) => {
    const { users: { userData } } = getState();
    dispatch(signoutSession(userData?.email));
    dispatch(removeSingleAccount(gid, true));
}

export const removeSingleAccount = (gid, logout = false, email) => (dispatch, getState) => {
    const userList = getCookie('userList') ? JSON.parse(getCookie('userList')) : "";
    const updatedData = userList && userList.filter((userData) => userData.gid !== gid);
    if (updatedData.length > 0) {
        setCookie('userList', JSON.stringify(updatedData), 30);
        dispatch(getFilterUserList(gid));
        if (!logout) { // refresh on delete only
            dispatch(signoutSession(email));
            setTimeout(() => {
                window.location.reload();
            }, 10);
        } else {
            dispatch(setUserDataToSlice({})); // set empty userData 
            dispatch(setLogin(false)); // set login false
        }
    } else {
            dispatch(doLogout());
            return;
    }
}

export const getFilterUserList = (gid) => (dispatch, getState) => {
    const { users: { userList } } = getState();
    const updatedData = userList && userList.filter((userData) => userData?.gid !== gid) || [];
    dispatch(updateUserListData(updatedData))

}

export const resendOTP = (email) => (dispatch, getState) => {
    const dataToPost = {
        email
    }
    dispatch(fetchAPI(RESEND_OTP, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const resetRegistrationForms = () => (dispatch) => {
    dispatch(reset('loginForm'));
    dispatch(reset('resetPassword'));
    dispatch(reset('setAccountPassword'));
    dispatch(reset('signUP'));
    dispatch(reset('signUPMailSent'));
}

export const getDemo = (values) => (dispatch, getState) => {
    // console.log(values, "values")
    const dataToPost = {
        full_name: values?.fullName,
        company_name: values?.companyName,
        email: values?.email,
        contact_number: values?.contactNumber,
        description: values?.description
    }
    dispatch(fetchAPI(DEMO, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(reset("Demo"));
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const setActivateUser = (token, navigate) => (dispatch, getState) => {
    const dataToPost = {
        token
    }
    dispatch(fetchAPI(ACTIVATE_USER, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                navigate('/login');
                // dispatch(setCompanyType(response.data))
            } else if (response?.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message?.message, type: "error" }));
            }
        },
    );
}
export const signoutSession = (email) => (dispatch, getState) => {
    // const { users: { userData } } = getState();
    const dataToPost = {
        email: email
    }
    dispatch(fetchAPI(SIGNOUT_SESSION, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                // navigate('/login');
            } else if (response?.error) {
                // dispatch(setSnackbar({ flag: true, message: response?.message?.message, type: "error" }));
            }
        },
    );
}