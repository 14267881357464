// ChatScreen.js
import React, { useState, useEffect } from 'react';
import { Avatar, Box, Card, MenuItem, Typography, Menu, Grid, IconButton, Divider } from '@mui/material';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import LeftChatSideBar from './LeftChatSideBar';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import CommonInitials from '../../../../common/components/CommonInitials';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import green_badge from '../../../../assets/icons/green_badge.svg'
import busy_badge from '../../../../assets/icons/busy_badge.svg'
import away_badge from '../../../../assets/icons/away_badge.svg'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getChatMessages, getConnectionList, sendChatMessages } from '../../actions';
import { getCompanyTypeByName, getConnectionTypeByName, timeAgo } from '../../../../common/utils';
import theme from '../../../../assets/material-ui/theme';
import cable from '../../../../cable';
import { chatMessagesInitialFlag, setChatMessages, setConnectionList, setIsChatMessagesLoading } from '../../../../store/slices/SocialMediaSlice';
import { useNavigate } from 'react-router';
export const UserProfile = ({ src, name, subtitle, status, initials, color_code, onChangeStatus, ownwer,gid}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStatusChange = (newStatus) => {
        onChangeStatus(newStatus);
        handleClose();
    };
    let companyType = subtitle ? dispatch(getConnectionTypeByName(subtitle)) : 'N/A';
    //console.log("ff",initials)
    const handleUserProfile = (e) => {
        e.stopPropagation();
        navigate(`/profile/${gid}`);
    }
    return (
        <Box className={SocialMediaCSS.UserProfileBox}>
            <Box className={SocialMediaCSS.cursor} onClick={handleUserProfile}>
            <CommonInitials
                name={initials}
                bgColor={color_code}
                divStyle={{ padding: '3px', maxHeight: '48px', maxWidth: '48px', minHeight: '48px', minWidth: '48px', borderRadius: '50%', boxShadow: theme.shadow.softShadow, flex: '0 0 48px', borderColor: '#e9ecef', border: '1px solid #dee2e6' }}
                typoStyle={{ fontSize: '16px', lineHeight: '40px', }}
                avatarStyle={{ width: '48px', height: '48px', boxShadow: theme.shadow.softShadow, }}
                src={src}
                companyLogo={src}
                avatar={true}
            /></Box>
            <Box className={SocialMediaCSS.profileUserStatus}>
                <Typography variant='body2' className={`${SocialMediaCSS.chatUserName} ${SocialMediaCSS.cursor}`} onClick={handleUserProfile}>{name}</Typography>
                <Typography variant='body2' fontWeight={600} color="text.secondary" noWrap>{companyType}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {!ownwer ? (
                        <>
                            <img src={status === 'Available' ? green_badge : status === 'Busy' ? busy_badge : away_badge} width={'12px'} />
                            <Typography variant='body4' fontWeight={500} color="text.secondary">
                                {status}
                            </Typography>
                        </> ) : (
                        <>
                            {/* <IconButton size="small" onClick={handleClick} className={SocialMediaCSS.chatStatusIconBox} disableRipple> */}
                                <img src={status === 'Available' ? green_badge : status === 'Busy' ? busy_badge : away_badge} />
                                <Typography variant='body4' fontWeight={500} color="text.secondary">
                                    {status}
                                </Typography>
                                {/*<ArrowDropDownIcon fontSize="small" />
                            </IconButton>
                             <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                                <MenuItem onClick={() => handleStatusChange('Available')} sx={{ gap: '10px' }}>
                                    <img
                                        className="me-2"
                                        src={green_badge}
                                        alt=""
                                        height="12"
                                    />
                                    Available
                                </MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Busy')} sx={{ gap: '10px' }}>
                                    <img
                                        className="me-2"
                                        src={busy_badge}
                                        alt=""
                                        height="12"
                                    />
                                    Busy
                                </MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Away')} sx={{ gap: '10px' }}>
                                    <img
                                        className="me-2"
                                        src={away_badge}
                                        alt=""
                                        height="12"
                                    />
                                    Away
                                </MenuItem>
                            </Menu> */}
                        </> )
                    }
                </Box>
                
            </Box>
        </Box>
    )
};

export const UserCard = ({ list, dispatch, setBrandData,brandData }) =>{

    const handleConnectionClick = (gid) =>{
        dispatch(setChatMessages([]));
        dispatch(setIsChatMessagesLoading(true));
        setTimeout(() => {
            dispatch(getChatMessages(gid));  
            setBrandData(list) 
        }, 1000);
    }
    const isSelected = brandData?.gid === list?.gid;
    return(
    <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', p: 1, mb: 1, borderRadius: '50rem',cursor:"pointer", backgroundColor: isSelected ? '#0A97D9' : 'white',color: isSelected ? 'white' : 'text.secondary', }} 
            onClick={()=>handleConnectionClick(list?.gid)}>
        {list?.profile_image ? 
            <Avatar src={list?.profile_image} sx={{ width: 48, height: 48, borderColor: '#e9ecef' }} /> 
            : ( <Avatar sx={{ width: 48, height: 48, bgcolor: list?.color_code, borderColor: '#e9ecef', fontWeight: 500, fontSize: '1.25rem' }}>
                {list?.initials}
              </Avatar>)
        }
        <Box sx={{ ml: 2, overflow: 'hidden', flexGrow: 1 }}>
            <Typography fontSize='0.875em' fontWeight={600} noWrap>{list?.name}</Typography>
            <Typography overflowWrap={'break-word'} wordBreak={'break-word'} fontSize='0.8em'  sx={{ color: isSelected ? 'white' : 'text.secondary' }}  noWrap>{list?.last_message}</Typography>
        </Box>
        <Typography variant="caption" sx={{ color: isSelected ? 'white' : 'text.secondary' }} noWrap mr={1}>
            {list?.last_message_time && timeAgo(list?.last_message_time)}
        </Typography>
    </Card>
)};

const ChatScreen = () => {
    const [selectedContact, setSelectedContact] = useState(null);
    const [brandData, setBrandData] = useState(null);
    const [userStatus, setUserStatus] = useState('Available');
    const [activeChatChannel, setActiveChatChannel] = useState('');
    const dispatch = useDispatch();
    const { users: { userData }, socialMedia, master } = useSelector((state) => state);
    const handleSend = (message) => {
        dispatch(sendChatMessages(message, brandData?.gid))
    };

    const handleSelectContact = (contactName) => {
        setSelectedContact(contactName);
    };
    const handleChangeUserStatus = (newStatus) => {
        setUserStatus(newStatus);
    };
    useEffect(() => {
        if(socialMedia?.connectionList && socialMedia?.connectionList.length > 0 && !socialMedia?.chatInitialFlag){
            dispatch(getChatMessages(socialMedia?.connectionList[0]?.gid));
            setBrandData(socialMedia?.connectionList[0]);
            dispatch(chatMessagesInitialFlag(true));
        }
    }, [socialMedia?.connectionList])

      useEffect(()=>{
        // if(socialMedia?.activeChannel){
            const subscription = cable.subscriptions.create(
                { channel: "MessagesChannel", connection_gid: brandData?.gid, current_user_gid: userData?.gid }, // connection list channel
                {
                    received: (data) => {
                        const newMessages = [...socialMedia?.chatMessages, JSON.parse(data)]
                        dispatch(setChatMessages(newMessages));
                    },
                }
                );
                // subscription for connection list
            const subscriptionConnectionList = cable.subscriptions.create(
                { channel: "ConnectionsListChannel", current_user_gid: userData?.gid }, // connection list channel
                {
                    received: (data) => {
                        dispatch(setConnectionList(JSON.parse(data).connections));
                    },
                }
            );

            return () => {
                subscription.unsubscribe();
            };
        // }

      }, [])
    return (
        <Box className={SocialMediaCSS.chatScreen}>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <LeftChatSideBar
                        onSelectContact={handleSelectContact}
                        SocialMediaCSS={SocialMediaCSS}
                        onChangeStatus={handleChangeUserStatus}
                        userStatus={userStatus}
                        dispatch={dispatch}
                        socialMedia={socialMedia}
                        userData={userData}
                        connectionTypes={master?.connectionTypes}
                        setBrandData={setBrandData}
                        brandData={brandData}
                        master={master}
                        setActiveChatChannel={setActiveChatChannel}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box className={SocialMediaCSS.chatMain}>
                        <ChatHeader brandData={brandData} userStatus={userStatus} socialMedia={socialMedia} />
                        <Divider />
                        <MessageList 
                            messages={socialMedia?.chatMessages}
                            socialMedia={socialMedia}
                            userData={userData} 
                            brandData={brandData}
                        />
                        <Divider />
                        <MessageInput onSend={handleSend} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChatScreen;