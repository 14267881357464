import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { useDispatch } from "react-redux";
import MainHeader from '../../../../common/components/MainHeader';

import RightRFPSection from "./RightRFPSection";
import LeftRFPSection from "./LeftRFPSection";
import { getMasterData } from "../../actions";
import CustomSnackbar from "../../../../common/components/CommonSnackbar";
import { useSelector } from "react-redux";

const RfpsPreview = () => {
    const dispatch = useDispatch();
    const snackbarProps = useSelector((state) => state.message);

    // useEffect(() => {
    //     dispatch(getMasterData());
    // }, [])
    return (
        <>
            <Box className={SocialMediaCSS.MainHeader}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={SocialMediaCSS.mainContent}>
                    <Container maxWidth={'xl'}>
                        <Grid container spacing={3} className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_center}`}>
                            <Grid item xs={12} md={8} lg={8}>
                                <LeftRFPSection />
                            </Grid>
                            {/* <Grid item xs={12} md={4} lg={4}>
                                <RightRFPSection />
                            </Grid> */}
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </>
    )
}

export default RfpsPreview;