import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../assets/icons/Cancel.svg"
import DownArrow from "../../../../assets/icons/DownArrow.svg";
import ReduxForm from "../../../../common/components/ReduxForm";
import CommonInput from "../../../../common/components/CommonInput";
import tag from "../../../../assets/icons/tag.svg";
import Post from "../../../../assets/icons/Post_send.svg"
import media from "../../../../assets/icons/media.svg";
import activity from "../../../../assets/icons/activity.svg";
import CommonButton2 from "../../../../common/components/CommonButton2";
import { useDispatch } from "react-redux";
import Grateful from "../../../../assets/icons/Grateful.svg";
import Attend from "../../../../assets/icons/Attending.svg";
import support from "../../../../assets/icons/support.svg";
import Celeberating from "../../../../assets/icons/celebrating.svg";
import Participate from "../../../../assets/icons/Participate.svg";
import global from "../../../../assets/icons/global.svg";
import ceo from "../../../../assets/icons/ceo 1.svg";
import ngo from "../../../../assets/icons/non-profit-organization 2.svg";
import vlog from "../../../../assets/icons/vlog 2.svg";
import employee from "../../../../assets/icons/employee 1.svg";
import csr from "../../../../assets/icons/CSR consulting.svg";
import { setCreatePost, setPostAudience, setShowActivity, setShowPostThematic, setTagPost, setUploadedFiles, addUploadedFile, removeUploadedFile, setSelectedActivity, setSelectedPostAudience, setDraftPost, resetDraftAndActivity, resetPostTags, setSelectedThematic } from "../../../../store/slices/SocialMediaSlice";
import DiscardPost from "../Popup/DiscardPost";
import { useDropzone } from 'react-dropzone';
import deleteIcon from "../../../../assets/icons/DeleteWhite.svg"
import { setSnackbar } from "../../../../store/slices/MessageSlice";
import { reset } from "redux-form";
import { useSelector } from "react-redux";
import { createPost, deletePost, deletePostImages, getDraftPost } from "../../actions";
import CommonInitials from "../../../../common/components/CommonInitials";
import { capitalizeFirstLetter, parseEmptySpaces } from "../../../../common/utils";

const CreatePost = ({ socialMedia, usersImg, form, selectedActivity,unSelectedTag, selectedTag, selectedPostTitle, master, setSelectedActivity1 }) => {
    const dispatch = useDispatch();
    const draft = socialMedia?.draftPost
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);
    // const [uploadedFiles, setUploadedFiles] = useState([]);
    const [textExceeded, setTextExceeded] = useState(false);
    const uploadedFiles = useSelector((state) => state.socialMedia.uploadedFiles);
    const isArticle = socialMedia.articleCreation
    const ActivityPost = socialMedia.selectedActivity
    const postDescription = form?.CreatePostform?.values?.post_description?.trim();
    const isNextButtonEnabled = !form?.CreatePostform?.values?.post_description && (uploadedFiles.length === 0 || draft?.post_images?.length === 0 || draft?.post_video?.length === 0);
    //console.log("hey", selectedPostTitle)
    // const handleProfileDialogOpen = () => {
    //     // setPostDiscardOpen(true);
    //     // dispatch(setSelectedActivity(selectedActivity));
    //     // if (!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description || selectedActivity === "" || selectedTag === "") {
    //     if (selectedPostTitle && selectedPostTitle !== "Public") {
    //         setPostDiscardOpen(true);
    //         dispatch(setSelectedActivity(selectedActivity));
    //         dispatch(setSelectedPostAudience(selectedPostTitle));
    //     }
    //     if (isNextButtonEnabled) {
    //         handleCreateCancel();
    //     }
    //     else {
    //         setPostDiscardOpen(true);
    //         dispatch(setSelectedActivity(selectedActivity));
    //         dispatch(setSelectedPostAudience(selectedPostTitle));
    //     }
    // };

    const handleExceedLimit = (exceeded) => {
        if (exceeded) {
            setTextExceeded(true)
            // console.log('Character limit exceeded!');
        } else {
            setTextExceeded(false)
            // console.log('Character within limit.');
        }
    };
    //console.log("textExceed",textExceeded)
    const handleProfileDialogOpen = () => {
        if ((selectedPostTitle && selectedPostTitle !== "Public" || selectedActivity) || (draft?.post_images?.length === 1 || draft?.post_video?.length === 1)) {
            setPostDiscardOpen(true);
            dispatch(setSelectedActivity(selectedActivity));
            dispatch(setSelectedPostAudience(selectedPostTitle));
        } else if (isNextButtonEnabled) {
            handleCreateCancel();
        } else {
            setPostDiscardOpen(true);
            dispatch(setSelectedActivity(selectedActivity));
            dispatch(setSelectedPostAudience(selectedPostTitle));
        }
    };

    const handleProfileDialogClose = () => {
        setPostDiscardOpen(false);
    };
    const handleCreateCancel = () => {
        dispatch(setCreatePost(false))
        dispatch(setSelectedPostAudience("Public"))
        dispatch(resetPostTags())
        dispatch(reset('CreatePostform'));
        dispatch(setUploadedFiles([]));
        setSelectedActivity('')
        //setSelectedActivity1(capitalizeFirstLetter(draft?.activity?.key))
        dispatch(setSelectedActivity(selectedActivity));
        dispatch(setSelectedPostAudience(selectedPostTitle));
        if (draft.gid) {
            dispatch(deletePost(draft.gid));
        }
        dispatch(resetDraftAndActivity());

    };
    const handleActivityClick = () => {
        dispatch(setShowActivity(true))
    };
    const handleTagClick = () => {
        dispatch(setTagPost(true))
    };
    const handlePostAudience = () => {
        dispatch(setPostAudience(true))
    }
    const handleNextClick = () => {
        dispatch(setSelectedActivity(selectedActivity));
        dispatch(setSelectedPostAudience(selectedPostTitle));
        dispatch(setShowPostThematic(true))
    }
    // const handleConformPost = () => {
    //     dispatch(createPost());
    // }
    const handleDrop = (acceptedFiles) => {
        const isVideo = acceptedFiles.some(file => file.type.startsWith('video'));
        const isImage = acceptedFiles.some(file => file.type.startsWith('image'));
        const totalUploadedFiles = draft?.post_images?.length + uploadedFiles.length;

        if (totalUploadedFiles + acceptedFiles.length > 5) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload a maximum of 5 images', type: 'error' }));
            return;
        }
        if (draft?.post_images?.length > 0 && isVideo) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload a video when images are already uploaded.', type: 'error' }));
            return;
        }
        if (draft?.post_video?.length > 0 && isImage) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload an image when videos are already uploaded.', type: 'error' }));
            return;
        }

        if (isVideo && isImage) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload either images or a video, not both.', type: 'error' }));
            return;
        }

        if (isVideo && uploadedFiles.some(file => file.type.startsWith('image'))) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload a video because images have already been uploaded.', type: 'error' }));
            return;
        }

        if (isImage && uploadedFiles.some(file => file.type.startsWith('video'))) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload images because a video has already been uploaded.', type: 'error' }));
            return;
        }

        if (isVideo && acceptedFiles.length > 1) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload only one video.', type: 'error' }));
            return;
        }

        if (isImage && (acceptedFiles.length + uploadedFiles.length > 5)) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload a maximum of 5 images.', type: 'error' }));
            return;
        }
        for (let file of acceptedFiles) {
            if (file.type.startsWith('image/') && file.size > 2 * 1024 * 1024) { // 2 MB in bytes
                dispatch(setSnackbar({ flag: true, message: 'File size cannot exceed 2 MB.', type: 'error' }));
                return;
            }
            if (file.type.startsWith('video') && file.size > 25 * 1024 * 1024) { // 25 MB in bytes
                dispatch(setSnackbar({ flag: true, message: 'Video file size cannot exceed 25 MB.', type: 'error' }));
                return;
            }
        }
        // setUploadedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
        // console.log("Uploaded files:", [...uploadedFiles, ...acceptedFiles]);
        acceptedFiles.forEach(file => {
            dispatch(addUploadedFile(file));
        });
    };

    const handleRemoveFile = (index) => {
        dispatch(removeUploadedFile(index));
        // setUploadedFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
    };
    const handleUploadedRemoveFile = (postId, gid) => {
        // console.log(`Removing image with GID: ${gid} from post with GID: ${postId}`);
        dispatch(deletePostImages(postId, gid));
    };
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: handleDrop,
        accept: { 'image/*': ['.jpeg', '.jpg', '.png'], 'video/*': ['.mp4'] },
        noClick: true,
        noKeyboard: true
    });

    const activityImages = { "Supporting": support, "Attending": Attend, "Grateful": Grateful, "Celebrating": Celeberating, "Participating": Participate };
    const PostImages = { "Public": global, "Govt. Agency": employee, "Non Profit | NGO": ngo, "Corporate/Company": ceo, "CSR | ESG Expert": csr, "Employees | Volunteer | Individual": vlog, };
    //console.log("reality",draft?.post_thematic_area_ids)
    useEffect(() => {
        if (draft?.post_thematic_area_ids) {
            dispatch(setSelectedThematic(draft?.post_thematic_area_ids))
        }
    }, []);
    // const selectedImage = activityImages[ActivityPost];
    const selectedImage = activityImages[selectedActivity];
    // const selectedPostImage = PostImages[selectedPostTitle];
    const selectedAudienceObject = master.postTypes.find(audience => audience.value === selectedPostTitle);
    const selectedAudienceImage = selectedAudienceObject ? selectedAudienceObject.image : null;
    const tags = master.hashtags.hashtags;
    const users = master.mentions;

    // const users = master.mentions;
    // console.log("red",tags)
    const isVideoUploaded = uploadedFiles.some((file) => file.type.startsWith('video'));
    const initialValues = {
        post_description: draft?.body?.replace(/^"|"$/g, ''),
    };
    const uploadedPostImages = draft?.post_images?.length
    const uploadedPostVideo = draft?.post_video?.length
    const isuploadCount = uploadedPostImages + uploadedFiles.length;
    //const isNextButtonEnabled = (!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description)|| (uploadedFiles.length > 0 || draft?.post_images?.length > 0 || draft?.post_video?.length > 0);

    //console.log("ff",uploadedFiles.length)
    return (
        <>
            <Box className={SocialMediaCSS.postCard}>
                <Box className={SocialMediaCSS.postHeader}>
                    <Box className={SocialMediaCSS.postHeaderLogo}>
                        <CommonInitials
                            name={usersImg.userData.initials}
                            bgColor={usersImg.userData.color_code}
                            divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                            typoStyle={{ fontSize: '14px', lineHeight: '46px', }}
                            avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                            src={usersImg?.userData?.profile_pic}
                            companyLogo={usersImg?.userData?.profile_pic}
                            avatar={true}
                        />
                    </Box>
                    <Box className={SocialMediaCSS.postHeaderInfo}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                            <Typography className={SocialMediaCSS.organisaion}>
                                {usersImg?.userData?.name}
                            </Typography>&nbsp;
                            {selectedActivity &&
                                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} sx={{ gap: '5px' }}>
                                    <Typography className={SocialMediaCSS.organisaion}> -</Typography>
                                    <img src={selectedImage} className={SocialMediaCSS.createImgActivity} />
                                    <Typography className={SocialMediaCSS.organisaion}>{selectedActivity}</Typography>
                                </Box>}
                        </Box>
                        <Box className={`${SocialMediaCSS.createBox}`} onClick={handlePostAudience}>
                            {selectedPostTitle ? (
                                <img src={selectedAudienceImage} className={SocialMediaCSS.headAudi} />
                            ) : (
                                <img src={global} className={SocialMediaCSS.headAudi} />
                            )}
                            <Typography variant='body4' className={SocialMediaCSS.postHeaderTagline}>
                                {/* {socialMedia.selectedPostAudience} */}
                                {selectedPostTitle}
                            </Typography>
                            <img src={DownArrow} />
                        </Box>
                    </Box>
                    <Box className={SocialMediaCSS.cancelIcon}
                        onClick={() => handleProfileDialogOpen()}
                    >
                        <img src={cancel} className={SocialMediaCSS.createPost} />
                    </Box>
                </Box>
                <ReduxForm
                    form="CreatePostform"
                    label="CreatePost"
                    initialValues={initialValues}
                    submitButton="Next">
                    <Grid container>
                        <Grid item xs={12} mt={-1.2}>
                            <CommonInput
                                name="post_description"
                                placeholder="Write your post"
                                type="socialInput"
                                users={users}
                                tags={tags}
                                maxLength={"5000"}
                                showCount={true}
                                countFontSize="12px"
                                rows="6"
                                selectedTags={selectedTag}
                                unSelectedTag={unSelectedTag}
                                onExceedLimit={handleExceedLimit}
                                normalize={!draft?.draft_post ? parseEmptySpaces : undefined}
                            />
                        </Grid>
                        {draft?.draft_post === true &&
                            <Box className={`${SocialMediaCSS.filePreview} ${SocialMediaCSS.f}`} gap={1}>
                                {draft?.post_images && (
                                    <div gap={1}>
                                        {draft?.post_images.map((image, index) => (
                                            <Box key={image.gid} className={SocialMediaCSS.filePreview} mr={1.2}>
                                                <img src={image.images_url} alt={`image-${image.gid}`} className={SocialMediaCSS.imagePreview} />
                                                <IconButton onClick={() => handleUploadedRemoveFile(image.gid, draft?.gid)} className={SocialMediaCSS.removeButton}>
                                                    <img src={deleteIcon} alt="Delete" />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </div>
                                )}
                                {draft?.post_video && (
                                    <>
                                        {draft?.post_video?.map((video, index) => (
                                            <Box key={index}>
                                                <video controls className={SocialMediaCSS.previewVideo}>
                                                    <source src={video?.video_url} />
                                                </video>
                                                <IconButton onClick={() => handleUploadedRemoveFile(video.gid, draft.gid)} className={SocialMediaCSS.removeButton}>
                                                    <img src={deleteIcon} />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </>
                                )}
                            </Box>}
                        <Box className={SocialMediaCSS.previewBox}>
                            {uploadedFiles.map((file, index) => (
                                <Box key={index} className={SocialMediaCSS.filePreview}>
                                    {file.type.startsWith('image') && (
                                        <>
                                            <img src={URL.createObjectURL(file)} alt={`img-${index}`} className={SocialMediaCSS.imagePreview} />
                                            <IconButton onClick={() => handleRemoveFile(index)} className={SocialMediaCSS.removeButton}>
                                                <img src={deleteIcon} />
                                            </IconButton>
                                        </>
                                    )}
                                    {file.type.startsWith('video') && (
                                        <>
                                            <video controls className={SocialMediaCSS.previewVideo}>
                                                <source src={URL.createObjectURL(file)} type={file.type} />
                                            </video>
                                            <IconButton onClick={() => handleRemoveFile(index)} className={SocialMediaCSS.removeButton}>
                                                <img src={deleteIcon} />
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                            ))}
                        </Box>
                        <Grid item xs={12} className={SocialMediaCSS.createGrid}>
                            <Box className={SocialMediaCSS.createOption}>
                                {/* <img src={media} className={uploadedFiles.length >= 5 || isVideoUploaded ? SocialMediaCSS.unclickable : SocialMediaCSS.Imagecursor} onClick={open} /> */}
                                <Tooltip title="Image/Video" arrow><img src={media} className={isuploadCount >= 5 || isVideoUploaded || uploadedPostVideo > 0 ? SocialMediaCSS.unclickable : SocialMediaCSS.Imagecursor} onClick={open} /></Tooltip>
                                <Tooltip title="Tag" arrow><img src={tag} className={SocialMediaCSS.cursor} onClick={handleTagClick} /></Tooltip>
                                <Tooltip title="Activity" arrow><img src={activity} className={SocialMediaCSS.cursor} onClick={handleActivityClick} /></Tooltip> 
                            </Box>
                            <Box className={SocialMediaCSS.nextPost}>
                                {/* {isArticle !== true && */}
                                <CommonButton2
                                    label="Next"
                                    name="Next"
                                    variant="outlined"
                                    type="button"
                                    onClick={() => handleNextClick()}
                                    style={{ padding: '6px 32px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none' }}
                                    // disabled={!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description }
                                    disabled={textExceeded || isNextButtonEnabled}
                                />
                                {/* {isArticle === true &&
                                    < CommonButton2
                                        label="| Post"
                                        name="Post"
                                        variant="contained"
                                        startIcon={<img src={Post} />}
                                        type="button"
                                        onClick={handleConformPost}
                                        disabled={!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description}
                                        style={{
                                            padding: '6px 24px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none',
                                            background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%', color: '#FFF',
                                            opacity: !form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description ? 0.5 : 1
                                        }}
                                    />
                                } */}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                    </Box>
                </ReduxForm>
            </Box>
            {postDiscardOpen &&
                <DiscardPost
                    open={postDiscardOpen}
                    onClose={handleProfileDialogClose}
                    ButtonText1="Save as Draft"
                    ButtonText2="Discard"
                    title="Save as Draft"
                    Content1="Do you want to save this post as Draft?"
                    Content2="The content of this post will be here whenever you come back."
                    handleDiscard={handleCreateCancel}
                    textExceeded={textExceeded}
                />}
        </>
    )
}

export default CreatePost
