import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import MainHeader from '../../../../common/components/MainHeader';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import EventMainHeader from './EventMainHeader';
import EventBody from './EventBody';
import { useDispatch } from 'react-redux';
import { getEventsList } from './actions';
import { getMasterData } from '../../actions';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { PROFILE_TYPE } from '../../../../common/constants';
import CustomSnackbar from '../../../../common/components/CommonSnackbar';

const Events = () => {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbarProps = useSelector((state) => state.message);
    const { users: { userData } } = useSelector(state => state);
    const isIndividual = userData?.profile_type?.toLowerCase() !== PROFILE_TYPE.COMPANY ? true : false;
    let offset = useRef(10);
    const handleTabChange = (event, newValue) => {
        if (newValue === 2 && !isIndividual) {
            navigate('/events/listing');
        }
        setTabValue(newValue);
    };
    useEffect(() => {
        dispatch(getMasterData());
        dispatch(getEventsList(offset.current));
    }, [])

    return (
        <>
            <Box className={SocialMediaCSS.MainHeader}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={SocialMediaCSS.mainContent} sx={{ overflow: 'visible' }}>
                    <Container maxWidth={'xl'}>
                        <Box className={SocialMediaCSS.eventMainContainer}>
                            <EventMainHeader value={tabValue} handleChange={handleTabChange} offset={offset} />
                            <EventBody value={tabValue} offset={offset} />
                        </Box>
                    </Container>
                </Grid>

            </Grid>
        </>
    );
};

export default Events
