import { Box, Divider, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { styled } from '@mui/styles';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { useDispatch } from 'react-redux';
import CommonInitials from '../../../../common/components/CommonInitials';
import { useNavigate } from 'react-router';

const SharedPostHeader = ({ post, users, master, socialMedia }) => {
    const navigate = useNavigate();
    const handleUserProfile = (gid) => {
        if(users?.isLoggedIn){
            navigate(`/profile/${post?.shared_module?.user_gid}`);
        }else{
            navigate('/login');
        }
    }
    return (
        <>
            <Box className={SocialMediaCSS.postHeader}>
                <Box className={SocialMediaCSS.cursor} onClick={handleUserProfile}>
                    <CommonInitials
                        name={post?.shared_module?.initials}
                        bgColor={post?.shared_module?.colour_code}
                        divStyle={{ padding: '0px', maxHeight: '130px', maxWidth: '130px', minHeight: '34px', minWidth: '34px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                        typoStyle={{ fontSize: '14px', lineHeight: '32px', }}
                        avatarStyle={{ width: '34px', height: '34px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                        src={post?.shared_module?.image}
                        companyLogo={post?.shared_module?.image}
                        avatar={true}
                    />
                </Box>
                <Box className={SocialMediaCSS.postHeaderInfo}>
                    <Typography className={`${SocialMediaCSS.postReshareHeader} ${SocialMediaCSS.cursor}`} onClick={handleUserProfile} >
                        {post?.shared_module?.name}
                    </Typography>
                    <Typography className={SocialMediaCSS.postReshareTagline} >
                        {post?.shared_module?.tagline}
                    </Typography>
                </Box>

            </Box>
            <Divider />
        </>
    )
}

export default SharedPostHeader
