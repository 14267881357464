import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import CommonCss from '../Common.module.css';
import Closebutton from '../../assets/icons/Close button.svg';
import proposal from '../../assets/icons/businees_proposal_black.svg';
import rfp from '../../assets/icons/businees_rfp_black.svg';
import event from '../../assets/icons/businees_event_black.svg';
import tick from '../../assets/icons/tick_white.svg';
import theme from '../../assets/material-ui/theme';
import CommonButton2 from './CommonButton2';
import { useSelector, useDispatch } from 'react-redux';
import { PROFILE_FLOW_KEYS, PROFILE_TYPE } from '../constants';
import CommonBusineesDialog from './CommonBusineesDialog';
import CommonEnableTrialDialog from './CommonEnableTrialDialog';
import SelectOrganizationDialog from './SelectOrganizationDialog';
import CreateAccountDialog from './CreateAccountDialog';
import { reset } from 'redux-form';
import SignUpDialog from './SignUpDialog';
import { useNavigate, useLocation } from 'react-router';
import { setTempUserData } from '../../store/slices/UserSlice';
import { submitTrialActivated, submitUpgradeAccount } from '../actions';
import PublishPopup from "../../modules/Events/Common/PublishPopup";


const dummyData = [
    {
        id: 1,
        head: 'b-Volunteer',
        subHead: 'Volunteer activities dashboard, integrate employee efforts, issue digital certificates, and motivate with a dynamic leaderboard.'
    },
    {
        id: 2,
        head: 'b-Engage',
        subHead: 'Conference planning with dashboard for delegate, speaker, sponsor, awards & agenda coordination.'
    },
    {
        id: 3,
        head: 'Request For Proposal [RFP]',
        subHead: 'RFP management with a dashboard for effortless creation, publishing, filtering responses, and easy sharing.'
    },
    {
        id: 4,
        head: 'Proposal',
        subHead: 'Proposal management with dashboard, custom creation, personalized sharing, and dedicated communication.'
    },
]
const proposalData = [
    {
        id: 1,
        head: 'Impactful Dashboard',
        subHead: 'Holistic view & Management of proposals'
    },
    {
        id: 2,
        head: 'Custom Proposal Creation',
        subHead: 'Create tailored proposals or utilize a simple copy-paste feature.'
    },
    {
        id: 3,
        head: 'Personalized Proposal Sharing',
        subHead: 'Share your proposals directly with connections for a personal touch.'
    },
    {
        id: 4,
        head: 'Dedicated Communication',
        subHead: 'Engage in direct chats with proposal recipients for clear and efficient communication.'
    },
]
const rfpData = [
    {
        id: 1,
        head: 'Dashboard',
        subHead: 'Overall RFP Analytics'
    },
    {
        id: 2,
        head: 'Effortless RFP Creation & Publishing',
        subHead: 'Generate Requests for Proposals (RFP) effortlessly.'
    },
    {
        id: 3,
        head: 'Filtered RFP Responses',
        subHead: "Receive and review filtered responses for better decision-making."
    },
    {
        id: 4,
        head: 'RFP Sharing',
        subHead: 'You can share your rfp with your connections.'
    },
]
const bEngageData = [
    {
        id: 1,
        head: 'Conference Dashboard',
        subHead: 'Create, Manage & Reporting Conferences/Events/Workshops/Summits.'
    },
    {
        id: 2,
        head: 'Speaker Registration & Management',
        subHead: 'Easily register and manage speakers for your conference.'
    },
    {
        id: 3,
        head: 'Sponsor Management',
        subHead: 'Attract, register, and manage sponsors seamlessly.'
    },
    {
        id: 4,
        head: 'Awards & Agenda Management',
        subHead: 'Handle awards nominations and comprehensive agenda.'
    },
]
const eventData = [
    {
        id: 1,
        head: 'Volunteering Dashboard',
        subHead: 'Admin and Volunteer dashboard for centralized Analytics.'
    },
    {
        id: 2,
        head: 'Public & Private Employee Volunteering Activities',
        subHead: 'Host public events or keep things private, maintaining control over your privacy.'
    },
    {
        id: 3,
        head: 'Digital Certificates',
        subHead: 'Distribute certificates automatically upon event completion.'
    },
    {
        id: 4,
        head: 'Volunteer Leaderboard',
        subHead: 'Track and recognize top contributors to social impact.'
    },
]

const iconMapping = {
    'proposal': proposal,
    'rfp': rfp,
    'events': event,
    'bengage':proposal
};

const BusinessSuite = ({ handleDrawerClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedCard, setSelectedCard] = useState(null);
    const [aboutData, setAboutData] = useState(dummyData);
    const [enableTrial, setEnableTrial] = useState(false);
    const [trialActivated, setTrialActivated] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [trailType, setTrailType] = useState(null);
    const [selectedUserType, setSelectedUserType] = useState(null);
    const [upgradeDialog, setUpgradeDialog] = useState(false);
    const [createDialog, setCreateDialog] = useState(false);
    const [signUpOpen, setSignUpOpen] = useState(false);
    const [subUserpopup, setSubUserPopUp] = useState(false);
    const { users: { userData, companyTypes, businessSuite }, master: { moduleType } } = useSelector((state) => { return state; });
    const isIndividual = userData?.profile_type?.toLowerCase() !== PROFILE_TYPE.COMPANY ? true : false;

    const handleSelection = (value) => {
        setSelectedCard(value);
        if (value.key === 'proposal') {
            setAboutData(proposalData);
        } else if (value.key === 'rfp') {
            setAboutData(rfpData);
        } else if (value.key === 'bengage') {
            setAboutData(bEngageData);
        } else {
            setAboutData(eventData);
        }
    }   
    const handleEnableTrial = () => {
        if(userData?.sub_user){ // incase of subuser just show the popup
            setSubUserPopUp(true);
            return;
        }
        if(!isIndividual){
            dispatch(submitTrialActivated(selectedCard?.id, userData?.gid, setTrialActivated))
            // setTrialActivated(true);
        }else{
            setEnableTrial(true);
        }
    }
    const handleTrialClose = () => { setTrialActivated(false); }
    const handleEnableTrialClose = () => { setEnableTrial(false); }
    const handleOrganizationDialog = (value) => {
        handleEnableTrialClose();
        setIsOpen(true);
        setTrailType(value);
    }
    const handleCompanyTypeClick = (value) => {
        setSelectedUserType(value);
        setIsOpen(false);
        if (trailType === 'upgrade') {
            setUpgradeDialog(true);
        } else {
            setCreateDialog(true);
        }
    }
    const handleCloseSubUserDialogue = () =>{
        setSubUserPopUp(false);
    }
    const handleCommonClose = () => {
        setEnableTrial(false);
        setTrialActivated(false);
        setIsOpen(false);
        setTrailType(null);
        setSelectedUserType(null);
        setUpgradeDialog(false);
        setCreateDialog(false);
        setSignUpOpen(false);
        dispatch(reset('signUP'));
        dispatch(reset('signUPMailSent'));
        dispatch(setTempUserData({}));
    }
    const handleUpgradeConfirm = () => {
        dispatch(submitUpgradeAccount(selectedUserType, userData?.gid, navigate, handleDrawerClose))
        setUpgradeDialog(false);
        setTrailType(null);
        setSelectedUserType(null);
    }

    const handleSignUpOpen = () => {
        setCreateDialog(false);
        setSignUpOpen(true);
    }
    const handleSignUpClose = () => {
        setSignUpOpen(false);
        setCreateDialog(true);
    }

    const checkForTrailActivation = (value) => {
        let status;
        if(userData?.sub_user){
            status = businessSuite && businessSuite.find((data) => data?.module_id === value && data?.status === "enable" && data?.trial && data?.sub_user_access);
        }else{
            status = businessSuite && businessSuite.find((data) => data?.module_id === value && data?.status === "enable" && data?.trial);
        }
        return status;
    }

    const handleGoToDashboard = () => { 
        if (selectedCard) {
            // const newPath = selectedCard.key === 'bengage' ? '/bEngage/' : `/${selectedCard.key}/dashboard`;
            
            let newPath;
            switch (selectedCard.key) {
                case 'events':
                    newPath = '/events';
                    break;
                case 'bengage':
                    newPath = '/bEngage/';
                    break;
                case 'rfp':
                    newPath =`/rfp/`;
                    break;
                default:
                    newPath = '/social-media'
                    break;
            }
            handleDrawerClose();

            if (location.pathname === newPath) {
                handleDrawerClose();
            } else {
                navigate(newPath);
            }
        }

        
    }
     console.log('selectedCard', selectedCard);
    return (
        <>
            <Box className={CommonCss.busineesRoot}>
                <Box className={CommonCss.busineesMain}>
                    <img src={Closebutton} alt='close' onClick={handleDrawerClose} edge="start" aria-label="close" className={CommonCss.busineesRootClose} />
                    <Box className={CommonCss.busineesHeader}>
                        <Typography className={CommonCss.busineesHeaderTypo}>Business suite modules</Typography>
                    </Box>
                </Box>
                <Box className={CommonCss.busineesBox}>
                    <Box className={CommonCss.busineesBox1}>
                        {/* <Box className={CommonCss.busineesCard} sx={{ backgroundColor: selectedCard === 'proposal' ? theme.mainPalette.primary.primary110 : theme.mainPalette.grey.white, border: selectedCard === 'proposal' ? `0.2px solid ${theme.palette.primary.main}` : 'none', boxShadow: selectedCard === 'proposal' ? theme.shadow.neoMorphismShadow2 : theme.shadow.softShadow2 }} onClick={() => handleSelection('proposal')}>
                            <Box className={CommonCss.busineesCardTickBox}>
                                <img src={tick} alt='tick' className={CommonCss.busineesCardTick} />
                            </Box>
                            <img src={proposal} alt='proposal' style={{ filter: selectedCard === 'proposal' ? 'invert(1)' : 'none' }} />
                            <Typography className={CommonCss.busineesCardTypo} sx={{ color: selectedCard === 'proposal' ? theme.palette.secondary.dark : theme.palette.success.dark }}>Proposal</Typography>
                        </Box> */}
                        {moduleType && moduleType.map((type) => {
                            const isActivated = checkForTrailActivation(type.id)?.trial;
                            return (
                                <Box 
                                    className={CommonCss.busineesCard} 
                                    sx={{ backgroundColor: selectedCard?.key === type?.key ? theme.mainPalette.primary.primary110 : theme.mainPalette.grey.white, border: selectedCard?.key === type?.key ? `0.2px solid ${theme.palette.primary.main}` : 'none', boxShadow: selectedCard?.key === type?.key ? theme.shadow.neoMorphismShadow2 : theme.shadow.softShadow2 }} 
                                    onClick={() => handleSelection(type)} 
                                    key={type.id}
                                >
                                    {isActivated && <Box className={CommonCss.busineesCardTickBox}>
                                        <img src={tick} alt='tick' className={CommonCss.busineesCardTick} />
                                    </Box>}
                                    <img src={iconMapping[type.key]} alt='icon' style={{ filter: selectedCard?.key === type?.key ? 'invert(1)' : 'none' }} />
                                    <Typography 
                                        className={CommonCss.busineesCardTypo} 
                                        sx={{ color: selectedCard?.key === type?.key ? theme.palette.secondary.dark : theme.palette.success.dark }}>
                                            {type?.value}
                                    </Typography>
                                </Box>
                            )
                        })}
                        {/* <Box className={CommonCss.busineesCard} sx={{ backgroundColor: selectedCard === 'Event' ? theme.mainPalette.primary.primary110 : theme.mainPalette.grey.white, border: selectedCard === 'Event' ? `0.2px solid ${theme.palette.primary.main}` : 'none', boxShadow: selectedCard === 'Event' ? theme.shadow.neoMorphismShadow2 : theme.shadow.softShadow2 }} onClick={() => handleSelection('Event')}>
                            <img src={event} alt='event' style={{ filter: selectedCard === 'Event' ? 'invert(1)' : 'none' }} />
                            <Typography className={CommonCss.busineesCardTypo} sx={{ color: selectedCard === 'Event' ? theme.palette.secondary.dark : theme.palette.success.dark }}>Event</Typography>
                        </Box> */}
                    </Box>
                    <Box className={CommonCss.busineesBox2}>
                        <Typography className={CommonCss.busineesInfoHeader}>
                            About {`${selectedCard ? selectedCard.value : 'Business Suite'}`}
                        </Typography>
                        {aboutData.map((data) => (
                            <Box className={CommonCss.busineesInfoBox}>
                                <Typography className={CommonCss.busineesInfohead}>
                                    <span className={CommonCss.busineesInfoDot}></span> {data.head}
                                </Typography>
                                <span className={CommonCss.busineesInfoSub}>{data.subHead}</span>
                            </Box>
                        ))}
                    </Box>
                </Box>
                {selectedCard && checkForTrailActivation(selectedCard.id)?.trial ? <Box className={CommonCss.busineesBtnBox}>
                    <CommonButton2
                        label="Go to dashboard"
                        name="save"
                        type="button"
                        color="primary"
                        variant='contained'
                        style={{ padding: '6px 24px', fontSize: '14px', backgroundColor: theme.palette.primary.dark, borderRadius: '27px', lineHeight: '20px' }}
                        onClick={() => handleGoToDashboard()}
                    />
                </Box> : selectedCard && <Box className={CommonCss.busineesBtnBox}>
                    <CommonButton2
                        label="Enable trial"
                        name="save"
                        type="button"
                        color="primary"
                        variant='contained'
                        style={{ padding: '6px 24px', fontSize: '14px', backgroundColor: theme.palette.primary.dark, borderRadius: '27px', lineHeight: '20px' }}
                        onClick={() => handleEnableTrial()}
                    />
                </Box>}
            </Box>
            <CommonBusineesDialog
                open={trialActivated}
                handleConfirm={handleTrialClose}
                handleClose={handleCommonClose}
                title='Trial Activation'
                titleStyle={{ fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '20px', }}
                tickIcon={true}
                CommonCss={CommonCss}
                btnText={'OK'}
                btnStyle={{ padding: '6px 32px', borderRadius: '27px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: '14px', lineHeight: '20px' }}
            />
            <CommonEnableTrialDialog
                open={enableTrial}
                handleClose={handleCommonClose}
                handleConfirm={() => handleOrganizationDialog('upgrade')}
                handleConfirm2={() => handleOrganizationDialog('create')}
                title='Enable trial'
                message='Business services can only be used with business account, you can
                upgrade account or create new account'
                btnText1={'Upgrade Account'}
                btnStyle1={{ padding: '6px 16px', borderRadius: '5px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: '14px', lineHeight: '20px', boxShadow: theme.shadow.hardShadow }}
                btnStyle2={{ padding: '6px 16px', borderRadius: '5px', border: `1px solid ${theme.mainPalette.primary.primary110}`, fontSize: '14px', lineHeight: '20px', boxShadow: theme.shadow.hardShadow }}
                btnText2={'Create new account'}
                titleStyle={{ fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '20px', }}
                CommonCss={CommonCss}
            />
            <SelectOrganizationDialog
                open={isOpen}
                onClose={handleCommonClose}
                CommonCss={CommonCss}
                title={'What best describes your organization?'}
                handleCompanyTypeClick={handleCompanyTypeClick}
                companyTypes={companyTypes}
            />
            <CommonBusineesDialog
                open={upgradeDialog}
                handleConfirm={handleUpgradeConfirm}
                handleClose={handleCommonClose}
                title='Upgrade account'
                titleStyle={{ fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '20px', }}
                tickIcon={false}
                message={'Are you sure you want to upgrade account?'}
                CommonCss={CommonCss}
                closeIcon={true}
                btnText={'Confirm'}
                btnStyle={{ padding: '6px 32px', borderRadius: '27px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: '14px', lineHeight: '20px' }}
            />
            <CreateAccountDialog
                open={createDialog}
                onClose={handleCommonClose}
                CommonCss={CommonCss}
                dispatch={dispatch}
                handleSignUpOpen={handleSignUpOpen}
                selectedCompanyType={selectedUserType}
                selectedOption={'company'}
                naviagte={navigate}
            />
            <SignUpDialog
                open={signUpOpen}
                onClose={handleCommonClose}
                CommonCss={CommonCss}
                dispatch={dispatch}
                navigate={navigate}
                handleSignUpClose={handleSignUpClose}
                selectedOption={'company'}
            />

            <PublishPopup
                open={subUserpopup}
                onClose={handleCloseSubUserDialogue}
                Content="Please get in touch with your administrator to enable this feature."
                text1={""}
                ButtonText1="Close"
                title="Enable Trail"
                handleButton2={handleCloseSubUserDialogue}
            />
        </>
    )
}

export default BusinessSuite