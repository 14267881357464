import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import FsLightbox from 'fslightbox-react';
import ImageViewer from 'react-simple-image-viewer';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const ImageCarousel = ({ images, media, video }) => {
    // const [lightboxController, setLightboxController] = useState({
    //     toggler: false,
    //     slide: 1
    // });
    // function openLightboxOnSlide(number) {
    //     setLightboxController({
    //         toggler: !lightboxController.toggler,
    //         slide: number + 1
    //     });
    // }
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    };

    const closeImageViewer = () => setIsViewerOpen(false);
    const CustomCloseComponent = () => (
        <IconButton
            onClick={closeImageViewer}
            style={{ position: 'relative', top: 2, right: 10, color: '#fff',zIndex:1000}}
        >
            <CloseIcon fontSize='large'/>
        </IconButton>
    );
    // const CustomLeftArrow = () => (
    //     <div className={`image-viewer-arrow ${SocialMediaCSS['image-viewer-arrow']} ${SocialMediaCSS['image-viewer-arrow-left']}`}>
    //         <ArrowBackIosNewIcon style={{ color: '#fff' }} fontSize='large' />
    //     </div>
    // );

    // const CustomRightArrow = () => (
    //     <div className={`image-viewer-arrow ${SocialMediaCSS['image-viewer-arrow']} ${SocialMediaCSS['image-viewer-arrow-right']}`}>
    //         <ArrowForwardIosIcon style={{ color: '#fff' }} fontSize='large'/>
    //     </div>
    // );

    const imageUrls = images?.map(image => image.images_url);
    //console.log("fff", imageUrls)
    return (
        <>
            <Box className={SocialMediaCSS.carouselContainer}>
                {(images !== null) ? (
                    <Carousel
                        showThumbs={true}
                        showStatus={true}
                        autoPlay={false}
                        animation={'slide'}
                        swipe={false}
                        indicatorContainerProps={{ className: SocialMediaCSS.carouselIndicatorBox }}
                        indicatorIconButtonProps={{ className: SocialMediaCSS.carouselIndicator }}
                        activeIndicatorIconButtonProps={{ className: SocialMediaCSS.carouselIndicatorActive }}
                        navButtonsAlwaysInvisible={images?.length === 1}
                    >
                        {images?.map((image, index) => (
                            <Box key={index}>
                                <img src={image?.images_url} alt={`Slide ${index}`} className={SocialMediaCSS.slide}
                                    // onClick={() => openLightboxOnSlide(index)} 
                                    onClick={() => openImageViewer(index)}
                                />
                            </Box>
                        ))}
                    </Carousel>
                ) : (
                    <Box className={SocialMediaCSS.videoSlide}>
                        {video?.map((video, index) => (
                            <video
                                controls
                                className={SocialMediaCSS.videoSlide}
                                src={video?.video_url}
                            >
                            </video>))}
                    </Box>
                )}
            </Box>
            {/* <FsLightbox toggler={lightboxController.toggler}
                sources={imageUrls}
                slide={lightboxController.slide} /> */}
            {isViewerOpen && (
                <div  sx={{    overflow: 'hidden' }}>
                    <ImageViewer
                        src={imageUrls}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={true}
                        closeOnClickOutside={true}
                        backgroundStyle={{
                            //backgroundColor: 'rgba(0,0,0,0.9)',
                            zIndex: 9999,
                            marginTop:"40px",
                        }}
                       
                        closeComponent={<CustomCloseComponent />}
                    />
                </div>
            )}
        </>
    );
};

export default ImageCarousel;