import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import EventGridCard from './Subcomponents/EventGridCard'
import EventListCard from './Subcomponents/EventListCard';
import EventDetailsDrawer from './Subcomponents/EventDetailsDrawer';
import EventMyEventList from './Subcomponents/EventMyEventList';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getEventPreview } from './actions';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`view-tabpanel-${index}`}
            aria-labelledby={`view-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const EventBody = ({ value, offset }) => {
    const [eventDraweropen, setEventDraweropen] = useState(false);
    const [eventGID, setEventGid]  = useState(null) ;
    const { socialMedia: { eventList, eventIsLoad, totalEventCount, eventSortBy, eventCategory, eventStatus, myEventList, totalMyEventCount, eventData } } = useSelector((state => state));
    const dispatch = useDispatch();
    const handleEventDrawerOpen = (gid) => {
        dispatch(getEventPreview(gid));
        setEventDraweropen(true);
    };

    const handleEventDrawerClose = () => {
        setEventDraweropen(false);
    };
    return (

        <>
            <TabPanel value={value} index={0}>
                <Grid container spacing={3}>
                    <EventGridCard 
                        eventList={eventList} 
                        handleEventDrawerOpen={handleEventDrawerOpen} 
                        eventIsLoad={eventIsLoad} 
                        totalEventCount={totalEventCount} 
                        dispatch={dispatch} 
                        eventSortBy={eventSortBy} 
                        eventCategory={eventCategory} 
                        offset={offset}
                     />
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={3}>
                    <EventListCard 
                        eventList={eventList} 
                        handleEventDrawerOpen={handleEventDrawerOpen} 
                        eventIsLoad={eventIsLoad} 
                        totalEventCount={totalEventCount} 
                        dispatch={dispatch} 
                        eventSortBy={eventSortBy} 
                        eventCategory={eventCategory} 
                        offset={offset}
                    />
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={3}>
                    <EventMyEventList 
                        eventList={myEventList} 
                        handleEventDrawerOpen={handleEventDrawerOpen} 
                        eventIsLoad={eventIsLoad} 
                        totalEventCount={totalMyEventCount} 
                        dispatch={dispatch} 
                        eventStatus={eventStatus} 
                        offset={offset} 
                        setEventGid={setEventGid}
                    />
                </Grid>
            </TabPanel>
            <EventDetailsDrawer 
                open={eventDraweropen} 
                onClose={handleEventDrawerClose} 
                title="Open Event" 
                desc="" 
                gid={eventData?.gid} 
            />
        </>
    )
}

export default EventBody
