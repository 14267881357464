import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import MainHeader from '../../../../common/components/MainHeader';
import CustomSnackbar from '../../../../common/components/CommonSnackbar';
import LeftChatSideBar from './LeftChatSideBar';
import ChatScreen from './ChatScreen';
import RightChatSideBar from './RightChatSideBar';
import { useDispatch } from 'react-redux';
import { getConnectionTypes, getPostTypes } from '../../../../common/actions';
import { chatMessagesInitialFlag } from '../../../../store/slices/SocialMediaSlice';


const ChatsLayout = () => {
  const snackbarProps = useSelector((state) => state.message);
  const isMdScreen = useMediaQuery('(max-width: 1280px)');
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getPostTypes());
    dispatch(getConnectionTypes());
    dispatch(chatMessagesInitialFlag(false));
  }, [])
    return (
        <>
            <Box className={SocialMediaCSS.MainHeader}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={SocialMediaCSS.mainContent}>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={9}>
                                <ChatScreen />
                            </Grid>
                            {!isMdScreen && <Grid item xs={12} lg={3}>
                                <RightChatSideBar />
                            </Grid>}
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}

export default ChatsLayout