import { Box, Typography } from '@mui/material';
import React from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';

const EmptyPostCard = () => {

    return (
        <>
            <Box className={SocialMediaCSS.emptyPost}>
                <Typography className={SocialMediaCSS.emptyTypo}>
                    No Posts yet, all Posts will be shown here.
                </Typography>

            </Box>
        </>
    )
}
export default EmptyPostCard