import React, { useCallback, useEffect, useRef, useState } from 'react'
import CommonInitials from '../../../../common/components/CommonInitials'
import { Box, CircularProgress, Fade, Grid, Typography } from '@mui/material'
import CommonButton2 from '../../../../common/components/CommonButton2'
import theme from '../../../../assets/material-ui/theme'
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css'
import userImage2 from '../../../../assets/images/user_profile_image.jpeg';
import _ from 'lodash-es';
import { debounce, getCompanyTypeByKey } from '../../../../common/utils'
import { FollowUser, sendConnectionReq } from '../../../Profile/actions'
import { getAllSuggestions } from '../../actions'
import SocialMediaEventCSS from '../../SocialMediaCSS/SocialMediaEvent.module.css';

const SuggestionsMain = ({ dispatch, navigate, recommendationList, offset, totalRecommendationCount, containerRef }) => {
    const [followStatus, setFollowStatus] = useState({});
    const [loading, setLoading] = useState(false);
    let totalCount = useRef(0);
    let totalData = useRef(0);
    const isFetching = useRef(false);
    const lastScrollTop = useRef(0);

    useEffect(() => {
        if (recommendationList) {
            totalCount.current = recommendationList.length;
            totalData.current = totalRecommendationCount;
        }
    }, [recommendationList, totalRecommendationCount])

    const handleScroll = useCallback(() => {
        console.log('running', isFetching, totalCount.current, totalData.current);
        if (isFetching.current) return;
        const container = containerRef.current;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        if (scrollTop > lastScrollTop.current && scrollTop + clientHeight >= scrollHeight - 50 && totalCount.current < totalData.current) { // Adjusted threshold for triggering
            fetchMoreData();
        }

        lastScrollTop.current = scrollTop;
    }, []);

    const debouncedHandleScroll = debounce(handleScroll, 300);
    const fetchMoreData = () => {
        try {
            isFetching.current = true;
            offset.current += 10;
            dispatch(getAllSuggestions(offset.current, true, setLoading, isFetching));
        } catch (error) {
            isFetching.current = false;
        }
    };
    // Initialize follow status based on suggested users data
    useEffect(() => {
        const initialStatus = {};
        recommendationList && recommendationList.forEach(user => {
            initialStatus[user.gid] = user.followed;
        });
        setFollowStatus(initialStatus);
    }, [recommendationList]);

    const handleSendConnect = (value) => {
        dispatch(sendConnectionReq(value, 'suggest'));
    }
    const handleFollow = (gid) => {
        dispatch(FollowUser(gid, followStatus[gid]));
        setFollowStatus(prevState => ({
            ...prevState,
            [gid]: !prevState[gid]
        }));
    }

    useEffect(() => {
        dispatch(getAllSuggestions());
    }, [])

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', debouncedHandleScroll);
        return () => {
            container.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, []);

    const goToProfile = (gid) => {
        navigate(`/profile/${gid}`)
    }

    return (
        <Grid item xs={12}>
            <Box className={SocialMediaCSS.communityMainRoot}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className={SocialMediaCSS.communityMainTitleGrid}>
                            <Box className={SocialMediaCSS.communityTitleBox}>
                                <Typography className={SocialMediaCSS.communityTitleTypo}>Suggestions</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={SocialMediaCSS.communityMainCardGrid}>
                            <Grid container spacing={2}>
                                {!_.isEmpty(recommendationList) && recommendationList.map((data) => (
                                    <Grid item xs={12} sm={6} md={4} xl={3} key={data?.gid}>
                                        <Box className={SocialMediaCSS.communityMainCardBox}>
                                            <Box className={SocialMediaCSS.communityMainCardBox2}>
                                                <CommonInitials
                                                    name={data?.initials}
                                                    bgColor={data?.colour_code}
                                                    divStyle={{ padding: '3px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '10px', boxShadow: theme.shadow.softShadow2, flex: '0 0 70px', cursor: 'pointer', }}
                                                    typoStyle={{ fontSize: '26px', lineHeight: '60px', }}
                                                    avatarStyle={{ width: '70px', height: '70px', boxShadow: theme.shadow.softShadow2, cursor: 'pointer', borderRadius: '10px', }}
                                                    src={data?.profile_pic?.url}
                                                    companyLogo={data?.profile_pic?.url}
                                                    avatar={true}
                                                    onClick={() => goToProfile(data?.gid)}
                                                />
                                                <Box className={SocialMediaCSS.communityMainCardBox4}>
                                                    <Typography className={SocialMediaCSS.communityMainCardTypo}>{data?.name}</Typography>
                                                    <Typography className={SocialMediaCSS.communityMainCardTypo2}>{dispatch(getCompanyTypeByKey(data?.profile_type))}</Typography>
                                                    {data?.profile_tagline &&
                                                        <Typography className={SocialMediaCSS.communityMainCardTypo2}>{data?.profile_tagline}</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box className={SocialMediaCSS.communityMainCardBox3}>
                                                <CommonButton2
                                                    label="Connect"
                                                    name="Connect"
                                                    type="Connect"
                                                    variant="contained"
                                                    // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                    style={{ padding: '2px 16px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', width: '100px', borderRadius: '20px' }}
                                                    onClick={() => { handleSendConnect(data?.email) }}
                                                />
                                                <CommonButton2
                                                    label={followStatus[data.gid] ? "Unfollow" : "Follow"}
                                                    name={followStatus[data.gid] ? "Unfollow" : "Follow"}
                                                    type={followStatus[data.gid] ? "Unfollow" : "Follow"}
                                                    variant="outlined"
                                                    // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                    style={{ padding: '2px 16px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                                                    onClick={() => handleFollow(data?.gid)}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                        {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                            <Fade
                                in={loading}
                                style={{
                                    transitionDelay: loading ? '100ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <Box className={SocialMediaEventCSS.loadingBox}>
                                    <CircularProgress />
                                </Box>
                            </Fade>
                        </Box>}
                        {_.isEmpty(recommendationList) && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
                            <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>All Caught up!!</Typography>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>

        </Grid>
    )
}

export default SuggestionsMain