import { Box, Button, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import CategoryPopover from './Subcomponents/CategoryPopover';
import { useSelector } from 'react-redux';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import GridViewIcon from '../../../../assets/icons/gridView.svg';
import ListViewIcon from '../../../../assets/icons/listView.svg';
import DashboardViewIcon from '../../../../assets/icons/dashboardView.svg';
import SortByPopover from './Subcomponents/SortByPopover';
import { useDispatch } from 'react-redux';
import { setEventSortBy } from '../../../../store/slices/SocialMediaSlice';
import { getEventsList, getMyEventsList, getSortByList } from './actions';
import CompanyEventPopover from './Subcomponents/CompanyEventPopover';

const CustomTab = (props) => (
    props.tooltip ? <Tooltip title={props.title} placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }} arrow>
        <Tab
            {...props}
            classes={{
                root: SocialMediaCSS.customTab,
                selected: SocialMediaCSS.customTabSelected,
                wrapper: SocialMediaCSS.customTabWrapper,
            }}
            label={viewBtns(props.iconName)}
            disableRipple
        />
    </Tooltip> : <Tab
        {...props}
        classes={{
            root: SocialMediaCSS.customTab,
            selected: SocialMediaCSS.customTabSelected,
            wrapper: SocialMediaCSS.customTabWrapper,
        }}
        label={viewBtns(props.iconName)}
        disableRipple
    />
);

const viewBtns = (iconName) => {
    return (
        <Box className={SocialMediaCSS.iconContainer}>

            <img src={iconName} alt='Icon' className={SocialMediaCSS.h24} />
        </Box>
    )
};

const viewTabSelProp = (index) => {
    return {
        id: `view-tab-${index}`,
        'aria-controls': `view-tabpanel-${index}`,
    };
};

const EventMainHeader = ({ value, handleChange, offset }) => {
    const { master, socialMedia: { eventSortBy, eventCategory }, form } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [checkedItems, setCheckedItems] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [sortAnchorEl, setSortAnchorEl] = useState(null); // State for SortByPopover anchor element
    const [anchorPosition, setAnchorPosition] = useState(null);
    const [eventAnchorEl, setEventAnchorEl] = useState(null);
    const open = Boolean(anchorEl2);
    const sortOpen = Boolean(sortAnchorEl); // Boolean to handle SortByPopover open state
    const eventOpen = Boolean(eventAnchorEl); // Boolean to handle EventByPopover open state

    const handleClick = (event) => {
        // Calculate the position of the Category button
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();

        // Set the anchor position
        setAnchorPosition({
            top: rect.bottom,
            left: rect.left,
        });
        setAnchorEl2(event.currentTarget);
    };

    const handleClose = () => {
        if (Object.keys(checkedItems).length === 0 || // Check if checkedItems is empty
            Object.keys(checkedItems).every(key => !checkedItems[key]) // Check if all values are falsy
        ) {
            setAnchorEl2(null); // Close the anchor element
            return;
        } else {
            setAnchorEl2(null); // Close the anchor element
        }
    }

    const handleSortClick = (event) => {
        setSortAnchorEl(event.currentTarget); // Set the anchor element for SortByPopover
    };

    const handleSortClose = () => {
        setSortAnchorEl(null);
    };

    const handleSelectSortOption = (option) => {
        console.log('Selected sort option:', option);
        dispatch(setEventSortBy(option))
        dispatch(getSortByList(10, false, '', option));
        handleSortClose();
    };
    const handleEventClick = (event) => {
        setEventAnchorEl(event.currentTarget); // Set the anchor element for SortByPopover
    };

    const handleEventClose = () => {
        setEventAnchorEl(null);
    };

    const handleSelectEventOption = (option) => {
        console.log('Selected sort option:', option);
        dispatch(getMyEventsList(offset.current, false, '', option));
        handleEventClose();
    };
    return (
        <>
            <Box className={SocialMediaCSS.eventMainHeader}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        {value === 2 ? (
                            <Box className={SocialMediaCSS.eventFilterContainer}>
                                <Button
                                    id="basic-button"
                                    // aria-controls={open ? 'basic-menu' : undefined}
                                    // aria-haspopup="true"
                                    // aria-expanded={open ? 'true' : undefined}
                                    onClick={handleEventClick}
                                    classes={{
                                        root: SocialMediaCSS.dropdownBtn,
                                        focused: SocialMediaCSS.dropdownBtnFocused,
                                    }}
                                    endIcon={<KeyboardArrowDownIcon className={SocialMediaCSS.dropdownIcon} />}
                                    disableRipple
                                >
                                    Company Events
                                </Button>
                            </Box>
                        ) : (
                            <Box className={SocialMediaCSS.eventFilterContainer}>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    classes={{
                                        root: SocialMediaCSS.dropdownBtn,
                                        focused: SocialMediaCSS.dropdownBtnFocused,
                                    }}
                                    endIcon={<KeyboardArrowDownIcon className={SocialMediaCSS.dropdownIcon} />}
                                    disableRipple
                                >
                                    Category
                                </Button>
                                <Button
                                    id="basic-button"
                                    // aria-controls={open ? 'basic-menu' : undefined}
                                    // aria-haspopup="true"
                                    // aria-expanded={open ? 'true' : undefined}
                                    onClick={handleSortClick}
                                    classes={{
                                        root: SocialMediaCSS.dropdownBtn,
                                        focused: SocialMediaCSS.dropdownBtnFocused,
                                    }}
                                    endIcon={<KeyboardArrowDownIcon className={SocialMediaCSS.dropdownIcon} />}
                                    disableRipple
                                >
                                    Sort by
                                </Button>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={SocialMediaCSS.eventViewContainer}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="Event Listing View Tabs"
                                TabIndicatorProps={{ style: { display: 'none' } }}
                            >
                                <CustomTab iconName={GridViewIcon} {...viewTabSelProp(0)} tooltip={true} title={'Grid View'} />
                                <CustomTab iconName={ListViewIcon} {...viewTabSelProp(1)} tooltip={true} title={'List View'} />
                                <CustomTab iconName={DashboardViewIcon} {...viewTabSelProp(2)} />
                            </Tabs>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <CategoryPopover
                options={master?.thematicAreas}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                anchorPosition={anchorPosition}
                dispatch={dispatch}
                eventCategory={eventCategory}
                eventSortBy={eventSortBy}
            />
            <SortByPopover
                anchorEl={sortAnchorEl}
                open={sortOpen}
                onClose={handleSortClose}
                onSelectOption={handleSelectSortOption}
            />
            <CompanyEventPopover
                anchorEl={eventAnchorEl}
                open={eventOpen}
                onClose={handleEventClose}
                onSelectOption={handleSelectEventOption}
            />
        </>
    )
}

export default EventMainHeader
