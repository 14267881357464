import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CreateSection from "../CreateSection";
import CreatePost from "../CreatePost";
import { useSelector } from "react-redux";
import PostActivity from "../PostActivity";
import TagPost from "../TagPost";
import PostAudience from "../PostAudience";
import PostCategory from "../PostCategory";
import { capitalizeFirstLetter } from "../../../../../common/utils";
import { useLocation } from "react-router";


const PostCreateProcess = () => {
    const { socialMedia, form, master, users } = useSelector((state) => { return state; });
    const draft = socialMedia?.draftPost
    const location = useLocation();
    const postTypeKey = draft?.post_type_name;
    const postType = master?.postTypes?.find(type => type?.key === postTypeKey);
    const postTypeValue = postType?.value;
    const [selectedActivity, setSelectedActivity] = useState(capitalizeFirstLetter(draft?.activity?.key));
    const [selectedTag, setSelectedTag] = useState('');
    const [unSelectedTag, setUnSelectedTag] = useState('');
    const [selectedPostTitle, setSelectedPostTitle] = useState(postTypeValue ? postTypeValue : "Public");
    // const [selectedPostTitle, setSelectedPostTitle] = useState("Public");
    const createPost = socialMedia.createPost
    const Activity = socialMedia.showActivity
    const tagPost = socialMedia.tagPost
    const postAudience = socialMedia.postAudience
    const showPostThematic = socialMedia.showPostThematic

    const isNotificationPost = location.pathname.startsWith(`/social-media/posts`);
    const isBookMarkEvents = location.pathname.startsWith(`/social-media/bookmark/event`);
    const isBookMarkPost = location.pathname.startsWith(`/social-media/bookmark/post`);
    const isMYPost = location.pathname.startsWith(`/social-media/my-posts`);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (draft) {
                setSelectedActivity(capitalizeFirstLetter(draft?.activity?.key || ''));
                setSelectedPostTitle(postTypeValue ? postTypeValue : 'Public');
            }
            setSelectedTag("")
        }, 1000);
        return () => clearTimeout(timer);
    }, [draft, postTypeValue]);

    const handleActivitySelect = (title) => {
        console.log(`Selected activity: ${title}`);
        setSelectedActivity(title);
    };
    const handleTagSelect = (tag) => {
        console.log(`Selected tags are: ${tag}`);
        setSelectedTag(tag);
    };
    const handleTagUnselect = (tag) => {
        console.log(`Unselected tag: ${tag}`);
        setUnSelectedTag(tag);
    };
    const handlePostSelect = (post) => {
        console.log(`Selected Posts are: ${post}`);
        setSelectedPostTitle(post);
    }
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {createPost !== true &&
                <>
                    {!isNotificationPost && !isBookMarkPost && !isBookMarkEvents && !isMYPost &&
                        <Grid item xs={12}>
                            <CreateSection />
                        </Grid>}
                </>
            }
            {createPost === true && Activity === false && tagPost === false && postAudience === false && showPostThematic === false &&
                <Grid item xs={12}>
                    <CreatePost socialMedia={socialMedia} form={form} selectedActivity={selectedActivity} selectedTag={selectedTag} unSelectedTag={unSelectedTag} selectedPostTitle={selectedPostTitle} master={master} usersImg={users} />
                </Grid>}
            {Activity === true &&
                <Grid item xs={12}>
                    <PostActivity onActivitySelect={handleActivitySelect} selectedPostTitle={selectedPostTitle} socialMedia={socialMedia} master={master} />
                </Grid>}
            {tagPost === true &&
                <Grid item xs={12}>
                    <TagPost onTagSelect={handleTagSelect} onTagUnselect={handleTagUnselect} socialMedia={socialMedia} master={master} form={form} />
                </Grid>}
            {postAudience === true &&
                <Grid item xs={12}>
                    <PostAudience onPostSelect={handlePostSelect} setSelectedPostTitle={setSelectedPostTitle} selectedPostTitle={selectedPostTitle} socialMedia={socialMedia} master={master} form={form} />
                </Grid>}
            {showPostThematic === true &&
                <Grid item xs={12}>
                    <PostCategory socialMedia={socialMedia} master={master} setSelectedActivity={setSelectedActivity} />
                </Grid>}

        </>
    );
}
export default PostCreateProcess;
