import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Popover, Box, Typography, Avatar, Grid, useMediaQuery, Accordion, AccordionSummary, Badge, Tooltip, TextField, styled, MenuList, Paper, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import deleteRed from '../../assets/icons/deleteRed.svg';
import AddIcon from '@mui/icons-material/Add';
import theme from '../../assets/material-ui/theme';
import logout from '../../assets/icons/logout.svg';
import { FILE_HOST, UPLOAD_DATA } from '../../common/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUploadedDocURL } from '../../modules/Profile/actions';
import message from '../../assets/icons/messagess.svg'
import { getColorCodeById, getMessageCount, getNotificationCount } from "../actions"
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { logoutSingleAccount, removeSingleAccount, switchProfile } from '../../modules/Registration/actions';
import CommonButton2 from './CommonButton3';
import DialogLogin from '../../modules/Registration/components/DialogLogin';
import { reset } from 'redux-form';
import CommonInitials from './CommonInitials';
import Search from '../../assets/icons/Search.svg';
import notification from "../../assets/icons/notification-bing.svg";
import CommonSearchBar from '../../modules/Profile/NewProfile/Common/CommonSearchBar';
import HeaderSearch from './HeaderSearch';
import { searchUser } from '../../modules/SocialMedia/actions';
import { resetNotificationPostType, resetPostDashboard, resetSelectedSlug, setBookmarkType, setUserList } from '../../store/slices/SocialMediaSlice';
import CommonCSS from '../Common.module.css';
import _ from 'lodash-es';
import helpandSupport from '../../assets/icons/helpandsupport.svg';
import my_bookmark from "../../assets/icons/my_bookmark.svg";
import events from "../../assets/icons/Events.svg";
import share from "../../assets/icons/share_lightGrey.svg";
import calender from "../../assets/icons/calender.svg";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        fontSize: '10px',
        padding: '1px 2px 1px 2px',
        margin: '-1px',
    },
}));

const useStyles = makeStyles((theme) => ({
    popover: {
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid',
        borderTopColor: theme.palette.primary.contrastText,
        paddingTop: '0!important',
        paddingBottom: '0 !important',
        borderLeft: '1px'
    },
    pop: {
        color: theme.palette.primary.contrastText,
        // borderTop: '1px solid',
        // borderTopColor: theme.palette.primary.contrastText,
        paddingTop: '0!important',
        paddingBottom: '0 !important',
    },
    headerAvtarStyle: {
        width: '45px',
        height: '45px',
        boxShadow: "2px 0 4px rgba(0, 0, 0, 0.5)"
    },
    headerImageDivStyle: {
        padding: '5px',
        maxHeight: '135px',
        maxWidth: '132px',
        minHeight: '35px',
        minWidth: '35px',
        borderRadius: '50%',
        boxShadow: "2px 0 4px rgba(0, 0, 0, 0.5)",
        cursor: 'pointer'
    }
}));
export default function RightHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [expanded, setExpanded] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [bookmarkAnchorEl, setBookmarkAnchorEl] = useState(null);
    let showMultiuser = true;
    const handleBookmarkClick = (event) => {
        setBookmarkAnchorEl(event.currentTarget);
    };

    const handleBookmarkClose = () => {
        setBookmarkAnchorEl(null);
    };
    const handleOpenDialog = () => {
        setOpenDialog(true);
        dispatch(reset('loginForm'));
        handleClose();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const bookmarkOpen = Boolean(bookmarkAnchorEl);
    const popoverId = open ? 'simple-popover' : undefined;
    const bookmarkPopoverId = bookmarkOpen ? 'bookmark-popover' : undefined;
    const isMobile = useMediaQuery('(max-width: 960px)');
    const { users: { userData, notifications, userList, companyTypes }, profile: { aboutUs
        : { name, profile_pic } }, socialMedia: { users_list } } = useSelector((state) => {
            return state;
        })
    const filterUserList = userList && userList.filter((userData) => userData.default !== true);
    const initials = userData?.initials;
    const color_code = userData?.color_code;
    const handleSearchToggle = () => {
        setSearchOpen(prev => !prev);
        setSearchQuery('');
        if (searchQuery === '') {
            dispatch(setUserList([]));
        }
    };

    const handleSearchChange = (e) => {
        dispatch(searchUser(e.target.value));
        setSearchQuery(e.target.value);
    };

    const handleUserProfile = (gid) => {
        navigate(`/profile/${gid}`);
        dispatch(setUserList([]));
    }

    const handleHelpandSupport = () => {
        navigate('/social-media/contact-us');
    }
    const handlePostBookmark = () => {
        dispatch(resetSelectedSlug())
        dispatch(resetPostDashboard())
        dispatch(resetNotificationPostType());
        navigate('/social-media/bookmark/post');
        dispatch(setBookmarkType('Post'));
        handleBookmarkClose();
    }
    const handleEventBookMark = () => {
        dispatch(resetSelectedSlug())
        dispatch(resetPostDashboard())
        dispatch(resetNotificationPostType());
        navigate('/social-media/bookmark/event');
        dispatch(setBookmarkType('SocialEvent'));
        handleBookmarkClose();
    }

    useEffect(() => {
        dispatch(getNotificationCount());
    }, [])
    return (
        <>
            <Grid item gap={3} display="flex" alignItems="center" justifyContent="flex-end" >
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    {!searchOpen && (
                        <Tooltip title="Search" arrow>
                            <IconButton onClick={handleSearchToggle} size="small" aria-label="search" color="inherit" sx={{ display: { xs: 'none', sm: 'block', padding: '0px', marginBottom: '-7px' } }}>
                                <img src={Search} style={{ height: '20px', width: '20px' }} />
                            </IconButton></Tooltip>
                    )}
                    {searchOpen && (
                        <>
                            <Box sx={{ position: 'absolute', top: '0', right: '0', display: 'flex', alignItems: 'center', width: '100%', }}>
                                <HeaderSearch
                                    query={searchQuery}
                                    onChange={handleSearchChange}
                                    handleSearchToggle={handleSearchToggle}
                                    searchOpen={searchOpen} />
                            </Box>
                            {!_.isEmpty(users_list) &&
                                <Paper className={CommonCSS.searchListPaper}>
                                    <MenuList className={CommonCSS.searchMenuList}>
                                        {users_list.map((user) => (
                                            <MenuItem key={user?.gid} className={CommonCSS.searchListMenuItem} onClick={() => handleUserProfile(user?.gid)}>
                                                <ListItemText className={CommonCSS.searchUserName} sx={{ '& .MuiTypography-root': { textOverflow: 'ellipsis', overflow: 'hidden', } }}>{user?.name}</ListItemText>
                                                <Typography variant="caption" color="text.secondary" sx={{
                                                    display: 'block',
                                                    maxWidth: '100%',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                    {user?.tagline}
                                                </Typography>
                                            </MenuItem>))}
                                    </MenuList>
                                </Paper>
                            }
                        </>
                    )}
                </Box>
                <a href={`${window.location.origin}/social-media/notifications`} style={{ textDecoration: 'none' }}>
                    <Tooltip title="Notifications" arrow>
                        <IconButton size="small" aria-label="show 17 new notifications" color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <StyledBadge badgeContent={notifications?.notification_count !== 0 ? notifications?.notification_count > 99 ? `99+` : notifications?.notification_count : null} color="error">
                                <img src={notification} />
                            </StyledBadge>
                        </IconButton></Tooltip>
                </a>
                <a style={{ textDecoration: 'none' }}>
                    <Tooltip title="Bookmarks" arrow>
                        <IconButton size="small" aria-label="show" color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }} onClick={handleBookmarkClick}>
                            <StyledBadge color="error">
                                <img src={my_bookmark} />
                            </StyledBadge>
                        </IconButton></Tooltip>
                </a>
                <a href={`${window.location.origin}/social-media/messages`} style={{ textDecoration: 'none' }}>
                    <Tooltip title="Messages" arrow>
                        <IconButton size="small" aria-label="show" color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <StyledBadge badgeContent={notifications?.message_count !== 0 ? notifications?.message_count > 99 ? `99+` : notifications?.message_count : null} color="error">
                                <img src={message} />
                            </StyledBadge>
                        </IconButton></Tooltip>
                </a>
                <Box sx={{ height: '28px', width: '1px', backgroundColor: theme.palette.divider, alignSelf: 'center', }}></Box>

                <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%" flexGrow={1} paddingRight={'8px'}>
                    {isMobile ? (
                        <IconButton size="small" color="inherit" onClick={handleClick} sx={{ padding: '0px 16px 0px 16px' }}>
                            <CommonInitials
                                name={initials}
                                bgColor={color_code}
                                divStyle={{ maxHeight: '32px', maxWidth: '32px', minHeight: '32px', minWidth: '32px', height: '32px', width: '32px', borderRadius: '50%', boxShadow: "2px 0 4px rgba(0, 0, 0, 0.5)", cursor: 'pointer' }}
                                typoStyle={{ fontSize: '14px', lineHeight: '32px', }}
                                avatarStyle={{ width: '32px', height: '32px', boxShadow: "2px 0 4px rgba(0, 0, 0, 0.5)" }}
                                src={userData?.profile_pic}
                                companyLogo={userData?.profile_pic}
                                avatar={true}
                            />
                        </IconButton>
                    ) : (
                        <>
                            <IconButton size="small" onClick={handleClick} sx={{ padding: '0px 16px 0px 16px' }}>
                                <CommonInitials
                                    onClick={handleClick}
                                    name={initials}
                                    bgColor={color_code}
                                    divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '32px', minWidth: '32px', borderRadius: '50%', boxShadow: "2px 0 4px rgba(0, 0, 0, 0.5)", cursor: 'pointer' }}
                                    typoStyle={{ fontSize: '14px', lineHeight: '32px', }}
                                    avatarStyle={{ width: '32px', height: '32px', boxShadow: "2px 0 4px rgba(0, 0, 0, 0.5)" }}
                                    src={userData?.profile_pic}
                                    companyLogo={userData?.profile_pic}
                                    avatar={true}
                                />
                            </IconButton>
                        </>
                    )}
                </Box>

                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            width: isMobile ? '105%' : '500%',
                            maxWidth: isMobile ? 'unset' : '300px',
                            textAlign: isMobile ? 'center' : 'left',
                            backgroundColor: '#F5F5FA',
                            borderRadius: '10px, 0px, 10px, 10px',
                            // mt: '-4px',
                        },
                    }}
                    sx={{
                        marginTop: '18px',
                        marginLeft: '16px',
                        // '& .MuiPopover-paper': {
                        //   width: '12%', 
                        // },
                    }}
                >

                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px 20px 8px 20px', }}>
                        <Link to="/user-profile/create" style={{ textDecoration: 'none' }}>
                            <CommonInitials name={initials} bgColor={color_code} divStyle={{ padding: '5px', maxHeight: '135px', maxWidth: '132px', minHeight: '55px', minWidth: '55px', borderRadius: '50%', boxShadow: "2px 0 4px rgba(0, 0, 0, 0.5)", cursor: 'pointer' }} typoStyle={{ fontSize: '20px', lineHeight: '44px', }} avatarStyle={{ boxShadow: '5px 5px 7px 0px rgba(0, 0, 0, 0.25), 1px 1px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 2px 0px rgba(255, 255, 255, 0.05), -6px -6px 8px 0px rgba(255, 255, 255, 0.10)', width: '55px', height: '55px' }} src={userData?.profile_pic} companyLogo={userData?.profile_pic} avatar={true} />
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Typography sx={{ fontWeight: '600 !important', fontSize: '16px !important', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '200px', }}>Hello, {userData?.name} !</Typography>
                    </Box>
                    <Box sx={{ paddingTop: '2px', textAlign: 'center' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>{userData?.email}</Typography>
                    </Box>
                    {(!userData?.sub_user && userData?.profile_type !== "personal") && (
                        <Box sx={{ padding: '6px 66px', textAlign: 'center' }}>
                            <CommonButton2
                                label="User Management"
                                name="User Management"
                                type="button"
                                color="primary"
                                variant='outlined'
                                onClick={() => navigate("/user/user-management-dashboard")}
                                style={{
                                    backgroundColor: 'white',
                                    color: `var(--Primary-110, #0775BA)`,
                                    fontSize: '14px',
                                    border: `1.5px solid var(--Primary-110, #0775BA)`,
                                    borderRadius: '40px',
                                    padding: '4px'
                                }}
                            />
                        </Box>)}
                    <Box sx={{ padding: '20px' }}>
                        {open && (
                            <>
                                {showMultiuser && <Box
                                    sx={{
                                        borderRadius: '16px',
                                        boxShadow: ' 5px 5px 7px 0px rgba(0, 0, 0, 0.25), 1px 1px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 2px 0px rgba(255, 255, 255, 0.05), -6px -6px 8px 0px rgba(255, 255, 255, 0.10)',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Accordion expanded={expanded} onChange={handleChange} sx={{ backgroundColor: 'white' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{expanded ? 'Hide all profiles' : 'Show all profiles'}</Typography>
                                        </AccordionSummary>
                                        {filterUserList.map((userData) =>
                                            <AccordionDetails sx={{ borderTop: '1.8px solid #ccc', marginLeft: '15px', marginRight: '15px', paddingLeft: '0px', paddingRight: '0px', cursor: 'pointer' }} >
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Box display='flex' alignItems='center' onClick={() => {
                                                        handleClose();
                                                        dispatch(switchProfile(userData?.gid, navigate))
                                                    }}
                                                    >
                                                        <CommonInitials
                                                            onClick={handleClick}
                                                            name={userData?.initials}
                                                            bgColor={userData?.color_code}
                                                            divStyle={{ padding: '3.5px', maxHeight: '135px', maxWidth: '132px', minHeight: '30px', minWidth: '30px', borderRadius: '50%', marginTop: '3px', boxShadow: '5px 5px 7px 0px rgba(0, 0, 0, 0.25), 1px 1px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 2px 0px rgba(255, 255, 255, 0.05), -6px -6px 8px 0px rgba(255, 255, 255, 0.10)', cursor: 'pointer' }}
                                                            typoStyle={{ fontSize: '12px', lineHeight: '25px', }}
                                                            avatarStyle={{ width: '30px', height: '30px', cursor: 'pointer', marginTop: '3px', boxShadow: '5px 5px 7px 0px rgba(0, 0, 0, 0.25), 1px 1px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 2px 0px rgba(255, 255, 255, 0.05), -6px -6px 8px 0px rgba(255, 255, 255, 0.10)' }}
                                                            src={`${userData?.profile_pic}`}
                                                            companyLogo={userData?.profile_pic}
                                                            avatar={true}
                                                        />
                                                        <Box sx={{ paddingLeft: '15px', maxWidth: '9rem' }}>
                                                            <Typography sx={{ fontSize: '12px !important', fontWeight: '700 !important', cursor: 'pointer !important', overflow: 'hidden !important', whiteSpace: 'nowrap !important', textOverflow: 'ellipsis !important', maxWidth: '160px !important', fontFamily: 'poppins !important', letterSpacing: '0 !important' }}>
                                                                {userData?.name}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '10px !important', lineHeight: '10px !important', cursor: 'pointer !important', fontFamily: 'poppins !important', letterSpacing: '0 !important' }}>
                                                                {companyTypes && companyTypes?.find((data) => data.key === userData?.company_type)?.value}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <IconButton onClick={() => dispatch(removeSingleAccount(userData?.gid, false, userData?.email))}>
                                                            <img src={deleteRed} style={{ width: '17px', height: '16px' }} />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </AccordionDetails>
                                        )}
                                    </Accordion>
                                </Box>}
                            </>
                        )}
                        <Box className={CommonCSS.helpandSupport} onClick={handleHelpandSupport}>
                            <Box className={CommonCSS.helpandSupport2}>
                                <Typography className={CommonCSS.helpandSupportTypo}>Help & Support</Typography>
                                <img src={helpandSupport} alt='help' height={'20px'} width={'20px'} />
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: "10px", justifyContent: 'space-evenly', paddingTop: "10px" }}>
                            <Box sx={{ paddingBottom: '10px', paddingTop: '10px', width: '170px', alignItems: 'center', }}>
                                <Tooltip title={userList.length > 4 ? "Cannot add more than 5 accounts" : ""} arrow>
                                    <span>
                                        <CommonButton2
                                            label="Add Account"
                                            name="Add Account"
                                            startIcon={<AddIcon style={{ fontSize: '20px' }} />}
                                            type="button"
                                            color="primary"
                                            variant='contained'
                                            // onClick={() => navigate("/login")}
                                            onClick={() => handleOpenDialog()}
                                            disabled={userList.length > 4}
                                            style={{
                                                color: 'white',
                                                borderRadius: '40px',
                                                fontSize: '14px',
                                                padding: "6px 5px 5.5px 4px",
                                                boxShadow: '5px 5px 7px 0px rgba(0, 0, 0, 0.25), 1px 1px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 2px 0px rgba(255, 255, 255, 0.05), -6px -6px 8px 0px rgba(255, 255, 255, 0.10)'
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            </Box>
                            <Box sx={{ paddingBottom: '10px', paddingTop: '10px', width: '170px', }}>
                                <CommonButton2
                                    label="Sign Out"
                                    name="Sign Out"
                                    type="button"
                                    startIcon={<img src={logout} alt='' />}
                                    color="primary"
                                    variant='outlined'
                                    onClick={() => dispatch(logoutSingleAccount(userData?.gid, navigate))}
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        fontSize: '14px',
                                        borderColor: 'black',
                                        borderRadius: '40px',
                                        padding: "4px 4px 4px 4px",
                                        boxShadow: '5px 5px 7px 0px rgba(0, 0, 0, 0.25), 1px 1px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 2px 0px rgba(255, 255, 255, 0.05), -6px -6px 8px 0px rgba(255, 255, 255, 0.10)'
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Popover>
                <Popover
                    id={bookmarkPopoverId}
                    open={bookmarkOpen}
                    anchorEl={bookmarkAnchorEl}
                    onClose={handleBookmarkClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: 'none',
                            border: '0.5px solid grey',
                            backgroundColor: '#fff',
                            padding: 0,
                            width: '125px',
                            marginTop: '10px',
                            borderRadius: '5px'
                        }
                    }}
                >
                    <Box sx={{ p: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, cursor: 'pointer' }} onClick={handlePostBookmark}>
                            <Typography >Posts</Typography>
                            <img src={share} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, cursor: 'pointer' }} onClick={handleEventBookMark}>
                            <Typography>Events</Typography>
                            <img src={calender} sx={{ height: '12px !important', width: '12px' }} />
                        </Box>
                    </Box>
                </Popover>
            </Grid>
            <DialogLogin
                open={openDialog}
                handleClose={handleCloseDialog}
            />
        </>
    );
}