import { Dialog, Divider, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import theme from '../../../../assets/icons/deletePopover.svg';
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import CommonButton2 from '../../../../common/components/CommonButton2';
import cancel from "../../../../assets/icons/Cancel.svg"
import save from "../../../.../../../assets/icons/Floppy.svg";
import { setCreatePost, setPostAudience, setPostStatus, setShowActivity, setShowPostThematic, setTagPost } from '../../../../store/slices/SocialMediaSlice';
import { createPost } from '../../actions';
import { useDispatch } from 'react-redux';

const DiscardPost = ({ events, open, onClose, Content1, Content2, title, ButtonText2, ButtonText1, handleDiscard, text1, handleButton2, connect, handleConfirm ,textExceeded}) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const dispatch = useDispatch();
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    const handleSaveDraft = () => {
        dispatch(setPostStatus("draft"));
        dispatch(setPostAudience(false));
        dispatch(setCreatePost(false))
        dispatch(setShowActivity(false));
        dispatch(setShowPostThematic(false));
        dispatch(setTagPost(false));
        dispatch(createPost());
        onClose();
    };

    return (
        <>
            <Dialog open={open} onClose={handleDlgClose} PaperProps={{ sx: { minWidth: isXsScreen ? '800px' : '300px', borderRadius: '24px' } }}>
                <Box className={`${SocialMediaCSS.discardPost} ${SocialMediaCSS.discardHeader}`}>
                    <Box >
                        <Typography className={SocialMediaCSS.DiscardTitle} >
                            {title}
                        </Typography>
                    </Box>
                    <Box className={SocialMediaCSS.cancelIcon} onClick={onClose}>
                        <img src={cancel} className={SocialMediaCSS.createPost} />
                    </Box>
                </Box>
                <Divider />
                <Box className={SocialMediaCSS.discardPost}>
                    <Typography sx={{ color: '#666', fontWeight: '400' }}>
                        {Content1}
                    </Typography>

                    <Typography sx={{ color: '#666', fontWeight: '400' }}>
                        {Content2}
                    </Typography>

                </Box>
                <Divider />
                <Box className={`${SocialMediaCSS.discardPost} ${SocialMediaCSS.f} ${SocialMediaCSS.jc_fend}`} sx={{ gap: '10px' }}>
                    {connect ?
                        <>
                            <CommonButton2
                                label={ButtonText2}
                                name={ButtonText2}
                                // startIcon={<img src={theme} />}
                                type="button"
                                color="primary"
                                variant='outlined'
                                style={{
                                    padding: '6px 24px', fontSize: '14px'
                                }}
                                onClick={() => onClose()}
                            />
                            <CommonButton2
                                label={ButtonText1}
                                name={ButtonText1}
                                // startIcon={<img src={save} style={{ height: "14px", width: "14px" }} />}
                                type="button"
                                color="primary"
                                variant='contained'
                                style={{
                                    padding: '6px 24px', fontSize: '14px', background: 'linear-gradient(180deg, #d45454 0%, #F93E3E 100%)',
                                }}
                                onClick={() => handleConfirm()}
                            />
                        </> :
                        <>
                            <CommonButton2
                                label={ButtonText2}
                                name={ButtonText2}
                                startIcon={<img src={theme} className={SocialMediaCSS.discardPostBtnImg}/>}
                                type="button"
                                color="primary"
                                variant='outlined'
                                style={{
                                    padding: '6px 24px', fontSize: '14px'
                                }}
                                onClick={() => handleDiscard()}
                            />
                            <CommonButton2
                                label={ButtonText1}
                                name={ButtonText1}
                                startIcon={<img src={save} className={SocialMediaCSS.savePostBtnImg}/>}
                                type="button"
                                color="primary"
                                variant='contained'
                                style={{
                                    padding: '6px 24px', fontSize: '14px', background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%',
                                    opacity: textExceeded ? 0.5 : 1, 
                                    pointerEvents: textExceeded ? 'none' : 'auto'
                                }}
                                onClick={() => handleSaveDraft()}
                                // disabled={textExceeded}
                            />
                        </>}
                </Box>

            </Dialog >
        </>
    )
}

export default DiscardPost;