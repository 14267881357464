import React, { useEffect, useState } from 'react'
import { SPEKAER_TABS } from '../../../../common/constants';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import theme from '../../../../assets/material-ui/theme';
import ProfileCss from '../../../Profile/NewProfile/Common/ProfileCss/Profile.module.css';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { setInvitations, setSelectedConnTab, setTotalInvitations } from '../../../../store/slices/SocialMediaSlice';
import { getAllInvitations, getAllSponsorInvitations } from '../../actions';

const SponsorHeaderTabs = ({ dispatch, totalInvitationCount, totalAcceptedCount, totalRequestedCount }) => {
    const [value, setValue] = useState(SPEKAER_TABS.RECIEVED);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setSelectedConnTab(newValue));
        dispatch(setInvitations([]));
        dispatch(setTotalInvitations(0));
        dispatch(getAllSponsorInvitations());
        console.log("Tab", newValue);
    };

    const tabStyle = (tabValue) => ({
        color: theme.mainPalette.grey.lightText,
        fontSize: '16px',
        fontWeight: '500',
        padding: '0px 5px',
        height: '26px',
        minHeight: '26px',
        minWidth: 'unset',
        transition: ' all 0.3s ease',
        '&.Mui-selected': {
            color: theme.mainPalette.primary.primary110,
            transition: ' all 0.3s ease',
        },
        '&:hover': {
            // color: theme.mainPalette.secondary.secondary110,
            borderBottom: `2px solid ${theme.mainPalette.grey.lightText}`,
            transition: ' all 0.1s ease',
            opacity: 1,
        },
    });
    const CustomLabel = ({ mainLabel, count }) => (
        <Box className={SocialMediaCSS.all}>
            <Typography className={SocialMediaCSS.mainLabel2}>{mainLabel}</Typography>
            <Typography className={SocialMediaCSS.countLabel2} >({count})</Typography>
        </Box>
    );

    useEffect(() => {
        dispatch(setSelectedConnTab(SPEKAER_TABS.RECIEVED))
    }, [])

    return (
        <Grid item xs={12}>
            <Box className={ProfileCss.headerTabs}>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="none"
                    variant="scrollable"
                    // scrollButtons="auto"
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: theme.palette.primary.dark, height: '2px', borderRadius: '0px' }, minHeight: '26px', height: '26px', }}
                    aria-label="Profile Tabs"
                >
                    <Tab value={SPEKAER_TABS.RECIEVED} label={<CustomLabel mainLabel={SPEKAER_TABS.RECIEVED} count={totalRequestedCount} />} sx={tabStyle(SPEKAER_TABS.RECIEVED)} />
                    <Box className={ProfileCss.dividerStyle}></Box>
                    <Tab value={SPEKAER_TABS.ACCEPTED} label={<CustomLabel mainLabel={SPEKAER_TABS.ACCEPTED} count={totalAcceptedCount} />} sx={tabStyle(SPEKAER_TABS.ACCEPTED)} />
                </Tabs>
            </Box>
        </Grid>
    )
}

export default SponsorHeaderTabs