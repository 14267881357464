import React from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { Box, Divider } from '@mui/material';
import CommentRow from './CommentRow';
import CommentInputContainer from './CommentInputContainer';

const PostComments = ({gid, socialMedia, dispatch, master, users,type}) => {
    const comments = socialMedia?.comments && socialMedia.comments[gid];
    return (
        <>
            <Box className={SocialMediaCSS.commentArea}>
                <CommentInputContainer  gid={gid} comment={true} users={users} />
                <Divider />
                <Box className={SocialMediaCSS.commentsContainer}>
                    {comments && comments.map((data)=>
                        <CommentRow data={data} gid={gid} dispatch={dispatch} socialMedia={socialMedia} master={master} users={users} type={type}/>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default PostComments
